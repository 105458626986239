import { useMemo, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';
import { ConfigServer } from '../../data/config';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useRef } from 'react';
import moment from 'moment';
import { formatDate } from '@fullcalendar/core';
import { formControlClasses } from '@mui/material';


export const useWorkOrder = () => {

    const history = useHistory();

    //LOCAL VARIABLES
    let id_commercial_line = localStorage.getItem('commercialLineId');
    let id_branch = localStorage.getItem('branchId');
    let percentage_urgent_work_order = localStorage.getItem('percentage_urgent_work_order');
    let validPorcentejeSpecial = localStorage.getItem('url_helen');
    let validHopitalInterfaced = localStorage.getItem('interfaced');
    let pets = localStorage.getItem('pets');

    const { id_work_order } = useParams();

    //#region MODALS
    const [modalTitle, setModalTitle] = useState("");
    const [modalPatient, setModalPatient] = useState(false);
    const [modalDoctor, setModalDoctor] = useState(false);
    const [modalViewExamsP, setModalViewExamsP] = useState(false);
    const [modalSpecialDiscounts, setModalSpecialDiscounts] = useState(false);
    const [modalCommonDiscount, setModalCommonDiscount] = useState(false);
    const [modalQuoter, setModalQuoter] = useState(false);
    const [modalInvoice, setModalInvoice] = useState(false);
    const [modalScannQrPatient, setModalScannQrPatient] = useState(false);
    const [modalRepeatProfile, setModalRepeatProfile] = useState(false);
    const [modalSocialGender, setModalSocialGender] = useState(false);
    const [modalDevolutions, setModalDevolutions] = useState(false);
    const [modalPayBills, setModalPayBills] = useState(false);
    const [modalCancelWorkOrderUpdate, setModalCancelWorkOrderUpdate] = useState(false);

    const [methodPatient, setMethodPatient] = useState("");
    const [methodDoctor, setMethodDoctor] = useState("");
    const [methodQuoter, setMethodQuoter] = useState("");
    const [methodInvoice, setMethodInvoice] = useState("");
    const [doctList, setDoctList] = useState([])
    const [discount, setDiscount] = useState([])
    const [validateCommonDiscountDetail, setValidateCommonDiscountDetail] = useState(false);


    const toggleModalSocialGender = () => {
        setModalSocialGender(!modalSocialGender);
    }
    const togglePatient = () => {
        setModalPatient(!modalPatient);
    }

    const toggleModalScannQr = () => {
        setModalScannQrPatient(!modalScannQrPatient);
    }

    const toggleDoctor = () => {
        setModalDoctor(!modalDoctor);
    }

    const toggleModalViewExams = () => {
        setModalViewExamsP(!modalViewExamsP);
    }

    const toggleSpecialDiscount = () => {
        setModalSpecialDiscounts(!modalSpecialDiscounts);
    }

    const toggleCommonDiscount = () => {
        setModalCommonDiscount(!modalCommonDiscount);
    }

    const toggleModalQuoter = () => {
        setModalQuoter(!modalQuoter);
    }

    const toggleModalInvoice = () => {
        setModalInvoice(!modalInvoice);
    }

    const toggleRepeatProfile = () => {
        setModalRepeatProfile(!modalRepeatProfile);
    }

    const toggleModalDevolutions = () => {
        setModalDevolutions(!modalDevolutions);
    }

    const toggleModalPayBills = () => {
        setModalPayBills(!modalPayBills);
    }

    const toggleModalCancellWorkOrderUpdate = () => {
        setModalCancelWorkOrderUpdate(!modalCancelWorkOrderUpdate);
    }
    //#endregion MODALS

    //#region SHOW DISPLAY MESSAGE
    const [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett, sweetConfirmationUpdate, sweetConfirmRepeatExam, sweetConfirmationChangeClient] = useSweetAlert();
    //#endregion SHOW DISPLAT MESSAGE

    //#region WORK ORDER 

    //#region WORK ORDER MODEL

    const [searchExamProfileState, setSearchExamProfileState] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchExtra, setSearchExtra] = useState([]);

    //#region IS EDIT WORK ORDER
    const [isEditWorkOrder, setIsEditWorkOrder] = useState(false);
    const [loadingEditWorkOrder, setLoadingEditWorkOrder] = useState(false);
    const [isFullyPaid, setIsFullyPaid] = useState(false);
    const [idTestDelete, setIdTestDelete] = useState({
        id_exam: 0,
        id_profile: 0
    });
    //#endregion

    //TAB TYPE WORK ORDER
    const [checkTypeClient, setCheckTypeClient] = useState(1);

    //#region PRELOADED VALUES
    const [listPrinterPoint, setListPrinterPoint] = useState([]);
    const [listPayMethod, setListPayMethod] = useState([]);
    const [listCompanies, setListCompanies] = useState([]);
    const [listUseCfdi, setListUseCfdi] = useState([]);
    const [times, setTimes] = useState([]);
    const [listType, setListType] = useState([])
    //#endregion

    //#region INFORMATION WORK ORDER
    const [totalW, setTotalW] = useState(0.00);
    const [subTotalW, setSubTotalW] = useState(0.0);
    const [totalDiscountW, setTotalDiscountW] = useState(0.0);
    const [totalTaxesW, setTotalTaxesW] = useState(0.0);
    const [totalDevolutions, setTotalDevolutions] = useState(0.0);
    const [moneyEntered, setMoneyEntered] = useState(0);
    const [showMessageW, setShowMessageW] = useState({
        labelMoney: "",
        amount: 0.0
    });

    const [devolutionsW, setDevolutionsW] = useState({
        id_work_order: 0,
        id_return_reason: 0,
        listTestDevolution: []
    });

    const [viewExamsProfile, setViewExamsProfile] = useState([]);
    const [viewWorkOrder, setViewWorkOrder] = useState(false);
    const [enableBtnSaveWorkOrder, setEnableBtnSaveWorkOrder] = useState(false);

    //USE ONLY IN ADMISSION TYPE COMPANY
    const [requiredInvoiceCompany, setRequiredInvoiceCompany] = useState(false);
    const [isPayForCompany, setIsPayForCompany] = useState(false);
    const [availableInvoicePatient, setAvailableInvoicePatient] = useState(false);
    const [listAgreements, setListAgreements] = useState([]);
    const [idAgreementCompany, setIdAgreementCompany] = useState(0);

    const [idWorkOrder, setIdWorkOrder] = useState(0);
    //#endregion

    //#region MEDICAL ORDER MESSAGE
    const [showMessageWF, setShowMessageWF] = useState({
        labelWF: "Cargar Orden Médica"
    });

    const [showClassSD, setshowClassSD] = useState({
        ClassSD: "f-w-600 f-12 badge badge-primary ml-5"
    });

    const [showClass, setshowClass] = useState({
        ClassName: "d-none"
    });
    //#endregion

    //COMMENTS GENERAL MEDICAL ORDER
    const [ShowClassNameC, SetShowClassNameC] = useState("d-none");
    const [checkComments, setcheckComments] = useState(false);
    const [ShowDiscount, SetShowDiscount] = useState("d-none");
    const [checkDiscopunt, setCheckDiscopunt] = useState(false);

    //STATUS MEDICAL ORDER
    const [OrderMedicalEstatus, setOrderMedicalEstatus] = useState(1);
    const [medical_order, setFile] = useState("");

    //#region COMMON DISCOUNTS, AGREEMENTS
    const [arrayNameDiscount, setArrayNameDiscount] = useState([]);

    const [arrayCommonDiscounts, setArrayCommonDiscounts] = useState([]);
    const [arrayAgreementDiscounts, setArrayAgreementDiscounts] = useState([]);

    const [listCommonDiscountsCurves, setListCommonDiscountsCurves] = useState([]);
    const [listAgreementsDiscountCurves, setListAgreementsDiscountCurves] = useState([]);

    const initialActiveTab = pets === 'True' ? '2' : '1';
    const [activeTab, setActiveTab] = useState(initialActiveTab);
    const [isPetsTabLocked, setIsPetsTabLocked] = useState(false);
    //#endregion

    //#region MEMBERSHIP PATIENT
    const [is_membership, setIs_membership] = useState(false);
    //#endregion

    //TYPE SEARCH
    const [typeSearch, setTypeSearch] = useState(0);
    const [typeNumber, setTypeNumber] = useState(0);
    //FORM VALUES WORK ORDER
    const [formWorkOrder, handleInputChangeWorkOrder, resetWorkOrder, handleUpdateValuesWorkOrder, handleSelectValuesWorkOrder, handlePickerValuesWorkOrder, handleDinamicInputWorkOrder] = useForm({
        id_patient: 0,
        id_pet: 0,
        id_branch: id_branch,
        id_company: 0,
        c: 0,
        id_quoter: null,
        id_doctor: 0,
        observations: "",
        observations_sample: "",
        observations_general: "",
        valid_commont_: true,
        checkPrint: true,
        checkPartial: true,

        checkEmail: false,
        checkWhats: false,
        checkDoctor: false,
        checkInvoice: true,
        business_name: "",
        rfc: "",
        phone: "",
        email: "",
        id_use_cfdi: 0,
        print_invoice: 0,
        send_invoice: 0,
        listTest: [],
        listPayMethods: [{
            id_pay_method: 0,
            amount: 0.0,
            id_branch: id_branch
        }],
        listDoctors: [],
        //listSupplies: [],
        iva: 0.0,
        percentageDiscount: 0,
        total_price: 0,
        credit_max_amount: 0,
        max_discount: 0,
        id_work_order_type_service: 0,
        //name_service: "",
        listTypeServices: [],
    });

    const {
        id_patient,
        id_pet,
        id_company,
        id_printer_point,
        id_doctor,
        observations,
        observations_sample,
        valid_commont_,
        observations_general,
        checkPrint,
        checkPartial,
        checkEmail,
        checkWhats,
        checkDoctor,
        checkInvoice,
        business_name,
        rfc,
        phone,
        email,
        id_use_cfdi,
        print_invoice,
        send_invoice,
        listTest,
        listPayMethods,
        listDoctors,
        //listSupplies,
        iva,
        percentageDiscount,
        total_price,
        credit_max_amount,
        max_discount,
        listTypeServices,
        id_work_order_type_service,
        name_service,
    } = formWorkOrder;
    //#endregion WORK ORDER MODEL 

    //#region VALIDATIONS WORK ORDER
    const [validationsWorkOrder, setValidationsWorkOrder] = useState({
        id_patient_valid: false,
        id_printer_point_valid: false,
        id_company_valid: false,
        listTest_valid: false,
        listPayMethods_valid: false,
        listid: false,
        id_work_order_type_service_valid: false,
    });

    const [validationsTest, setValidationsTest] = useState({
        valid_repeat_test: false,
        valid_exist_exam_profile: false,
        valid_conteins_exam: false,
        message_validation: ""
    });

    const [validationsRepeat, setValidationsRepeat] = useState({
        name_profile: "",
        repeat_profiles: [],
        repeat_exams: []
    });
    //#endregion VALIDATIONS WORK ORDER

    //#region WORK ORDER USE EFFECT
    // useEffect(() => {
    //     if (listPrinterPoint.length > 0) {
    //         handleUpdateValuesWorkOrder({
    //             ...formWorkOrder,
    //             id_printer_point: listPrinterPoint[0]
    //         });
    //     }

    // }, [listPrinterPoint]);

    // useEffect(() => {
    //     if (listType.length > 1) {
    //         handleUpdateValuesWorkOrder({
    //             ...formWorkOrder,
    //             id_work_order_type_service: listType[0]
    //         });
    //     }
    // }, [listType]);

    useEffect(() => {
        if (listPrinterPoint.length > 0) {
            handleUpdateValuesWorkOrder(prevState => ({
                ...formWorkOrder,
                id_printer_point: listPrinterPoint[0]
            }));
        }
    }, [listPrinterPoint]);
    
    useEffect(() => {
        if (listType.length > 1) {
            handleUpdateValuesWorkOrder(prevState => ({
                ...prevState,
                id_work_order_type_service: listType[0]
            }));
        }
    }, [listType]);
    

    useEffect(() => {
        handleGetPrinterPoints();
        handleGetTypeService();
        handleGetTimes();
        handleGetPayMethods();
        //handleGetUseCfdi();
    }, []);

    useEffect(() => {

        if (idWorkOrder > 0) {
            UpdateFilePatient(idWorkOrder);
            //printTicket(idWorkOrder);
        }

    }, [idWorkOrder]);

    useEffect(() => {

        if (id_work_order !== undefined) {
            handleGetUpdateWorkOrder(id_work_order, id_commercial_line);
        }

    }, [id_work_order]);

    const [workOrderId, setWorkOrderId] = useState(null);

    useEffect(() => {
        // Esto asegura que el estado se actualice solo cuando el valor de id_work_order cambie
        setWorkOrderId(id_work_order);
    }, [id_work_order]);

    useEffect(async () => {

        //!descomentar en caso que no reconozca el convenio
             // if (id_company?.value && isEditWorkOrder == false) {
                  if (id_company?.value ) {
      
      
                  const request = await handleRequest("GET", `Agreement/ListAgreementByCompany/${id_company.value}`);
                  console.log(request);
      
      
                  if (request !== null && request.length > 0) {
      
                      console.log(request);
                      let listAgreements = [];
      
                      const list = request?.filter(filterAgreement => {
                          if (filterAgreement?.status === "" && filterAgreement?.is_authorize) {
                              return true;
                          }
                      })
                          ?.forEach(_agreement => {
                              console.log(_agreement);
                              console.log("wilsojn");
                              listAgreements.push({
                                  label: _agreement.code,
                                  value: _agreement.id_agreement
                              });
                          });
      
      
                          if (isEditWorkOrder == false) {
                               if (listAgreements.length == 1) {
                                  setIdAgreementCompany(listAgreements[0]?.value);
                              }
                              
                          }
      
                          if (isEditWorkOrder == true) {
                              if (listAgreements.length > 0) {
                                 setIdAgreementCompany(listAgreements[0]?.value);
                             }
                         }
      
      
          
                      setListAgreements(listAgreements);
                  }
              }
          }, [id_company]);
    
    //#endregion WORK ORDER USE EFFECT

    //#region VETERINARY
    // const toggleTab = tab => {
    //     if (activeTab !== tab) setActiveTab(tab);
    // }

    const toggleTab = tab => {
        if (!isPetsTabLocked || tab !== '1') {
            setActiveTab(tab);
        }
    };
    
    //#endregion

    //#region WORK ORDER METHODS
    const handleChangeTypeClient = (typeClient, validClient = -1) => {

        if (formWorkOrder.id_patient === 0) {
            setCheckTypeClient(typeClient);
            handleGetClients(typeClient);
        }
        else {
            if (validClient === -1) {
                sweetConfirmationChangeClient("Al cambiar el tipo de ingreso, perderas los datos ingresados", "Estas seguro de realizar esta acción", "warning", typeClient, true, handleChangeTypeClient)
                return;
            }
        }
        if (!validClient) {
            return;
        }
        else {
            setCheckTypeClient(typeClient);
            handleGetClients(typeClient);
            cancellWorkOrder();
        }
    }

    const handleGetTimes = async () => {
        let listTemp = await handleRequest("GET", "Price/GetTimes", "Tiempos");

        if (listTemp !== null && listTemp.length > 0) {
            setTimes(listTemp);
        }
    }

    const handleGetTypeService = async () => {
        let listTmp = await handleRequest("GET", "WorkOrderServiceType/List", 'Tipo de Servicio');

        if (listTmp !== null && listTmp.length > 0) {
            let listTmpTypeService = [];
            listTmp.forEach((_typeService, i) => {
                if (i === 0) {
                    handleUpdateValuesWorkOrder({
                        ...formWorkOrder,
                        id_work_order_type_service: {
                            label: _typeService.name,
                            value: _typeService.id_work_order_type_service,
                        }
                    });
                }
                listTmpTypeService.push({
                    value: _typeService.id_work_order_type_service,
                    label: _typeService.name
                })
            });
            setListType([...listTmpTypeService])
        }
    }

    const handleGetPrinterPoints = async () => {
        let listTmp = await handleRequest("GET", "PrinterPoints", "Puntos de impresión");

        if (listTmp !== null && listTmp.length > 0) {
            let list = [];
            listTmp.forEach((obj, i) => {
                if (i === 0) {
                    handleUpdateValuesWorkOrder({
                        ...formWorkOrder,
                        id_printer_point: {
                            value: obj.id_printer_point,
                            label: obj.name
                        }
                    });
                }

                list.push({
                    value: obj.id_printer_point,
                    label: obj.name
                });
            });

            setListPrinterPoint([...list]);
        }
    }

    const handleGetUseCfdi = async () => {
        let listTmp = await handleRequest("GET", "UseCfdi/List", "Uso de cfdi");

        if (listTmp !== null && listTmp.length > 0) {
            let list = [];

            listTmp.forEach(obj => {
                list.push({
                    id_use_cfdi: obj.id_use_cfdi,
                    description: obj.description,
                });
            });

            setListUseCfdi(list);
        }
    }

    const handleGetClients = async (typeClient) => {
        let listTmp = await handleRequest("GET", `Company/ListTypeCompanies/${typeClient}`, 'Clientes');

        if (listTmp !== null && listTmp.length > 0) {

            let list = [];

            listTmp.forEach(obj => {
                list.push({
                    value: obj.id_company,
                    label: obj.code + ' ' + obj.name,
                    payment_by_company: obj.payment_by_company,
                    payment_by_patient: obj.payment_by_patient,
                    require_invoice: obj.require_invoice,
                    available_invoice_patient: obj.available_invoice_patient
                });
            });

            setListCompanies(list);
        }
    }

    const handleGetPayMethods = async () => {
        let listTmp = await handleRequest("GET", "PayMethod/List", "Metodos de pago");

        if (listTmp !== null && listTmp.length > 0) {
            let list = [];

            listTmp.forEach(obj => {
                list.push({
                    id_pay_method: obj.id_pay_method,
                    name: obj.name,
                    abbreviation: obj.abbreviation
                });
            });

            setListPayMethod(list);
        }
    }

    //CHANGE SELECT AGREEMENT
    const handleChangeAgreement = (e) => {
        setIdAgreementCompany(e);
    }

    //CHANGE GENERATE PAYMETHOD

    const handleChangeClient = (e) => {

        handleSelectValuesWorkOrder(e, 'id_company');
        if (e?.payment_by_company && (e?.payment_by_patient || !e?.payment_by_patient)) {
            setIsPayForCompany(true);
        }
        else {
            setIsPayForCompany(false);
        }

        if (e?.require_invoice) {
            setRequiredInvoiceCompany(true);
        }
        else {
            if (!e?.require_invoice && e?.available_invoice_patient) {
                setAvailableInvoicePatient(true);
            }
            else {
                setAvailableInvoicePatient(false);
            }
        }
    }

    const handleChangeGeneratePay = (e) => {
        if (e?.value === "1") {
            setIsPayForCompany(true);
        }
        else if (e?.value === "0") {
            setIsPayForCompany(false);
        }

        if (!isEditWorkOrder) {
            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listPayMethods: [{
                    id_pay_method: 0,
                    amount: 0.0,
                    id_branch: id_branch
                }]
            });

            setMoneyEntered(0.0);
        }
    }

    //FIND EXAMS AND PROFILES
    const handleChangeTypeFilterTest = (_typeFilter) => {
        setTypeSearch(_typeFilter);

        if (_typeFilter === 1) {
            handleGetCampaigns(id_patient, id_commercial_line, id_branch);
        }
    }

    const handleChangeTypeFilter = (_type) => {
        setTypeNumber(_type)
        if (_type === 1) {
        }
    }

    //#region SUPPLIES
    // const handleSelectSupplies = async (e) => {
    //     let event = e.target.value;
    //     let list = listSupplies;
    //     var listTemp = await handleRequest("POST", `Additional/List?id_additional=${event[0]}`, "Buscar Insumos")
    //     //console.log('listTemp: ', listTemp);
    //     if (listTemp !== null) {
    //         listTemp.forEach(obj => {
    //             list.push({
    //                 code: obj.code,
    //                 id_additional: obj.id_additional,
    //                 name: obj.name,
    //                 price: obj.price
    //             })
    //         })
    //         handleUpdateValuesWorkOrder({
    //             ...formWorkOrder,
    //             listSupplies: list
    //         });
    //     }
    //     //console.log("list---->", listSupplies)

    //     if (listTemp.code === 200) {
    //         createSweet("success", "success", "Se agregó correctamente el extra");
    //     } else if (listTemp.code === 401) {
    //         validarSesion(history, handleSelectSupplies);
    //     } else if (listTemp.code === 500) {
    //         createSweet("error", "error", "No existe ese extra", "Ocurrio un error en el servidor");
    //     }
    // }
    //#endregion SUPPLIES

    const handleSelectExamChange = (e) => {
        let examProfile = e.target.value;

        if (examProfile !== 0 && examProfile !== null) {

            examProfile = examProfile.split('-');

            let idTest = examProfile[0];
            let typeTest = examProfile[1];
            let nameTypeTest = typeTest === "0" ? "Exam" : "Profile";

            if (nameTypeTest === "Profile") {
                handleGetProfile(idTest);
            }
            else {
                handleGetExam(idTest);
            }
        }
    }

    const handleGetProfile = async (id_profile, valid_exist = -1) => {

        let mIdPatient = 0;
        let mIdClient = 0;
        let mIdAgreement = null;
        let mIdDoctor = 0;

        //#region VALIDATE IF IS COMPANY, DOCTOR, PATIENT OR MEMBERSHIP

        if (formWorkOrder.id_company.value !== undefined) {
            mIdPatient = 0;
            mIdDoctor = 0;
            mIdClient = formWorkOrder.id_company.value;
            mIdAgreement = idAgreementCompany?.value ?? idAgreementCompany;
        }
        else if (informationDoctor.length > 0) {
            let _find_doctor_by_agreement = informationDoctor.find(d => d.isCommercialPartner === true);

            if (_find_doctor_by_agreement !== undefined) {
                mIdPatient = 0;
                mIdDoctor = _find_doctor_by_agreement.id_doctor;
                mIdClient = 0;
                mIdAgreement = null;
            }
            else {
                mIdClient = 0;
                mIdAgreement = null;
                mIdDoctor = 0;
                mIdPatient = specialDiscount !== null ? 0 : id_patient;
            }
        }
        else {
            mIdClient = 0;
            mIdDoctor = 0;
            mIdAgreement = null;
            mIdPatient = specialDiscount !== null ? 0 : id_patient;
        }

        let alternative_method = "";

        if (is_membership) {
            alternative_method = "&is_membership=true";
        }
        else {
            alternative_method = "&is_membership=false";
        }

        if (mIdAgreement !== null) {
            alternative_method = (alternative_method == "" ? `?idAgreement=${mIdAgreement}` : `${alternative_method}&idAgreement=${mIdAgreement}`);
        }

        //#endregion

        var listTemp = await handleRequest("GET", `Profiles/FindProfileId/${id_profile},${id_branch},${id_commercial_line},${mIdPatient},${mIdClient},${mIdDoctor}?validation_strict=true${alternative_method}`, "Buscar perfles")

        let list = listTest;

        if (listTemp !== null) {

            //#region DUPLICATES PROFILES
            let validTest = validationTest(null, id_profile, listTemp.listExams, listTemp.name);

            if (validTest !== -1 && validTest !== 2) {
                sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                return;
            }
            else if (validTest === 2 && valid_exist === -1) {
                toggleRepeatProfile();
                setModalTitle("Perfiles con exámenes duplicados");
            }
            //#endregion

            //#region SPECIMENS
            let array_specimens = [];

            listTemp.exams_specimens.forEach(obj => {
                if (!array_specimens.find(x => x.id_specimen === obj.id_specimen_origin)) {

                    let valorCheck = true;
                    let valorCheckForwarded = false;

                    listTest.forEach(item => {
                        if (item.id_specimen === parseInt(obj.id_specimen_origin)) {
                            valorCheck = item.check_specimen;
                            valorCheckForwarded = false;
                        }
                    });

                    array_specimens.push({
                        specimen_name: obj.name_specimen_origin,
                        id_specimen: obj.id_specimen_origin,
                        check_specimen: valorCheck,
                        check_forwarded: valorCheckForwarded,
                    });
                }
            });
            //#endregion 

            //#region DISCOUNTS
            let descuentoEspecial = null;

            let id_common_discount = null;

            let id_agreement = null;
            let id_agreement_test_range = null;
            let contract_number = null;

            let is_auto_redeem = false;

            let porcentaje_descuento = 0;

            if (specialDiscount !== null) {
                if (specialDiscount.examProfileInclude.length > 0) {
                    specialDiscount.examProfileInclude.forEach(obj => {
                        if (obj.id_profile === listTemp.id_profile) {
                            if (obj.percentege > 0) {
                                porcentaje_descuento = obj.percentege;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                            else {
                                porcentaje_descuento = specialDiscount.percentage;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                        }
                    })
                }
            }
            else if (listTemp.commonDiscounts !== null && listTemp.commonDiscounts.length > 0) {

                let _listCommonDiscountsCurves = listCommonDiscountsCurves;
                let _copyListCommonDiscounts = arrayCommonDiscounts;

                if (listTemp.is_curve) {
                    listTemp.commonDiscounts.forEach(_common => {
                        if (_listCommonDiscountsCurves.find(x => x.id_profile === listTemp.id_profile && x.id_profile_curve_price === _common.id_profile_curve_price) === undefined) {
                            _listCommonDiscountsCurves.push({
                                id_common_discount: _common.id_common_discount,
                                id_profile_curve_price: _common.id_profile_curve_price,
                                id_profile: listTemp.id_profile,
                                percentage_discount: _common.percentage_discount,
                                auto_redeem: _common.auto_redeem,
                                valid_from: _common.valid_from,
                                valid_to: _common.valid_to
                            });
                        }
                    });

                    setListCommonDiscountsCurves(_listCommonDiscountsCurves);
                }
                else {
                    let _common_discount = listTemp.commonDiscounts[0];

                    let _date_now = moment().format("YYYY-MM-DD");
                    let _date_from = moment(_common_discount.valid_from).format("YYYY-MM-DD");
                    let _date_to = moment(_common_discount.valid_to).from("YYYY-MM-DD");

                    if (_date_now >= _date_from && _date_now <= _date_to) {

                        id_common_discount = _common_discount.id_common_discount;
                        porcentaje_descuento = _common_discount.percentage_discount;
                        is_auto_redeem = _common_discount.auto_redeem;

                        if (_copyListCommonDiscounts.find(x => x.id_common_discount === id_common_discount) === undefined) {
                            _copyListCommonDiscounts.push({
                                id_common_discount: id_common_discount,
                                is_available_discount: true,
                                is_enable: true
                            });
                        }

                        setArrayCommonDiscounts(_copyListCommonDiscounts);
                    }
                }


            }
            else if (mIdClient > 0 || mIdDoctor > 0) {
                if (listTemp.agreement !== null && listTemp.agreement.length > 0) {

                    let listAgrrementDiscountsCurves = listAgreementsDiscountCurves;
                    let _copyArrayAgreements = arrayAgreementDiscounts;

                    if (listTemp.is_curve) {

                        listTemp.agreement.forEach(_curve_agreement => {
                            if (listAgrrementDiscountsCurves.find(x => x.id_profile === listTemp.id_profile && x.id_profile_curve_price === _curve_agreement.id_profile_curve_price) === undefined) {
                                listAgrrementDiscountsCurves.push({
                                    id_agreement: _curve_agreement.id_agreement,
                                    id_agreement_test_range: _curve_agreement.id_agreement_test_range,
                                    id_profile_curve_price: _curve_agreement.id_profile_curve_price,
                                    id_profile: listTemp.id_profile,
                                    id_exam: null,
                                    percentage_discount: _curve_agreement.percentage,
                                    contract_number: _curve_agreement.contract_number,
                                    test: listTemp.name,
                                    is_pay_for_company: _curve_agreement.is_pay_for_company,
                                    required_invoice_company: _curve_agreement.required_invoice_company
                                });
                            }
                        });

                        setListAgreementsDiscountCurves(listAgrrementDiscountsCurves);
                    }
                    else {

                        let agreement = listTemp.agreement[0];

                        id_agreement = agreement.id_agreement;
                        id_agreement_test_range = agreement.id_agreement_test_range;
                        porcentaje_descuento = agreement.percentage;
                        contract_number = agreement.contract_number;
                        is_auto_redeem = true;

                        if (_copyArrayAgreements.find(x => x.id_agreement === id_agreement) === undefined) {

                            _copyArrayAgreements.push({
                                id_agreement: id_agreement,
                                id_agreement_test_range: id_agreement_test_range,
                                contract_number: contract_number,
                                is_available_discount: true,
                                is_enable: false,
                                is_pay_for_company: agreement.is_pay_for_company,
                                required_invoice_company: agreement.required_invoice_company
                            });
                        }

                        setArrayAgreementDiscounts(_copyArrayAgreements);
                    }

                }
            }
            //#endregion       

            let _copyCurves = listTemp.listCurves;

            if (_copyCurves !== null && _copyCurves.length > 0) {
                _copyCurves.forEach(_c => {
                    _c.exist = false
                });
            }

            list.push({
                is_canceled: false,
                exist: false,
                id_profile: listTemp.id_profile,
                id_exam: null,
                name: listTemp.name,
                abbreviation: listTemp.abbreviation,
                code: listTemp.code,
                id_rate: null,
                id_price: listTemp.id_price,
                name_rate: "",
                price: listTemp.price,
                specimen_name: "",
                id_specimen: null,
                array_specimens: array_specimens,
                discount: porcentaje_descuento,
                id_common_discount: id_common_discount,
                id_special_discount: descuentoEspecial,
                id_agreement: id_agreement,
                id_agreement_test_range: id_agreement_test_range,
                contract_number: contract_number,

                listIndications: listTemp.listExamIndications,

                is_urgent: false,
                is_curve: listTemp.is_curve,
                curves: _copyCurves,
                workOrderCurves: null,

                listExams: listTemp.listExams,

                config_iva: listTemp.config_iva,
                value_iva: listTemp.value_iva,
                is_quotation: false,
                id_profile_curve_price: 0,
                urgent_price: 0,
                auto_redeem: is_auto_redeem,
                is_campaign: false,
                name_campaign: "",

                is_referenced: false,
                name_external_laboratory: "",
                id_external_laboratory: "",
                is_edited_test: false
            });

            if (validPorcentejeSpecial === "https://labcen.helen-sw.com") {
                if (id_agreement === null) {
                    list.forEach(_find => {
                        _find.auto_redeem = false;
                        let _common_discounts = arrayCommonDiscounts.map(_common => {
                            if (_common.id_common_discount === _find.id_common_discount) {
                                _common.is_available_discount = false
                            }
                            return _common;
                        });

                        setArrayCommonDiscounts([..._common_discounts]);
                        return _find;
                    });
                }


            }





            //!VALID BUTTON METHOS
            let foundNotCanceled = list.some(item => item?.exist == false || item?.exist == undefined)

            setValidButton(foundNotCanceled)

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: list
            });

            setSearchExamProfileState({});
        }
    }


    const [validButton, setValidButton] = useState(false);

    const handleGetExam = async (id_exam) => {
        let mIdPatient = 0;
        let mIdClient = 0;
        let mIdAgreement = null;
        let mIdDoctor = 0;

        //#region VALIDATE IF IS COMPANY, DOCTOR, PATIENT OR MEMBERSHIP
        //console.log(formWorkOrder.id_company);
        if (formWorkOrder.id_company.value !== undefined) {
            mIdPatient = 0;
            mIdDoctor = 0;
            mIdClient = formWorkOrder.id_company.value;
            //console.log(idAgreementCompany);
            mIdAgreement = idAgreementCompany?.value ?? idAgreementCompany;
            //revisar mañana porque no toma el convenio
            //console.log(mIdAgreement);
        }
        else if (informationDoctor.length > 0) {
            let _find_doctor_by_agreement = informationDoctor.find(d => d.isCommercialPartner === true);

            if (_find_doctor_by_agreement !== undefined) {
                mIdPatient = 0;
                mIdClient = 0;
                mIdDoctor = _find_doctor_by_agreement.id_doctor;
                mIdAgreement = null;
            }
            else {
                mIdClient = 0;
                mIdAgreement = null;
                mIdDoctor = 0;
                mIdPatient = specialDiscount !== null ? 0 : id_patient;
            }
        }
        else {
            mIdClient = 0;
            mIdDoctor = 0;
            mIdAgreement = null;
            mIdPatient = specialDiscount !== null ? 0 : id_patient;
        }

        let alternative_method = "";

        if (is_membership) {
            alternative_method = "?is_membership=true";
        }
        // debugger;
        if (mIdAgreement !== null) {
            //console.log(mIdAgreement);

            alternative_method = (alternative_method == "" ? `?idAgreement=${mIdAgreement}` : `${alternative_method}&idAgreement=${mIdAgreement}`);
        }
        //#endregion

        let list = listTest;

        let listTemp = await handleRequest("GET", `Exam/GetPriceFromExam/${id_exam},${id_branch},${id_commercial_line},${mIdPatient},${mIdClient},${mIdDoctor},true${alternative_method}`, "Exámenes");

        if (listTemp !== null) {

            //#region VALIDATIONS DUPLICATE EXAM
            let validationExam = validationTest(id_exam);

            if (validationExam !== -1 && validationExam !== 2) {
                sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                return;
            }
            //#endregion

            //#region check specimens
            let valorCheck = true;
            let valorCheckForwarded = false;


            list.forEach(item => {
                if (item.id_specimen === parseInt(listTemp.id_specimen)) {
                    valorCheck = item.check_specimen;
                    valorCheckForwarded = false;
                }
            });
            //#endregion

            //#region DISCOUNTS
            let descuentoEspecial = null;

            let id_common_discount = null;

            let id_agreement = null;
            let id_agreement_test_range = null;
            let contract_number = null;

            let is_auto_redeem = false;

            let porcentaje_descuento = 0;

            if (specialDiscount !== null) {
                debugger;
                if (specialDiscount.examProfileInclude.length > 0) {
                    specialDiscount.examProfileInclude.forEach(obj => {
                        if (obj.id_exam === listTemp.id_exam) {
                            if (obj.percentege > 0) {
                                porcentaje_descuento = obj.percentege;
                                descuentoEspecial = specialDiscount.id_special_discount;
                                is_auto_redeem = true;
                            }
                            else {
                                porcentaje_descuento = specialDiscount.percentege;
                                descuentoEspecial = specialDiscount.id_special_discount;
                                is_auto_redeem = true;
                            }
                        }
                    });
                }

                if (specialDiscount.is_all_validate_in_exams && !listTemp.is_referenced) {
                    porcentaje_descuento = specialDiscount.percentage;
                    descuentoEspecial = specialDiscount.id_special_discount;
                    is_auto_redeem = true;
                }
            }
            else if (listTemp.validateExamCommonDiscount !== null) {

                let _copyListCommonDiscountsE = arrayCommonDiscounts;

                let date_now = moment().format("YYYY-MM-DD");
                let date_from = moment(listTemp.validateExamCommonDiscount.valid_from).format("YYYY-MM-DD");
                let date_to = moment(listTemp.validateExamCommonDiscount.valid_to).format("YYYY-MM-DD");

                if (date_now >= date_from && date_now <= date_to) {
                    id_common_discount = listTemp.validateExamCommonDiscount.id_common_discount;
                    is_auto_redeem = listTemp.validateExamCommonDiscount.auto_redeem;
                    porcentaje_descuento = listTemp.validateExamCommonDiscount.percentage_discount;

                    if (_copyListCommonDiscountsE.find(x => x.id_common_discount === id_common_discount) === undefined) {
                        _copyListCommonDiscountsE.push({
                            id_common_discount: id_common_discount,
                            is_available_discount: true,
                            is_enable: true
                        });
                    }

                    setArrayCommonDiscounts(_copyListCommonDiscountsE);
                }
            }
            else if (mIdClient > 0 || mIdDoctor) {
                if (listTemp.agreement !== null) {

                    let listAgreementDiscounts = arrayAgreementDiscounts;

                    let agreement = listTemp.agreement;

                    id_agreement = agreement.id_agreement;
                    id_agreement_test_range = agreement.id_agreement_test_range;
                    porcentaje_descuento = agreement.percentage;
                    contract_number = agreement.contract_number;
                    is_auto_redeem = true;

                    if (listAgreementDiscounts.find(x => x.id_agreement === id_agreement)) {
                        listAgreementDiscounts.push({
                            id_agreement: id_agreement,
                            id_agreement_test_range: id_agreement_test_range,
                            contract_number: contract_number,
                            is_available_discount: true,
                            is_enable: true
                        });
                    }

                    setArrayAgreementDiscounts(listAgreementDiscounts);
                }
            }
            //#endregion

            list.push({
                is_canceled: false,
                exist: false,
                id_exam: listTemp.id_exam,
                id_profile: null,
                name: listTemp.name,
                abbreviation: listTemp.abbreviation,
                code: listTemp.code,
                id_rate: listTemp.id_rate,
                id_price: listTemp.id_price,
                name_rate: listTemp.name_rate,
                price: listTemp.price,
                specimen_name: listTemp.name_specimen,
                id_specimen: listTemp.id_specimen,
                check_specimen: valorCheck,
                check_forwarded: valorCheckForwarded,
                discount: porcentaje_descuento,
                id_common_discount: id_common_discount,
                id_special_discount: descuentoEspecial,
                id_agreement: id_agreement,
                id_agreement_test_range: id_agreement_test_range,
                contract_number: contract_number,
                listIndications: listTemp.listIndications,
                is_urgent: false,
                is_curve: false,
                config_iva: listTemp.config_iva,
                value_iva: listTemp.value_iva,
                is_quotation: false,
                listExams: null,
                urgent_price: 0,
                auto_redeem: is_auto_redeem,
                is_campaign: false,
                name_campaign: "",

                is_referenced: listTemp.is_referenced,
                name_external_laboratory: listTemp.name_external_laboratory,
                id_external_laboratory: listTemp.id_external_laboratory,
                listExternalLaboratories: listTemp.listExternalLaboratories,
                is_edited_test: false,
            });



            if (validPorcentejeSpecial === "https://labcen.helen-sw.com") {

                if (id_agreement === null) {
                    list.forEach(_find => {
                        _find.auto_redeem = false;
                        let _common_discounts = arrayCommonDiscounts.map(_common => {
                            if (_common.id_common_discount === _find.id_common_discount) {
                                _common.is_available_discount = false
                            }
                            return _common;
                        });

                        setArrayCommonDiscounts([..._common_discounts]);
                        return _find;
                    });
                }

            }


            //!VALID BUTTON METHOS
            let foundNotCanceled = list.some(item => item?.exist == false || item?.exist == undefined)

            setValidButton(foundNotCanceled)

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: list
            });

            setSearchExamProfileState({});
        }
    }

    const changeSelectSamplings = (e, id_profileAux, isUpdate = false) => {
        let allRequisitionExams = listTest;

        let id_profile_curve_price = parseInt(e.target.value);

        let findRequestExam = allRequisitionExams.find(r => r.id_profile === parseInt(id_profileAux));

        if (isUpdate) {
            let newTest = [];
            if (findRequestExam.exist) {
                let workOrderCurves_canceled = null;

                if (findRequestExam.workOrderCurves.exist) {
                    workOrderCurves_canceled = findRequestExam.workOrderCurves;
                }
                else {
                    if (findRequestExam.workOrderCurves_canceled !== undefined && findRequestExam.workOrderCurves_canceled !== null) {
                        workOrderCurves_canceled = findRequestExam.workOrderCurves_canceled;
                    }
                }

                newTest = allRequisitionExams.map(_test => {
                    if (_test.id_profile === parseInt(id_profileAux)) {

                        if (workOrderCurves_canceled !== null) {

                            if (workOrderCurves_canceled.id_profile_curve_price === parseInt(id_profile_curve_price)) {
                                _test.workOrderCurves = _test.workOrderCurves_canceled;
                                _test.price = _test.workOrderCurves_canceled.price;
                                _test.workOrderCurves_canceled = null;
                            }
                            else {
                                let _selected_curve = findRequestExam.curves.find(x => x.id_profile_curve_price === id_profile_curve_price);
                                let newWorkOrderCurves = [];

                                if (_selected_curve) {
                                    let curves_aux = [];

                                    for (let index = 1; index <= _selected_curve.number_sampling; index++) {

                                        curves_aux.push({
                                            value: 0,
                                            label: ''
                                        });
                                    }

                                    newWorkOrderCurves = {
                                        exist: false,
                                        id_price: _selected_curve.id_profile_curve_price,
                                        id_profile_curve_price: _selected_curve.id_profile_curve_price,
                                        id_work_order_exam: workOrderCurves_canceled.id_work_order_exam,
                                        id_profile_price: _selected_curve.id_profile_price,
                                        number_sampling: _selected_curve.number_sampling,
                                        price: _selected_curve.price,
                                        listTimeCurves: curves_aux
                                    }

                                    _test.price = _selected_curve.price;
                                }

                                _test.workOrderCurves = newWorkOrderCurves;
                                _test.workOrderCurves_canceled = workOrderCurves_canceled;
                            }
                        }
                    }

                    return _test;
                });
            }
            else {
                let _selected_curve = findRequestExam.curves.find(x => x.id_profile_curve_price === id_profile_curve_price);
                let newWorkOrderCurves = [];
                let priceCurve = 0.0;

                if (_selected_curve) {
                    let curves_aux = [];

                    for (let index = 1; index <= _selected_curve.number_sampling; index++) {

                        curves_aux.push({
                            value: 0,
                            label: ''
                        });
                    }

                    newWorkOrderCurves = {
                        exist: false,
                        id_price: _selected_curve.id_profile_curve_price,
                        id_profile_curve_price: _selected_curve.id_profile_curve_price,
                        id_work_order_exam: null,
                        id_profile_price: _selected_curve.id_profile_price,
                        number_sampling: _selected_curve.number_sampling,
                        price: _selected_curve.price,
                        listTimeCurves: curves_aux
                    };

                    priceCurve = _selected_curve.price;
                }

                newTest = allRequisitionExams.map(t => {
                    if (t.id_profile === parseInt(id_profileAux)) {
                        t.workOrderCurves = newWorkOrderCurves;
                        t.workOrderCurves_canceled = null;
                        t.price = priceCurve;
                    }

                    return t;
                });
            }

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: [...newTest]
            });
        }
        else {
            let curva_seleccionada = findRequestExam.curves.find(x => x.id_profile_curve_price === id_profile_curve_price);

            let _find_curve_agreement = listAgreementsDiscountCurves.find(x => x.id_profile === id_profileAux && x.id_profile_curve_price === id_profile_curve_price);
            let _find_curve_common_discount = listCommonDiscountsCurves.find(x => x.id_profile === id_profileAux && x.id_profile_curve_price === id_profile_curve_price);

            let nuevos = allRequisitionExams.map(r => {
                if (r.id_profile == parseInt(id_profileAux)) {

                    let curves_aux = [];
                    let _listCommonDiscounts = arrayCommonDiscounts;
                    let _listAgreements = arrayAgreementDiscounts;

                    r.price = curva_seleccionada.price;

                    if (_find_curve_agreement !== undefined) {
                        r.discount = _find_curve_agreement.percentage_discount;
                        r.id_agreement = _find_curve_agreement.id_agreement;
                        r.id_agreement_test_range = _find_curve_agreement.id_agreement_test_range;
                        r.contract_number = _find_curve_agreement.contract_number;
                        r.auto_redeem = true;

                        if (_listAgreements.find(x => x.id_agreement === _find_curve_agreement.id_agreement) === undefined) {
                            _listAgreements.push({
                                id_agreement: _find_curve_agreement.id_agreement,
                                id_agreement_test_range: _find_curve_agreement.id_agreement_test_range,
                                contract_number: _find_curve_agreement.contract_number,
                                is_available_discount: true,
                                is_enable: false
                            });
                        }

                        setArrayAgreementDiscounts(_listAgreements);
                    }
                    else if (_find_curve_common_discount !== undefined) {

                        if (_listCommonDiscounts.find(x => x.id_common_discount === _find_curve_common_discount.id_common_discount) === undefined) {
                            _listCommonDiscounts.push({
                                id_common_discount: _find_curve_common_discount.id_common_discount,
                                is_available_discount: true,
                                is_enable: (r.is_campaign ? false : true)
                            });

                            setArrayCommonDiscounts(_listCommonDiscounts);
                        }

                        r.id_common_discount = _find_curve_common_discount.id_common_discount;
                        r.discount = _find_curve_common_discount.percentage_discount;
                        r.auto_redeem = true;
                    }

                    for (let index = 1; index <= curva_seleccionada.number_sampling; index++) {

                        curves_aux.push({
                            ...curva_seleccionada,
                            time: "",
                            number_sampling_aux: index
                        });
                    }

                    r.curves_aux = curves_aux;
                }

                return r;
            });

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: [...nuevos]
            });
        }
    }

    const changeValueTimeSampling = (e, id_profileAux, number_sampling, is_editWorkOrder = false) => {
        let allRequisitionExams = listTest;

        let tiempo_axu = e;

        let nuevos = allRequisitionExams.map(r => {
            if (r.id_profile === parseInt(id_profileAux)) {

                if (is_editWorkOrder === true) {
                    let newListTimes = r.workOrderCurves.listTimeCurves.map((c, cIndex) => {
                        if (cIndex === parseInt(number_sampling)) {
                            c = tiempo_axu;
                        }

                        return c;
                    });

                    r.workOrderCurves.listTimeCurves = newListTimes;
                }
                else {
                    let curves_aux = r.curves_aux;
                    let nuevas_curvas = curves_aux.map(c => {
                        if (c.number_sampling_aux === number_sampling) {
                            c.time = tiempo_axu;
                        }

                        return c;
                    });
                    r.curves_aux = nuevas_curvas;
                }
            }
            return r;
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: [...nuevos]
        });
    }

    const handleChangeExternalLaboratories = (e, id_exam = null, id_profile = null) => {
        let _value = e.target.value;

        let _copyTest = listTest.map(_test => {

            if (id_exam !== null && _test.id_exam === id_exam) {
                let findExternalLaboratories = _test.listExternalLaboratories.find(x => x.id_price === parseInt(_value));

                if (findExternalLaboratories !== undefined) {
                    _test.id_external_laboratory = findExternalLaboratories.id_external_laboratory;
                    _test.name_external_laboratory = findExternalLaboratories.name_external_laboratory;
                    _test.id_price = findExternalLaboratories.id_price;
                    _test.price = findExternalLaboratories.price;
                }
            }

            return _test;
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: _copyTest
        });
    }

    const removeExamProfile = (id_exam = null, id_profile = null) => {
        let findTest = listTest.find(r => (id_exam !== null ? r.id_exam === parseInt(id_exam) : r.id_profile === parseInt(id_profile)));

        let tmpDevolutions = devolutionsW;

        tmpDevolutions.id_work_order = formWorkOrder.id_work_order;

        if (findTest !== undefined && findTest.exist) {
            setLoading(false);
            setModalTitle("Nueva devolución");
            GetDevolutions();
            toggleModalDevolutions();

            setIdTestDelete({
                id_exam: id_exam,
                id_profile: id_profile
            });
        }
        //modificar estar parte
        let _id_common_discount = null;
        let _id_agreement = null;
        let _is_curve = false;

        if (findTest !== null) {

            _id_common_discount = findTest.id_common_discount;
            _id_agreement = findTest.id_agreement;
            _is_curve = findTest.is_curve;

            let newListTest = listTest.filter(item => {

                if (!item.exist) {

                    if ((id_exam !== null && item.id_exam === parseInt(id_exam)) || (id_profile !== null && item.id_profile === parseInt(id_profile))) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else if (item.exist) {

                    if ((id_exam !== null && item.id_exam === parseInt(id_exam)) || (id_profile !== null && item.id_profile === parseInt(id_profile))) {
                        item.is_canceled = true;

                        tmpDevolutions.listTestDevolution.push({
                            id_work_order_profile: id_profile !== null ? item.id_work_order_profile : null,
                            id_work_order_exam: id_exam !== null ? item.id_work_order_exam : null,
                            code: item.code,
                            name: item.name,
                            amount: item.price,
                            iva: item.tax,
                            extra: item.urgent_price,
                            discount: item.amountDiscount,
                            is_edited_test: item.is_edited_test,
                            is_editWorkOrder: isEditWorkOrder,
                        });
                        return true;
                    }
                    else {
                        return true;
                    }
                }
            });

            //DELETE COMMON DISCOUNT FROM ARRAY COMMON DISCOUNTS
            if (_id_common_discount !== null) {
                if (newListTest.find(x => x.id_common_discount === _id_common_discount) === undefined) {
                    let _new_list_common_discounts = arrayCommonDiscounts.filter(_common => {
                        if (_common.id_common_discount === _id_common_discount) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    });

                    setArrayCommonDiscounts([..._new_list_common_discounts]);
                }
            }//DELETE AGREEMENT FROM ARRAY AGREEMENTS
            else if (_id_agreement !== null) {
                if (newListTest.find(x => x.id_agreement === _id_agreement) === undefined) {

                    let _new_list_agreements = arrayAgreementDiscounts.filter(_agreement => {
                        if (_agreement.id_agreement === _id_agreement) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    });

                    setArrayAgreementDiscounts([..._new_list_agreements]);
                }
            }

            if (_is_curve) {

                if (_id_common_discount !== null) {
                    let _new_list_common_discount_curves = listCommonDiscountsCurves.filter(_curve => {
                        if (_curve.id_profile === id_profile) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    });

                    setListCommonDiscountsCurves([..._new_list_common_discount_curves]);
                }
                else if (_id_agreement !== null) {
                    let _new_list_agreement_curves = listAgreementsDiscountCurves.filter(_curve => {
                        if (_curve.id_profile === id_profile) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    });

                    setListAgreementsDiscountCurves([..._new_list_agreement_curves]);
                }
            }

            if (newListTest.length == 0) {

                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    listTest: newListTest,
                    listPayMethods: [{
                        id_pay_method: 0,
                        amount: 0.0,
                        is_edited: false,
                        deleted: false
                    }]
                });

                setMoneyEntered(0.00);
            }
            else {
                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    listTest: newListTest,
                    listPrueba: tmpDevolutions.listTestDevolution
                });
            }
        }
    }

    const handleCheckUrgent = (e, id_exam = null, id_profile = null) => {
        let listTmp = listTest;

        let _find_test = listTmp.find(x => x.id_exam === id_exam && x.id_profile === id_profile);

        if (_find_test !== undefined) {
            _find_test.is_urgent = e.target.checked;
            _find_test.is_edited_test = true;
        }

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: [...listTmp]
        });
    }

    const handleCheckForwarded = (id_exam, id_profile = null, id_specimen = null) => {
        let allRequisitionExams = listTest;

        let findRequestExam = allRequisitionExams.find(r => (id_exam !== null ? r.id_exam === parseInt(id_exam) : r.id_profile === parseInt(id_profile)));

        let valor = 0;
        let sub = null;
        if (id_profile !== null && id_specimen !== null) {
            sub = findRequestExam.array_specimens.find(x => x.id_specimen === id_specimen);
            valor = !sub.check_forwarded;
        }
        else {
            valor = !findRequestExam.check_forwarded;
        }

        allRequisitionExams.map(item => {
            if (item.array_specimens) {
                item.array_specimens.map(element => {
                    if (sub === null) {
                        if (item.id_exam != null) {
                            if (element.id_specimen === findRequestExam.id_specimen && item.id_exam == id_exam) {
                                element.check_forwarded = valor;
                            }
                        } else if (item.id_profile != null) {
                            if (element.id_specimen === findRequestExam.id_specimen && item.id_profile == id_profile) {
                                element.check_forwarded = valor;
                            }
                        }
                    }
                    else {
                        if (item.id_exam != null) {
                            if (element.id_specimen === sub.id_specimen && item.id_exam == id_exam) {
                                element.check_forwarded = valor;
                            }
                        } else if (item.id_profile != null) {
                            if (element.id_specimen === sub.id_specimen && item.id_profile == id_profile) {
                                element.check_forwarded = valor;
                            }
                        }
                    }

                    return element;
                });
            }
            else {
                if (sub === null) {
                    if (item.id_exam != null) {
                        if (item.id_specimen === findRequestExam.id_specimen && item.id_exam == id_exam) {
                            item.check_forwarded = valor;
                        }
                    }
                    else if (item.id_profile != null) {
                        if (item.id_specimen === findRequestExam.id_specimen && item.id_profile == id_profile) {
                            item.check_forwarded = valor;
                        }
                    }
                }
                else {
                    if (item.id_exam != null) {
                        if (item.id_specimen === sub.id_specimen && item.id_exam == id_exam) {
                            item.check_forwarded = valor;
                        }
                    } else if (item.id_profile != null) {
                        if (item.id_specimen === sub.id_specimen && item.id_profile == id_profile) {
                            item.check_forwarded = valor;
                        }
                    }

                }
            }
            return item;
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: allRequisitionExams
        });
    }

    const handleCheckSpecimen = (id_exam, id_profile = null, id_specimen = null) => {
        let allRequisitionExams = listTest;

        let findRequestExam = allRequisitionExams.find(r => (id_exam !== null ? r.id_exam === parseInt(id_exam) : r.id_profile === parseInt(id_profile)));

        let valor = 0;
        let sub = null;

        if (id_profile !== null && id_specimen !== null) {
            sub = findRequestExam.array_specimens.find(x => x.id_specimen === id_specimen);
            valor = !sub.check_specimen;
        }
        else {
            valor = !findRequestExam.check_specimen;
        }

        allRequisitionExams.map(item => {
            if (item.array_specimens) {
                item.array_specimens.map(element => {
                    if (sub === null) {
                        if (item.id_exam != null) {
                            if (element.id_specimen === findRequestExam.id_specimen && item.id_exam == id_exam) {
                                element.check_specimen = valor;
                            }
                        } else if (item.id_profile != null) {
                            if (element.id_specimen === findRequestExam.id_specimen && item.id_profile == id_profile) {
                                element.check_specimen = valor;
                            }
                        }
                    }
                    else {
                        if (item.id_exam != null) {
                            if (element.id_specimen === sub.id_specimen && item.id_exam == id_exam) {
                                element.check_specimen = valor;
                            }
                        } else if (item.id_profile != null) {
                            if (element.id_specimen === sub.id_specimen && item.id_profile == id_profile) {
                                element.check_specimen = valor;
                            }
                        }
                    }

                    return element;
                });
            }
            else {
                if (sub === null) {
                    if (item.id_exam != null) {
                        if (item.id_specimen === findRequestExam.id_specimen && item.id_exam == id_exam) {
                            item.check_specimen = valor;
                        }
                    }
                    else if (item.id_profile != null) {
                        if (item.id_specimen === findRequestExam.id_specimen && item.id_profile == id_profile) {
                            item.check_specimen = valor;
                        }
                    }
                }
                else {
                    if (item.id_exam != null) {
                        if (item.id_specimen === sub.id_specimen && item.id_exam == id_exam) {
                            item.check_specimen = valor;
                        }
                    } else if (item.id_profile != null) {
                        if (item.id_specimen === sub.id_specimen && item.id_profile == id_profile) {
                            item.check_specimen = valor;
                        }
                    }

                }
            }
            return item;
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: allRequisitionExams
        });
    }

    const handleViewExamsProfile = (id_profile) => {

        let findExams = listTest.find(x => x.id_profile === parseInt(id_profile));
        if (findExams) {

            setViewExamsProfile(findExams.listExams);
            toggleModalViewExams();
        }
    }

    const handleAddPayMethodInputs = () => {
        let listPayMethod = listPayMethods;

        listPayMethod.push({
            id_pay_method: 0,
            amount: 0.0,
            is_edited: false,
            deleted: false
            // id_branch: 0
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listPayMethods: listPayMethod
        });
    }

    const handleGiveChange = (e, nameInput, nameArraysInput) => {
        handleDinamicInputWorkOrder(e, nameInput, nameArraysInput);
        let totalIngresado = 0;

        listPayMethods.forEach(obj => {

            totalIngresado += (obj.amount === "") ? 0.00 : (parseFloat(obj.amount) < 0 ? 0 : parseFloat(obj.amount));
        });
        totalIngresado = !isNaN(totalIngresado) ? totalIngresado : 0;
        setMoneyEntered(totalIngresado.toFixed(2));
    }

    const handleDeletePayMethod = (index) => {
        let copyListPayMethods = listPayMethods;
        let money = 0;

        if (listPayMethods.length > 1) {
            copyListPayMethods.splice(index, 1);
        }

        copyListPayMethods.forEach(obj => {
            money += parseFloat(obj.amount)
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listPayMethods: [...copyListPayMethods]
        });

        setMoneyEntered(money.toFixed(2));
    }

    let idNew = formWorkOrder.id_doctor

    const saveWorkOrder = async (validPercentagePrice = -1) => {

        setLoading(true);
        setEnableBtnSaveWorkOrder(true);

        if (validationWorkOrder()) {

            setLoading(false);

            setEnableBtnSaveWorkOrder(false);

            return;
        }

        if (validArrayCurva1()) {
            setLoading(false);
            setEnableBtnSaveWorkOrder(false);
            createSweet("create", "warning", "Curvas", "Selecciona opción de la toma");
            return;
        }

        if (validArrayCurva()) {
            setLoading(false);
            setEnableBtnSaveWorkOrder(false);
            createSweet("create", "warning", "Curvas", "Configura el tiempo de la toma");
            return;
        }

        if (moneyEntered > 0) {
            let percentage = (moneyEntered * (50 / 100));
            percentage = parseFloat(percentage.toFixed(2));

            let handleChange = (parseFloat(moneyEntered) - totalW);
            handleChange = !isNaN(handleChange) ? parseFloat(handleChange.toFixed(2)) : 0;

            if (handleChange > percentage && validPercentagePrice === -1) {
                sweetConfirmation("El cambio supera el 50% del total", "Deseas cambiar la cantidad ingresada", "warning", true, saveWorkOrder);
                return;
            }
        }

        if (!validPercentagePrice) {
            return;
        }

        let arrayDoctors = [];

        if (formWorkOrder.id_doctor.length === undefined) {
            if (formWorkOrder.id_doctor === 0) {
                arrayDoctors = [];
            }
            else {
                arrayDoctors.push({
                    id_doctor: idNew
                });
            }
        }

        if (formWorkOrder.id_doctor.length === 1) {
            formWorkOrder.id_doctor.forEach(obj => {
                arrayDoctors.push({
                    id_doctor: obj.value
                });
            })
        }

        if (formWorkOrder.id_doctor.length >= 2) {
            formWorkOrder.id_doctor.forEach(obj => {
                arrayDoctors.push({
                    id_doctor: obj.value
                });
            })
        }

        // let arraySupplies = [];
        // if(listSupplies.length > 0){
        //     listSupplies.forEach(element => {
        //         arraySupplies.push({
        //             id_additional: element.id_additional,
        //             code: element.code,
        //             name: element.name,
        //             price: element.price
        //         })
        //     })
        // }
        // //console.log('arraySupplies: ',arraySupplies)

        let body = {
            id_patient: formWorkOrder.id_patient,
            id_branch: formWorkOrder.id_branch,
            id_company: (formWorkOrder.id_company.value === undefined) ? 0 : formWorkOrder.id_company.value,
            id_printer_point: formWorkOrder.id_printer_point.value,
            id_work_order_priority: null,
            id_quoter: formWorkOrder.id_quoter,
            listExamProfile: formWorkOrder.listTest,
            observations: formWorkOrder.observations,
            observations_sample: formWorkOrder.observations_sample,
            observations_general: formWorkOrder.observations_general,
            print_results: formWorkOrder.checkPrint,
            results_partial: formWorkOrder.checkPartial,
            send_email: formWorkOrder.checkEmail,
            send_whatsapp: formWorkOrder.checkWhats,
            send_doctor: formWorkOrder.checkDoctor,
            requiered_invoice_patient: !availableInvoicePatient ? false : formWorkOrder.checkInvoice,
            business_name: formWorkOrder.business_name,
            rfc: formWorkOrder.rfc,
            phone: formWorkOrder.phone,
            email: formWorkOrder.email,
            id_use_cfdi: formWorkOrder.id_use_cfdi,
            print_invoice: formWorkOrder.print_invoice,
            send_invoice: formWorkOrder.send_invoice,
            listDoctors: arrayDoctors,

            id_income_type: checkTypeClient,
            listPayMethods: listPayMethods,
            iva: totalTaxesW,
            is_pay_for_company: isPayForCompany,
            requiered_invoice: requiredInvoiceCompany,
            percentageDiscount: formWorkOrder.percentageDiscount,
            total_price: formWorkOrder.total_price,
            credit_max_amount: formWorkOrder.credit_max_amount,
            max_discount: formWorkOrder.max_discount,
            id_work_order_type_service: (formWorkOrder.id_work_order_type_service.value === undefined) ? 0 : formWorkOrder.id_work_order_type_service.value,
            id_pet: (formValuesPet.id_pet === undefined) ? 0 : formValuesPet.id_pet,
        };

        console.log(body);
        let listTmp = await handleRequest("POST", "WorkOrder/Create", "Admisión", body);
        console.log('2.- ',listTmp)
        if (listTmp !== null) {

            let id_work_order = listTmp.id_work_order;
            setIdWorkOrder(id_work_order);

            setLoading(false);
            setEnableBtnSaveWorkOrder(false);
            createSweet("create", "success", "Exito!");

            cancellWorkOrder();
        }
        else {
            setLoading(false);
            setEnableBtnSaveWorkOrder(false);
        }
    }

    const [doctorToDelete, setDoctorToDelete] = useState(null);

    const handleChooseDoctorToDelete = (idOfDoctor) => {
        setDoctorToDelete(idOfDoctor);
    }

    const constructDoctorsArray = () => {
        let array = [];
        if (formWorkOrder.id_doctor) {
            formWorkOrder.id_doctor.forEach(obj => {
                if (doctorToDelete && obj.value === doctorToDelete) {
                    return;
                }
                array.push({ id_doctor: obj.value });
            });
        }

        return array;
    }

    const saveUpdateWorkOrder = async (validPercentagePrice = -1) => {
        //let arrayDoctors = [];
        let arrayDoctors = constructDoctorsArray();
        setLoading(true);
        setEnableBtnSaveWorkOrder(true);

        if (validationWorkOrder()) {
            setLoading(false);
            setEnableBtnSaveWorkOrder(false);
            return;
        }

        if (validArrayCurva1()) {
            setLoading(false);
            setEnableBtnSaveWorkOrder(false);
            createSweet("create", "warning", "Curvas", "Selecciona opción de la toma");
            return;
        }

        if (moneyEntered > 0) {
            let percentage = (moneyEntered * (50 / 100));
            percentage = parseFloat(percentage.toFixed(2));

            let handleChange = (parseFloat(moneyEntered) - totalW);
            handleChange = !isNaN(handleChange) ? parseFloat(handleChange.toFixed(2)) : 0;

            if (handleChange > percentage && validPercentagePrice === -1) {
                sweetConfirmation("El cambio supera el 50% del total", "Deseas cambiar la cantidad ingresada", "warning", true, saveUpdateWorkOrder);
                return;
            }
        }

        if (!validPercentagePrice) {
            return;
        }

        // if (formWorkOrder.id_doctor) {
        //     formWorkOrder.id_doctor.forEach(obj => {
        //         arrayDoctors.push({
        //             id_doctor: obj.value
        //         });
        //     })
        // }

        let _listDoctors = listDoctors.map(_doctor => {
            if (_doctor.id_work_order_doctor === undefined) {
                _doctor.id_work_order_doctor = null;
            }
            _doctor.id_work_order = id_work_order;
            return _doctor;
        });

        let _listPayMethods = listPayMethods.map(_payment => {
            if (_payment.id_work_order_pay === null) {
                _payment.id_work_order_pay = null;
            }

            _payment.id_work_order = formWorkOrder.id_work_order;
            _payment.id_branch = id_branch

            return _payment;
        });

        let _listTest = [];

        listTest.forEach(_test => {
            _listTest.push({
                id_work_order: formWorkOrder.id_work_order,
                exist: _test.exist,
                id_work_order_exam: _test.id_work_order_exam,
                id_work_order_profile: _test.id_work_order_profile,
                id_agreement: _test.id_agreement,
                id_agreement_test_range: _test.id_agreement_test_range,
                id_common_discount: _test.id_common_discount,
                id_exam: _test.id_exam,
                id_price: (_test.id_exam !== null ? _test.id_price : null),
                id_profile: _test.id_profile,
                id_profile_price: (_test.id_exam === null ? _test.id_price : null),
                id_rate: _test.id_rate,
                id_special_discount: _test.id_special_discount,
                name: _test.name,
                code: _test.code,
                is_canceled: _test.is_canceled,
                is_curve: _test.is_curve,
                is_referenced: false,
                is_urgent: _test.is_urgent,
                percentage_discount: 0,
                amount_discount: _test.discount,
                price: _test.price,
                price_urgent: _test.urgent_price,
                specimenExam: _test.id_exam === null ? null : {
                    id_specimen: _test.id_specimen,
                    check_specimen: _test.check_specimen,
                    check_forwarded: _test.check_forwarded
                },
                listSpecimen: _test.id_profile === null ? null : _test.array_specimens,
                workOrderCurves: !_test.is_curve ? null : _test.workOrderCurves
            });
        });

        let body = {
            id_work_order: formWorkOrder.id_work_order,
            id_patient: formWorkOrder.id_patient,
            id_branch: formWorkOrder.id_branch,
            id_company: (formWorkOrder.id_company.value === undefined) ? 0 : formWorkOrder.id_company.value,
            id_printer_point: formWorkOrder.id_printer_point.value,
            observations: formWorkOrder.observations,
            print_results: formWorkOrder.checkPrint,
            send_whatsapp: formWorkOrder.checkWhats,
            send_doctor: formWorkOrder.checkDoctor,
            results_partial: formWorkOrder.checkPartial,
            send_email: formWorkOrder.checkEmail,
            nim: formWorkOrder.nim,
            observations_general: formWorkOrder.observations_general,
            observations_sample: formWorkOrder.observations_sample,
            listDoctors: arrayDoctors,
            listPayMethods: _listPayMethods,
            listTest: _listTest,
            percentageDiscount: formWorkOrder.percentageDiscount,
            total_price: formWorkOrder.total_price,
            credit_max_amount: formWorkOrder.credit_max_amount,
            max_discount: formWorkOrder.max_discount,
            id_work_order_type_service: (formWorkOrder.id_work_order_type_service.value === undefined) ? 0 : formWorkOrder.id_work_order_type_service.value,
            id_pet: (formValuesPet.id_pet === undefined) ? 0 : formValuesPet.id_pet
        }
        console.log('3.- ',body)

        // handleDeleteDoctor()

        let response = await handleRequest("PUT", "WorkOrder/UpdateWorkOrder", "Actualizar ingreso", body);
        console.log('4.- ', response)
        if (response !== null) {
            setLoading(false);
            setEnableBtnSaveWorkOrder(false);
            setDevolutionsW({
                id_return_reason: 0,
                id_work_order: 0,
                listTestDevolution: []
            });

            createSweet("create", "success", "Exito!");
            setDoctorToDelete(null);
            window.location.reload()
        }
    }

    const cancellWorkOrder = () => {
        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            id_patient: 0,
            id_branch: id_branch,
            id_company: 0,
            id_doctor: 0,
            observations: "",
            observations_sample: "",
            observations_general: "",
            checkPrint: true,
            checkPartial: true,
            checkEmail: false,
            checkWhats: false,
            checkDoctor: false,
            checkInvoice: true,
            business_name: "",
            rfc: "",
            phone: "",
            email: "",
            id_use_cfdi: 0,
            print_invoice: 0,
            send_invoice: 0,
            listTest: [],
            listPayMethods: [{
                id_pay_method: 0,
                amount: 0.0,
                id_branch: 0
            }],
            listDoctors: [],
            percentageDiscount: 0,
            total_price: 0,
            credit_max_amount: 0,
            max_discount: 0,
            //listTypeServices: [],
            id_work_order_type_service: 0,
            id_pet: 0,
        });

        setArrayCommonDiscounts([]);
        setArrayNameDiscount([]);

        setMoneyEntered(0.0);

        //PATIENT
        setSearchPatientState([]);
        setInformationPatient({
            birthday: null,
            is_complete: false,
            phone: null,
            gender: null,
            age: null,
            email: null,
            curp: null,
            membership: null,
            name: null,
            paternal_surname: null,
            maternal_surname: null,
            id_social_gender_patient: 0,
            cp: null,
            id_blood_type: null,
            colony: null,
            address: null,
            id_state: 0,
            id_municipality: 0,
            expedient_number: null,
        });

        handleUpdateValuesPatient({
            id_gender: 0,
            id_state_patient: 0,
            id_municipality_patient: 0,
            id_social_gender_patient: 0,
            id_blood_type: 0,
            code: "",
            paternal_surname: "",
            maternal_surname: "",
            name: "",
            birthday: "",
            age: "",
            curp: "",
            address_patient: "",
            cp_patient: "",
            colony_patient: "",
            phone_patient: "",
            email_patient: "",
            who_collects_exams: "",
            membership: "",
            cardnumber: "",
            img64File: "",
            expedient_number: "",
        });

        //DOCTOR
        setSearchDoctorState([]);
        setInformationDoctor([]);
        setIdDoctor(0);
        setDoctList([]);
        setIdDoctorSelect(0);

        handleUpdateValuesDoctor({
            id_specialty: 0,
            name_doctor: "",
            professional_license: "",
            phone_doctor: "",
            address_doctor: "",
            email_doctor: "",
            name_specialty: "",
            isCommercialPartner: false
        });

        setViewWorkOrder(false);

        //SPECIAL DISCOUNTS
        setSpecialDiscount(null);
        setIs_membership(false);

        //VARIABLES
        setcheckComments(false);
        setCheckDiscopunt(false);
        SetShowClassNameC("d-none mb-2");
        SetShowDiscount("d-none mb-2");
        setArrayAgreementDiscounts([]);
        setListCommonDiscountsCurves([]);
        setListAgreementsDiscountCurves([]);

        setIsPayForCompany(false);
        setRequiredInvoiceCompany(false);
        setAvailableInvoicePatient(false);
        setListAgreements([]);
        setIdAgreementCompany(0);
    }

    const printTicket = async (id_work_order) => {

        if (id_work_order > 0) {

            let token = localStorage.getItem("token");

            await fetch(ConfigServer.serverUrl + "/api/PrintTicket/PrintTicket/" + id_work_order, {
                method: 'POST',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
                .then(response => {
                    if (response.status !== 200) {
                        createSweet("warning", "warning", "No se descargo el Ticket");
                        return;
                    }

                    return response.blob();
                })
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "ticket.pdf")
                    document.body.appendChild(link);
                    link.style.display = 'none';
                    link.click();

                    setIdWorkOrder(0);
                })
        }
    }
    //#endregion WORK ORDER METHODS

    //#region WORK ORDER UPDATE

    const handleGetUpdateWorkOrder = async (_idWorkOrder, _idCommercialLine) => {

        setIsEditWorkOrder(true);
        setLoadingEditWorkOrder(true);

        let response = await handleRequest("GET", `WorkOrder/DetailWorkOrderById/${_idWorkOrder}/${_idCommercialLine}`);

        if (response !== null) {

            let listTest = [];
            let listPayments = [];
            let totalPayment = 0.0;
            let totalDevolutions = 0.0;
            let listIdDoctors = [];
            let listInformationDoctors = [];
            let listSearchDoctor = [];
            let newSupplies = [];
            let listDiscount = []

            // if(response.additional_work_orders.length > 0){
            //     response.additional_work_orders.forEach(supplies => {
            //         newSupplies.push({
            //             id_additional: supplies.id_additional,
            //             // code: supplies.code,
            //             // name:  supplies.name,
            //             // price: supplies.price
            //         })
            //     })
            // }

            if (response) {
                listDiscount.push({
                    total_price: response.total_price,
                    credit_max_amount: response.credit_max_amount,
                    max_discount: response.max_discount,
                });
                setDiscount([...listDiscount])
            }


            if (response.listDoctors.length > 0) {
                response.listDoctors.forEach(_doctor => {

                    listIdDoctors.push({
                        label: _doctor.name_doctor,
                        value: _doctor.id_doctor,
                        id_doctor: _doctor.id_doctor,
                        id_work_order_doctor: _doctor.id_work_order_doctor,
                    });

                    listInformationDoctors.push({
                        id_doctor: _doctor.id_doctor,
                        id_specialty: _doctor.id_specialty,
                        name: _doctor.name_doctor,
                        name_specialty: _doctor.specialty,
                        professional_license: _doctor.professional_license,
                        phone: _doctor.phone,
                        address: _doctor.address,
                        email: _doctor.email,
                        isCommercialPartner: _doctor.isCommercialPartner,
                        id_work_order_doctor: _doctor.id_work_order_doctor,

                    });

                    listSearchDoctor.push({
                        value: _doctor.id_doctor,
                        name: _doctor.name_doctor
                    })
                });
                setInformationDoctor([...listInformationDoctors]);
                setDoctList([...listIdDoctors]);
                ////console.log('LISTA DE DOCTORES: ', listIdDoctors)
            }

            if (response.listdevolution.length > 0) {
                response.listdevolution.forEach(_devolution => {
                    let _dev = format_trunc_number(((_devolution.amount - _devolution.discount_amount) + _devolution.iva), 2);

                    totalDevolutions += Math.round(_dev);
                });
            }

            if (response.listPayMethods.length > 0) {
                listPayments = response.listPayMethods.map(_payment => {
                    if (response.id_company !== null) {
                        let price = _payment.amount === "" ? 0.0 : (parseFloat(_payment.amount) < 0 ? 0 : parseFloat(_payment.amount));

                        totalPayment += !isNaN(price) ? parseFloat(price.toFixed(2)) : 0;
                    }
                    else if (_payment.name !== "CREDITO") {
                        let price = _payment.amount === "" ? 0.0 : (parseFloat(_payment.amount) < 0 ? 0 : parseFloat(_payment.amount));

                        totalPayment += !isNaN(price) ? parseFloat(price.toFixed(2)) : 0;
                    }

                    _payment.is_edited = false;
                    _payment.deleted = false;

                    return _payment;
                });
            }

            response.listTest.forEach(_test => {

                //#region SPECIMENS
                let array_specimens = [];

                if (_test.id_profile !== null) {
                    _test.listSpecimen.forEach(specimen => {
                        if (!array_specimens.find(x => x.id_specimen === specimen.id_specimen)) {

                            let valorCheck = true;
                            let valorCheckForwarded = false;

                            listTest.forEach(item => {
                                if (item.id_specimen === parseInt(specimen.id_specimen)) {
                                    valorCheck = item.check_specimen;
                                    valorCheckForwarded = false;
                                }
                            });

                            array_specimens.push({
                                id_specimen: specimen.id_specimen,
                                specimen_name: specimen.specimen_name,
                                check_specimen: valorCheck,
                                check_forwarded: valorCheckForwarded
                            });
                        }
                    });
                }
                //#endregion

                //#region DISCOUNTS
                let id_special_discount = null;
                let id_common_discount = null;

                let id_agreement = null;
                let id_agreement_test_range = null;
                let contract_number = null;

                let is_auto_redeem = false;

                let percentage_discount = 0;

                if (_test.validateTestSpecialDiscount !== null) {
                    id_special_discount = _test.validateTestSpecialDiscount.id_special_discount;
                    percentage_discount = _test.validateTestSpecialDiscount.percentage;

                    is_auto_redeem = true;
                }
                else if (_test.validateCommonDiscountDetail !== null) {
                    let _copyListCommonDiscountsE = arrayCommonDiscounts;

                    id_common_discount = _test.validateCommonDiscountDetail.id_common_discount;
                    is_auto_redeem = _test.validateCommonDiscountDetail.auto_redeem;
                    percentage_discount = _test.validateCommonDiscountDetail.percentage_discount;

                    if (_copyListCommonDiscountsE.find(x => x.id_common_discount === id_common_discount) === undefined) {
                        _copyListCommonDiscountsE.push({
                            id_common_discount: id_common_discount,
                            is_available_discount: true,
                            is_enable: true
                        });
                    }

                    setArrayCommonDiscounts(_copyListCommonDiscountsE);
                }
                else if (_test.validateAgreementDiscount !== null) {
                    let listAgreementDiscounts = arrayAgreementDiscounts;

                    id_agreement = _test.validateAgreementDiscount.id_agreement;
                    id_agreement_test_range = _test.validateAgreementDiscount.id_agreement_test_range;
                    percentage_discount = _test.validateAgreementDiscount.percentage;
                    contract_number = _test.validateAgreementDiscount.contract_number;
                    is_auto_redeem = _test.validateAgreementDiscount.auto_reedem;

                    if (listAgreementDiscounts.find(x => x.id_agreement === id_agreement)) {
                        listAgreementDiscounts.push({
                            id_agreement,
                            id_agreement_test_range,
                            contract_number,
                            is_available_discount: true,
                            is_enable: true
                        });
                    }

                    setArrayAgreementDiscounts(listAgreementDiscounts);
                }

                //#endregion

                let objectTest = {
                    exist: _test.exist,
                    id_work_order_exam: _test.id_work_order_exam,
                    id_work_order_profile: _test.id_work_order_profile,
                    id_exam: _test.id_exam,
                    id_profile: _test.id_profile,
                    name: _test.name,
                    abbreviation: _test.abbreviation,
                    code: _test.code,
                    id_rate: _test.id_rate,
                    id_price: _test.id_exam !== null ? _test.id_price : _test.id_profile_price,
                    name_rate: "",
                    price: _test.price,

                    discount: percentage_discount,
                    id_common_discount: id_common_discount,
                    id_special_discount: id_special_discount,
                    id_agreement: id_agreement,
                    id_agreement_test_range: id_agreement_test_range,
                    contract_number: contract_number,


                    is_urgent: _test.is_urgent,
                    is_curve: _test.is_curve,

                    config_iva: _test.config_iva,
                    value_iva: _test.value_iva,
                    is_quotation: false,

                    urgent_price: _test.price_urgent,
                    auto_redeem: is_auto_redeem,
                    is_campaign: false,
                    name_campaign: false,

                    is_canceled: _test.is_canceled,

                    is_referenced: _test.is_referenced,
                    id_external_laboratory: _test.id_external_laboratory,
                    name_external_laboratory: _test.name_external_laboratory,
                    listExternalLaborator7ies: _test.listExternalLaboratories,
                    is_edited_test: false
                }

                //#region OBJECT PROFILE
                if (_test.id_profile !== null) {

                    let _copyCurves = [];

                    if (_test.is_curve) {
                        _copyCurves = _test.profileCurves.map(_curve => {
                            _curve.exist = false;

                            return _curve;
                        });

                        objectTest.price = _test.workOrderCurves.price;
                    }

                    objectTest.specimen_name = "";
                    objectTest.id_specimen = null;
                    objectTest.array_specimens = array_specimens;
                    objectTest.listExams = _test.listExams;
                    objectTest.workOrderCurves = _test.workOrderCurves;
                    objectTest.curves = _copyCurves;
                }
                //#endregion

                //#region OBJECT INVOICE
                if (_test.id_exam !== null) {

                    objectTest.specimen_name = _test.specimenExam.specimen_name;
                    objectTest.id_specimen = _test.specimenExam.id_specimen;
                    objectTest.check_specimen = _test.specimenExam.check_specimen;
                    objectTest.check_forwarded = _test.specimenExam.check_forwarded;
                }
                //#endregion

                listTest.push(objectTest);
            });

            setCheckTypeClient(response.id_income_type);

            setIsFullyPaid(response.paid);

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                id_work_order: response.id_work_order,
                id_branch: response.id_branch,
                id_patient: response.id_patient,
                id_company: response.id_company === null ? 0 : {
                    label: response.name_company,
                    value: response.id_company
                },
                id_printer_point: {
                    "value": 1,
                    "label": "Recepción"
                },
                id_doctor: listIdDoctors,
                nim: response.nim,
                observations: response.observations === null ? "" : response.observations,
                observations_sample: response.observations_sample,
                observations_general: response.observations_general,
                checkPrint: response.print_results,
                checkPartial: response.results_partial,
                checkEmail: response.send_email,
                checkWhats: response.send_whatsapp,
                checkDoctor: response.send_doctor,
                checkInvoice: false,
                listTest: listTest,
                listPayMethods: listPayments,
                listDoctors: listIdDoctors,
                listSupplies: newSupplies,
                percentageDiscount: percentageDiscount,
                total_price: total_price,
                credit_max_amount: credit_max_amount,
                max_discount: max_discount,
                id_work_order_type_service: response.id_work_order_type_service === null ? 0 : {
                    label: response.name_service,
                    value: response.id_work_order_type_service
                },
                id_pet: response.id_pet === null ? 0 : response.id_pet,
                //response.id_work_order_type_service.value
                //listTypeServices: listTypeServices
            });

            setInformationPatient({
                age: response.age,
                birthday: response.birthday,
                is_complete: response.is_complete,
                phone: response.phone,
                gender: response.gender,
                email: response.email,
                curp: response.curp,
                membership: (response.membership === null || response.membership === "null") ? "" : response.membership,
                name: response.name_patient,
                paternal_surname: response.paternal_surname,
                maternal_surname: response.maternal_surname,
                id_state: { value: response.id_state, label: response.name_state },
                id_municipality: { value: response.id_municipality, label: response.name_municipality },
                cardnumber: null,
                id_social_gender: 0,
                setFileINE: "",
                img64FileI: false,
                cp: response.cp,
                address: response.address,
                colony: response.colony,
                expedient_number: response.expedient_number,
                // id_blood_type: response.id_blood_type,
                // name_blood_type: response.name_blood_type
            });


            let totalMoneyEntered = Math.round(totalPayment);
            setMoneyEntered(totalMoneyEntered);
            setTotalDevolutions(totalDevolutions)

            setViewWorkOrder(true);
            setLoadingEditWorkOrder(false);

            setIsPayForCompany(response.is_pay_for_company);
            setRequiredInvoiceCompany(response.required_invoice);
        }
        else {
            setLoadingEditWorkOrder(false);
        }
    }
    //#endregion

    const format_trunc_number = (_quantity, _decimals) => {
        let _format_number = 0;
        const _base = 10;

        let _number_decimals = Math.pow(_base, _decimals);

        _format_number = Math.round(_quantity * _number_decimals) / _number_decimals;

        return _format_number;
    }

    const [showDiscountWarning, setShowDiscountWarning] = useState(false);
    const [showDiscountWarningCredit, setShowDiscountWarningCredit] = useState(false);
    const [showDiscountWarningPay, setShowDiscountWarningPay] = useState(false)

    useMemo(() => {
        if (validPorcentejeSpecial === "https://labcen.helen-sw.com") {
            let percentage_urgency = parseInt(percentage_urgent_work_order);
            let _take_number_decimals = 2;
            let total = 0.0;
            let subtotal = 0.0;
            let descuento = 0.0;
            let total_iva = 0.0;
            let total_cancellations = 0.0;

            if (formWorkOrder.listTest.length > 0) {
                formWorkOrder.listTest.forEach(data => {

                    let price = data.price;
                    let discount = 0.0;
                    let urgency = 0.0;
                    let taxIva = 0.0;

                    switch (data.config_iva) {
                        //restar el iva
                        case true:
                            let _valueIva = 1 + (data.value_iva / 100);

                            price = (price / _valueIva);
                            price = format_trunc_number(price, _take_number_decimals);

                            if (data.is_urgent) {
                                urgency = (price * percentage_urgency) / 100;
                                urgency = format_trunc_number(urgency, _take_number_decimals);

                                data.urgent_price = !isNaN(urgency) ? urgency : 0;

                                price = (price + urgency);
                                price = format_trunc_number(price, _take_number_decimals);
                            }
                            else {
                                data.urgent_price = 0;
                            }

                            if (data.auto_redeem) {
                                discount = (price * data.discount) / 100;
                                discount = format_trunc_number(discount, _take_number_decimals);
                                data.amountDiscount = discount;
                            }
                            else {
                                data.amountDiscount = 0;
                            }

                            //RECALCULATE IVA

                            taxIva = ((price - discount) * data.value_iva) / 100;
                            taxIva = format_trunc_number(taxIva, _take_number_decimals);
                            data.tax = taxIva;

                            break;

                        default:
                            if (data.is_urgent) {
                                urgency = (price * percentage_urgency) / 100;
                                urgency = format_trunc_number(urgency, _take_number_decimals);

                                data.urgent_price = !isNaN(urgency) ? urgency : 0;

                                price = (price + urgency);
                                price = format_trunc_number(price, _take_number_decimals);
                            }
                            else {
                                data.urgent_price = 0;
                            }

                            if (data.auto_redeem) {
                                discount = (price * data.discount) / 100;
                                discount = format_trunc_number(discount, _take_number_decimals);
                                data.amountDiscount = discount;
                            }
                            else {
                                data.amountDiscount = 0;
                            }

                            //RECALCULATE IVA
                            taxIva = ((price - discount) * data.value_iva) / 100;

                            //taxIva = format_trunc_number(taxIva, _take_number_decimals);
                            data.tax = taxIva;

                            break;
                    }

                    if (data.is_canceled) {
                        total_cancellations += ((price - discount) + taxIva);
                    }
                    else {
                        subtotal += price;
                        descuento += discount;
                        total_iva += taxIva;


                    }
                    if (percentageDiscount > 0) {

                        const additionalDiscount = (subtotal * percentageDiscount) / 100;
                        descuento = format_trunc_number(additionalDiscount, _take_number_decimals);

                        const newSubtotal = format_trunc_number(subtotal - descuento, _take_number_decimals);
                        const additionalTaxIva = format_trunc_number(newSubtotal * 0.16, _take_number_decimals);
                        data.tax = additionalTaxIva;
                        total_iva = additionalTaxIva;
                    }
                });
            }

            total = Math.round((subtotal - descuento) + total_iva);
            total_cancellations = Math.round(total_cancellations);

            listPayMethods.map(_payment => {
                if (validPorcentejeSpecial === 'https://labcen.helen-sw.com' && _payment.name !== 'CREDITO') {
                    let percentageDiscount = formWorkOrder.percentageDiscount;
                    let amount = _payment.amount;

                    if (percentageDiscount > -1 && percentageDiscount < 101) {
                        setShowDiscountWarning(false);

                        if (percentageDiscount.length > 0 && _payment.amount > total) {
                            // console.log('_payment.name ', _payment.name)
                            // console.log('_payment.amount ', _payment.amount)
                            // console.log('percentageDiscount ', percentageDiscount)
                            // console.log('total ', total)
                            // console.log('totalAux ', totalAux)
                            // console.log('amount ', amount)
                            setShowDiscountWarningPay(true);
                        } else {
                            setShowDiscountWarningPay(false)
                        }
                        _payment.amount = amount;

                    } else {
                        setShowDiscountWarning(true);
                    }
                }
            });


            // listPayMethods.map(_payment => {
            //     if (validPorcentejeSpecial === 'https://labcen.helen-sw.com') {
            //         if (_payment.name === "CREDITO") {
            //             let percentageDiscount = formWorkOrder.percentageDiscount;
            //             let amount = _payment.amount;


            //             listPayMethods.map(_payment => {
            //                 if (validPorcentejeSpecial === 'https://labcen.helen-sw.com' && _payment.name === 'CREDITO') {
            //                     let percentageDiscount = formWorkOrder.percentageDiscount;
            //                     //let total1 = total
            //                     let amount = _payment.amount;

            //                     if (percentageDiscount > -1 && percentageDiscount < 101) {
            //                         //const additionalDiscount = (total * percentageDiscount) / 100;

            //                         setShowDiscountWarning(false);
            //                         // Verificar si el descuento total excede el límite
            //                         // if ( additionalDiscount > _payment.max_discount) {
            //                         //     setShowDiscountWarningCredit(true);
            //                         // }else{
            //                         //     setShowDiscountWarningCredit(false);
            //                         // }

            //                         // Verificar si el monto total a pagar excede el límite
            //                         // if (total > _payment.credit_max_amount) {
            //                         //     setShowDiscountWarningCredit(true);
            //                         // }else{
            //                         //     setShowDiscountWarningCredit(false);
            //                         // }


            //                         if (percentageDiscount.length > 0 && totalAux > _payment.amount) {
            //                             console.log('_payment.name ', _payment.name)
            //                             console.log('_payment.amount ', _payment.amount)
            //                             console.log('percentageDiscount ', percentageDiscount)
            //                             console.log('total ', total)
            //                             console.log('totalAux ', totalAux)
            //                             console.log('amount ', amount)
            //                             setShowDiscountWarningPay(true);
            //                         } else {
            //                             setShowDiscountWarningPay(false)
            //                         }

            //                         _payment.amount = amount;

            //                     } else {
            //                         setShowDiscountWarning(true);
            //                     }
            //                 }
            //             });
            //         }
            //     }
            // });

            descuento = !isNaN(descuento) ? parseFloat(descuento.toFixed(2)) : 0;
            subtotal = !isNaN(subtotal) ? parseFloat(subtotal.toFixed(2)) : 0;
            total_iva = !isNaN(total_iva) ? parseFloat(total_iva.toFixed(2)) : 0;
            total = !isNaN(total) ? parseFloat(total.toFixed(2)) : 0;
            total_cancellations = !isNaN(total_cancellations) ? parseFloat(total_cancellations.toFixed(2)) : 0;

            setSubTotalW(subtotal);
            setTotalDiscountW(descuento);
            setTotalTaxesW(total_iva);
            setTotalW(total);
            setTotalDevolutions(total_cancellations);
            setArrayCommonDiscounts(arrayCommonDiscounts);

        } else {
            ////console.log('listSupplies: ',listSupplies)
            // let suppliesPrice = 0;
            ////console.log('suppliesPrice: ',suppliesPrice)
            let percentage_urgency = parseInt(percentage_urgent_work_order);
            let _take_number_decimals = 2;
            let total = 0.0;
            let subtotal = 0.0;
            let descuento = 0.0;
            let total_iva = 0.0;
            let total_cancellations = 0.0;

            // if(listSupplies !== null){
            //     listSupplies.forEach(supply => {
            //         suppliesPrice += supply.price;
            //     })
            // }
            ////console.log('suppliesPrice: ',suppliesPrice)

            // subtotal = suppliesPrice;

            if (formWorkOrder.listTest.length > 0) {
                formWorkOrder.listTest.forEach(data => {

                    let price = data.price;
                    let discount = 0.0;
                    let urgency = 0.0;
                    let taxIva = 0.0;

                    switch (data.config_iva) {
                        //restar el iva
                        case true:
                            let _valueIva = 1 + (data.value_iva / 100);

                            price = (price / _valueIva);
                            price = format_trunc_number(price, _take_number_decimals);

                            if (data.is_urgent) {
                                urgency = (price * percentage_urgency) / 100;
                                urgency = format_trunc_number(urgency, _take_number_decimals);

                                data.urgent_price = !isNaN(urgency) ? urgency : 0;

                                price = (price + urgency);
                                price = format_trunc_number(price, _take_number_decimals);
                            }
                            else {
                                data.urgent_price = 0;
                            }

                            if (data.auto_redeem) {
                                discount = (price * data.discount) / 100;
                                discount = format_trunc_number(discount, _take_number_decimals);
                                data.amountDiscount = discount;
                            }
                            else {
                                data.amountDiscount = 0;
                            }

                            //RECALCULATE IVA
                            taxIva = ((price - discount) * data.value_iva) / 100;
                            taxIva = format_trunc_number(taxIva, _take_number_decimals);
                            data.tax = taxIva;

                            break;

                        default:

                            if (data.is_urgent) {
                                urgency = (price * percentage_urgency) / 100;
                                urgency = format_trunc_number(urgency, _take_number_decimals);

                                data.urgent_price = !isNaN(urgency) ? urgency : 0;

                                price = (price + urgency);
                                price = format_trunc_number(price, _take_number_decimals);
                            }
                            else {
                                data.urgent_price = 0;
                            }

                            if (data.auto_redeem) {
                                discount = (price * data.discount) / 100;
                                discount = Math.floor(discount * 100) / 100;
                                //discount = format_trunc_number(discount, _take_number_decimals);
                                data.amountDiscount = discount;
                            }
                            else {
                                data.amountDiscount = 0;
                            }

                            //RECALCULATE IVA
                            taxIva = ((price - discount) * data.value_iva) / 100;
                            taxIva = format_trunc_number(taxIva, _take_number_decimals);
                            data.tax = taxIva;
                            break;
                    }

                    if (data.is_canceled) {
                        total_cancellations += ((price - discount) + taxIva);
                    }
                    else {

                        subtotal += price;
                        descuento += discount;
                        total_iva += taxIva;
                    }
                });
            }

            total = Math.round((subtotal - descuento) + total_iva);
            total_cancellations = Math.round(total_cancellations);

            descuento = !isNaN(descuento) ? parseFloat(descuento.toFixed(2)) : 0;
            subtotal = !isNaN(subtotal) ? parseFloat(subtotal.toFixed(2)) : 0;
            total_iva = !isNaN(total_iva) ? parseFloat(total_iva.toFixed(2)) : 0;
            total = !isNaN(total) ? parseFloat(total.toFixed(2)) : 0;
            total_cancellations = !isNaN(total_cancellations) ? parseFloat(total_cancellations.toFixed(2)) : 0;

            setSubTotalW(subtotal);
            setTotalDiscountW(descuento);
            setTotalTaxesW(total_iva);
            setTotalW(total);
            setTotalDevolutions(total_cancellations);
            setArrayCommonDiscounts(arrayCommonDiscounts);
        }
    }, [formWorkOrder]);

    useMemo(() => {
        console.log(formWorkOrder)
        if (totalW === 0 && formWorkOrder.listTest.length == 0) {
            setMoneyEntered(0);
            setShowMessageW({
                labelMoney: "",
                amount: 0
            });
            setEnableBtnSaveWorkOrder(true);
        }
        else {
            if (moneyEntered >= totalW) {

                let change = (parseFloat(moneyEntered) - totalW);

                setShowMessageW({
                    labelMoney: "Cambio",
                    amount: !isNaN(change) ? change.toFixed(2) : 0
                });
                setEnableBtnSaveWorkOrder(false);
            }
            else {

                let change = (totalW - parseFloat(moneyEntered));

                setShowMessageW({
                    labelMoney: "Faltan",
                    amount: !isNaN(change) ? change.toFixed(2) : 0
                });

                setEnableBtnSaveWorkOrder(true);
            }
        }
    }, [totalW, moneyEntered]);

    // //CALCULATE AMOUNT OF WORK ORDER
    // useMemo(() => {
    //     ////console.log('listSupplies: ',listSupplies)
    //     // let suppliesPrice = 0;
    //     ////console.log('suppliesPrice: ',suppliesPrice)
    //     let percentage_urgency = parseInt(percentage_urgent_work_order);
    //     let _take_number_decimals = 2;
    //     let total = 0.0;
    //     let subtotal = 0.0;
    //     let descuento = 0.0;
    //     let total_iva = 0.0;
    //     let total_cancellations = 0.0;

    //     // if(listSupplies !== null){
    //     //     listSupplies.forEach(supply => {
    //     //         suppliesPrice += supply.price;
    //     //     })
    //     // }
    //     ////console.log('suppliesPrice: ',suppliesPrice)

    //     // subtotal = suppliesPrice;

    //     if () {

    //     } else {

    //     }
    //     if (formWorkOrder.listTest.length > 0) {
    //         formWorkOrder.listTest.forEach(data => {

    //             let price = data.price;
    //             let discount = 0.0;
    //             let urgency = 0.0;
    //             let taxIva = 0.0;
    //             switch (data.config_iva) {
    //                 //restar el iva
    //                 case true:
    //                     let _valueIva = 1 + (data.value_iva / 100);

    //                     price = (price / _valueIva);
    //                     price = format_trunc_number(price, _take_number_decimals);

    //                     if (data.is_urgent) {
    //                         urgency = (price * percentage_urgency) / 100;
    //                         urgency = format_trunc_number(urgency, _take_number_decimals);
    //                         data.urgent_price = !isNaN(urgency) ? urgency : 0;
    //                         price = (price + urgency);
    //                         price = format_trunc_number(price, _take_number_decimals);
    //                     }
    //                     else {
    //                         data.urgent_price = 0;
    //                     }
    //                     if (data.auto_redeem) {
    //                         discount = (price * data.discount) / 100;
    //                         discount = format_trunc_number(discount, _take_number_decimals);
    //                         data.amountDiscount = discount;
    //                     }
    //                     else {
    //                         data.amountDiscount = 0;
    //                     }

    //                     //RECALCULATE IVA
    //                     taxIva = ((price - discount) * data.value_iva) / 100;
    //                     taxIva = format_trunc_number(taxIva, _take_number_decimals);
    //                     data.tax = taxIva;
    //                     break;

    //                 default:
    //                     if (data.is_urgent) {
    //                         urgency = (price * percentage_urgency) / 100;
    //                         urgency = format_trunc_number(urgency, _take_number_decimals);
    //                         data.urgent_price = !isNaN(urgency) ? urgency : 0;
    //                         price = (price + urgency);
    //                         price = format_trunc_number(price, _take_number_decimals);
    //                     }
    //                     else {
    //                         data.urgent_price = 0;
    //                     }

    //                     if (data.auto_redeem) {
    //                         if (validPorcentejeSpecial === 'https://labcen.helen-sw.com') {
    //                             if (percentageDiscount > 0) {
    //                                 discount = 0.0;
    //                                 data.amountDiscount = discount;
    //                             } else {
    //                                 discount = (price * data.discount) / 100;
    //                                 discount = format_trunc_number(discount, _take_number_decimals);
    //                                 data.amountDiscount = discount;
    //                             }
    //                         } else {
    //                             discount = (price * data.discount) / 100;
    //                             discount = format_trunc_number(discount, _take_number_decimals);
    //                             data.amountDiscount = discount;
    //                         }
    //                     }
    //                     else {
    //                         data.amountDiscount = 0;
    //                     }

    //                     //RECALCULATE IVA
    //                     taxIva = ((price - discount) * data.value_iva) / 100;
    //                     taxIva = format_trunc_number(taxIva, _take_number_decimals);
    //                     data.tax = taxIva;
    //                     break;
    //             }

    //             if (data.is_canceled) {
    //                 total_cancellations += ((price - discount) + taxIva);
    //             } else {
    //                 if (validPorcentejeSpecial === 'https://labcen.helen-sw.com') {
    //                     if (percentageDiscount > 0) {
    //                         descuento = 0.0;
    //                     } else if (data.id_common_discount > 0) {
    //                         descuento = discount;
    //                     }
    //                 } else {
    //                     subtotal += price;
    //                     descuento += discount;
    //                     total_iva += taxIva;
    //                 }
    //             }
    //         });
    //     }

    //     total = Math.round((subtotal - descuento) + total_iva);
    //     total_cancellations = Math.round(total_cancellations);

    //     listPayMethods.map(_payment => {
    //         if (validPorcentejeSpecial === 'https://labcen.helen-sw.com') {
    //             if (_payment.name === "CREDITO") {
    //                 let percentageDiscount = formWorkOrder.percentageDiscount;
    //                 let amount = _payment.amount;

    //                 if (percentageDiscount > -1 && percentageDiscount < 101) {
    //                     let additionalDiscount = (total * percentageDiscount) / 100;

    //                     additionalDiscount = format_trunc_number(additionalDiscount, _take_number_decimals);
    //                     total = total - additionalDiscount;

    //                     //discount.forEach(discounts => {
    //                     //console.log('discounts.credit_max_amount: ', discounts.credit_max_amount)
    //                     // if (Number(percentageDiscount) !== 0 && (discounts.credit_max_amount > total || total > discounts.max_discount)) {
    //                     //     setShowDiscountWarningCredit(true);
    //                     // } else {
    //                     //     setShowDiscountWarningCredit(false);
    //                     // }
    //                     //});


    //                     _payment.amount = amount;
    //                     descuento += additionalDiscount;
    //                     setShowDiscountWarning(false);
    //                 } else {
    //                     setShowDiscountWarning(true);
    //                 }
    //             }
    //         }
    //     });


    //     descuento = !isNaN(descuento) ? parseFloat(descuento.toFixed(2)) : 0;
    //     subtotal = !isNaN(subtotal) ? parseFloat(subtotal.toFixed(2)) : 0;
    //     total_iva = !isNaN(total_iva) ? parseFloat(total_iva.toFixed(2)) : 0;
    //     total = !isNaN(total) ? parseFloat(total.toFixed(2)) : 0;
    //     total_cancellations = !isNaN(total_cancellations) ? parseFloat(total_cancellations.toFixed(2)) : 0;

    //     setSubTotalW(subtotal);
    //     setTotalDiscountW(descuento);
    //     setTotalTaxesW(total_iva);
    //     setTotalW(total);
    //     setTotalDevolutions(total_cancellations);
    //     setArrayCommonDiscounts(arrayCommonDiscounts);

    // }, [formWorkOrder]);



    //#endregion WORK ORDER

    useEffect(() => {
        getPetType();
        getPetSpecie();
        getPetBreed();
    }, []);


    //#region VETERINARY
    const [listPetType, setListPetType] = useState([]);
    const [type, setType] = useState([]);
    const [listPetSpecie, setListPetSpecie] = useState([]);
    const [specie, setSpecie] = useState([]);
    const [listPetBreed, setListPetBreed] = useState([]);
    const [breed, setBreed] = useState([]);

    const [informationPet, setInformationPet] = useState({
        id_pet: 0,
        id_pet_type: 0,
        id_pet_specie: 0,
        id_pet_breed: 0,
        pet: null,
        birthday: null,
        sex: false,
        agePet: "",
    })

    //FORM VALUES PET
    const [formValuesPet, handleInputChangePet, resetPet, handleUpdateValuesPet, handleSelectValuesPet, handlePickerValuesPet, handleDinamicInputPet, handleDatePet] = useForm({
        id_pet_type: 0,
        id_pet_specie: 0,
        id_pet_breed: 0,
        pet: "",
        birthday: "",
        sex: "",
        agePet: "",
    });

    //VALIDATION PET
    const [validationsPet, setValidationsPet] = useState({
        id_pet_type_valid: false,
        id_pet_specie_valid: false,
        id_pet_breed_valid: false,
        pet_valid: false,
        birthday_valid: false,
        sex_valid: false,
    });

    const getPetType = async () => {
        const _list = await handleRequest("GET", "PetType/ListTypes");
        if (_list !== null && _list.length > 0) {
            let _list_type = [];
            let _list_pet_type = [];

            _list.forEach(_type => {
                _list_type.push({
                    id_pet_type: _type.id_pet_type,
                    type: _type.type,
                });
            });
            setType(_list_type);

            _list.forEach(_type => {
                _list_pet_type.push({
                    value: _type.id_pet_type,
                    label: _type.type,
                });
            });
            setListPetType(_list_pet_type);
        }
    }

    const getPetSpecie = async () => {
        const _list = await handleRequest("GET", "PetSpecie/ListSpecies");
        if (_list !== null && _list.length > 0) {
            let _list_specie = [];
            let _list_pet_specie = [];
            _list.forEach(_specie => {
                _list_specie.push({
                    id_pet_specie: _specie.id_pet_specie,
                    //specie: _specie.specie
                    specie: _specie.specie.toUpperCase()
                })
            })
            setSpecie(_list_specie);

            _list.forEach(_specie => {
                _list_pet_specie.push({
                    value: _specie.id_pet_specie,
                    //label: _specie.specie,
                    label: _specie.specie.toUpperCase(),
                })
            })
            setListPetSpecie(_list_pet_specie);
        }
    }

    const getPetBreed = async () => {
        const _list = await handleRequest("GET", "PetBreed/ListBreed");
        if (_list !== null && _list.length > 0) {
            let _list_breed = [];
            let _list_pet_breed = [];

            _list.forEach(_breed => {
                _list_breed.push({
                    id_pet_breed: _breed.id_pet_breed,
                    breed: _breed.breed.toUpperCase(),
                })
            })
            setBreed(_list_breed)

            _list.forEach(_breed => {
                _list_pet_breed.push({
                    value: _breed.id_pet_breed,
                    label: _breed.breed.toUpperCase()
                })
            })
            setListPetBreed(_list_pet_breed);
        }
    }
    //CALCULATION OF BIRTHDAY
    const [agePet, setAgePet] = useState(0)

    const handleWheelPet = (e) => {
        e.preventDetault();
        const direction = e.deltaY > 0 ? -1 : 1;
        const newValue = parseInt(agePet) + direction;

        agePet(formValuesPet.agePet);
        if (!isNaN(newValue) && newValue >= 0) {
            setAgePet(newValue)
        }
    }

    let isCalculatingPet = false;

    async function getAgePet(dateString) {
        if (isCalculatingPet) return;
        isCalculatingPet = true;
        try {
            let today = new Date();
            let parts = dateString.split("/");
            let birthDate = new Date(parts[2], parts[1] - 1, parts[0]);
            let years = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                years--;
            }
            //setAgePet(years)
            formValuesPet.agePet = years;
        } catch (error) {
            console.error("Eroor al calcular la edad del paciente: " + error);
        } finally {
            isCalculatingPet = false;
        }
    }
    //setAgePet(formValuesPet.agePet)

    async function getBirthdayPet(years) {
        if (isCalculatingPet) return;
        isCalculatingPet = true;
        try {
            let now = new Date().getFullYear();
            let calculateYear = now - years;
            let date = `01/01/${calculateYear}`;
            formValuesPet.birthday = date;
        } catch (error) {
            console.error("Error al calcular la fecha de nacimiento del paciente: " + error);
        } finally {
            isCalculatingPet = false;
        }
    }

    async function processPetData() {
        if ((formValuesPet.agePet.length >= 1) && (formValuesPet.agePet.length < 4)) {
            getBirthdayPet(formValuesPet.agePet)
        }
    }

    processPetData();

    if (formValuesPet.birthday !== '') {
        getAgePet(formValuesPet.birthday);
    }

    const handleChangePetType = (e) => {
        let a = e.value
        console.log(a);
        handleSelectValuesPet(e, "id_pet_type");
        if (e.value !== undefined) {
            getPetType(e.value);
        }
    };

    const handleChangePetSpecie = (e) => {
        let a = e.value;
        handleSelectValuesPet(e, "id_pet_specie");
        if (e.value !== undefined) {
            getPetSpecie(e.value);
        }
    }

    const handleChangePetBreed = (e) => {
        let a = e.value;
        handleSelectValuesPet(e, "id_pet_breed");
        if (e.value !== undefined) {
            getPetBreed(e.value);
        }
    }

    const handleCreatePet = (e) => {
        handleInputChangePet(e);
        let pet = e.target.value;
        if (pet !== 0 && pet !== null && pet !== '') {
            //handleGetInformationPet(pet);
        } else {
            setInformationPet({
                birthday: null,
                sex: null,
                pet: null,
                id_pet_type: 0,
                id_pet_specie: 0,
                id_pet_breed: 0,
            })
            handleUpdateValuesPatient({
                ...formValuesPatient,
                id_pet: 0,
            })
        }
    }

    const handleCreatePets = () => {
        handleUpdateValuesPet({
            pet: "",
            id_pet_type: 0,
            id_pet_specie: 0,
            id_pet_breed: 0,
            birthday: "",
            sex: ""
        })
        setValidationsPet({
            id_pet_breed_valid: false,
            id_pet_specie_valid: false,
            id_pet_type_valid: false,
            pet_valid: false,
            birthday_valid: false,
            sex: false,
        })
    }

    const saveCreatePet = async (e) => {

        e.preventDefault();
        if (validationsPets()) {
            return;
        }

        let _arrayBirthday = formValuesPet.birthday.split('/')
        let _stringBirthday = `${_arrayBirthday[2]}-${_arrayBirthday[1]}-${_arrayBirthday[0]}`
        let sPet = {
            id_pet_breed: formValuesPet.id_pet_breed.value,
            id_pet_specie: formValuesPet.id_pet_specie.value,
            id_pet_type: formValuesPet.id_pet_type.value,
            pet: formValuesPet.pet.toUpperCase(),
            sex: formValuesPet.sex === "2" ? false : true,
            birthday: _stringBirthday
        }

        let _listTmp = await handleRequest("POST", "Pet", 'Mascota', sPet)


        if (_listTmp !== null) {
            setSearchPetState({
                value: _listTmp.id_pet,
                label: _listTmp.pet,
            })
            
            handleUpdateValuesPet({
                ...formValuesPatient,
                id_pet: _listTmp.id_pet
            })

            await handleGetInformationPet(_listTmp.id_pet);
            createSweet("create", "success", "Mascota Registrada")
            setIsPetsTabLocked(true);
            setActiveTab('1');
        } else {
            setLoading(false);
        }
        setLoading(false);
    }

    const handleGetInformationPet= async (id_pet) =>{

        let _list = await handleRequest("GET", `Pet/FindPetId/${id_pet}`, 'Mascota')

        if(_list != null){
            let tmpPet = {
                birthday: _list.birthday,
                sex: _list.sex,
                pet: _list.pet,
                id_pet_type: _list.id_pet_type,
                id_pet_breed: _list.id_pet_breed,
                id_pet_specie: _list.id_pet_specie,
            }

            setInformationPet(tmpPet);

            handleUpdateValuesPatient({
                ...formValuesPatient,
                id_pet: _list.id_pet,
            })
        }else{
            setSearchPetState([]);
            handleUpdateValuesPatient({
                ...formValuesPatient,
                id_pet: 0
            })
            setInformationPet({
                birthday: null,
                sex: null,
                pet: null,
                id_pet_type: 0,
                id_pet_specie: 0,
                id_pet_breed: 0,
            })
        }
    }

    //#endregion


    //#region PATIENT
    //#region PATIENT MODEL
    //USE STATES
    const [listBloodType, setListBloodType] = useState([]);
    const [listStates, setListStates] = useState([]);
    const [listMunicipalities, setListMunicipalities] = useState([]);
    const [listTypeService, setListTypeService] = useState([]);
    const [listTaxRegime, setListTaxRegime] = useState([]);
    const [Listsocialgender, setListsocialgender] = useState([]);
    const [listUseCfdiPatient, setListUseCfdiPatient] = useState([]);

    const [focusQrPatient, setFocusQrPatient] = useState(true);
    const [loadingPatient, setLoadingPatient] = useState(false);
    const [isInvoiceService, setIsInvoiceService] = useState(false);
    const [viewNewSocialGender, setViewNewSocialGender] = useState(false);

    //INFORMATION SELECT PATIENT
    const [searchPatientState, setSearchPatientState] = useState([]);
    const [searchPetState, setSearchPetState] = useState([]);

    const [informationPatient, setInformationPatient] = useState({
        birthday: null,
        is_complete: false,
        phone: null,
        gender: null,
        age: null,
        email: null,
        curp: null,
        membership: null,
        name: null,
        paternal_surname: null,
        maternal_surname: null,
        cardnumber: null,
        id_social_gender: 0,
        setFileINE: "",
        img64FileI: false,
        cp: null,
        id_blood_type: 0,
        id_municipality: 0,
        id_state: 0,
        expedient_number: null,
        id_patient: 0,
        id_pet: 0
    });

    //FORM VALUES PATIENT
    const [formValuesPatient, handleInputChangePatient, resetPatient, handleUpdateValuesPatient, handleSelectValuesPatient, handlePickerValuesPatient, handleDinamicInputPatient, handleDatePatient] = useForm({
        id_gender: 0,
        id_state: 0,
        id_municipality: 0,
        id_blood_type: 0,
        id_social_gender: 0,
        id_tax_regime: 0,
        id_use_cfdi: 0,
        rfc: "",
        type_person: 1,
        code: "",
        paternal_surname: "",
        maternal_surname: "",
        name: "",
        birthday: "",
        age: "",
        curp: "",
        address: "",
        cp: "",
        colony: "",
        phone: "",
        email: "",
        who_collects_exams: "",
        membership: "",
        cardnumber: "",
        new_social_gender: "",
        scan_ine_check: false,
        setFileINE: "",
        img64File: "",
        expedient_number: "",
        id_pet: 0,
    });

    const [formSocialGender, handleInputChangeSocialGender, handleUpdateValuesSocialGender] = useForm({
        name_genderc: "",
        abreviaturac: "",
    });

    const [validationsSG, setvalidationsSG] = useState({
        name_genderc_valid: false,
        abreviaturac_valid: false,
    });

    const [validationsPatient, setValidationsPatient] = useState({
        id_gender_valid: false,
        paternal_surname_valid: false,
        maternal_surname_valid: true,
        name_valid: false,
        birthday_valid: false,
        age_valid: true,
        phone_patient_valid: false,
        email_patient_valid: false,
        curp_valid: false,
        cp_patient_valid: false,
        id_tax_regime_valid: false,
        rfc_valid: false,
        id_use_cfdi_valid: false,
        municipality_Valid: false,
        state_valid: false,
        expedient_number: false
    });

    //#endregion PATIENT MODEL

    //#region PATIENT METHODS
    //#region METHODS GET
    const getBloodType = async () => {
        let listTemp = await handleRequest("GET", "BloodType/List", "Tipo sanguineo");

        if (listTemp !== null && listTemp.length > 0) {
            let _list_blood = [];

            listTemp.forEach(_blood => {
                _list_blood.push({
                    label: _blood.blood_type,
                    value: _blood.id_blood_type
                });
            });

            setListBloodType(_list_blood);
        }
    }

    const getStates = async () => {
        let listTmp = await handleRequest("GET", "State/List", "Estados");
        //console.log('listTmp Estados:', listTmp);
        if (listTmp !== null && listTmp.length > 0) {

            let listTmpStates = [];

            listTmp.forEach(_state => {
                listTmpStates.push({
                    label: _state.name,
                    value: _state.id_state
                });
            });
            setListStates(listTmpStates);
        }
    }

    const getMunicipality = async (id_state) => {
        let listTmp = await handleRequest("GET", `Municipality/List/${id_state}`, 'Municipios');


        if (listTmp !== null && listTmp.length > 0) {
            let listTmpMunicipalities = [];

            listTmp.forEach(_municipality => {
                listTmpMunicipalities.push({
                    label: _municipality.name,
                    value: _municipality.id_municipality
                });
            });

            setListMunicipalities(listTmpMunicipalities);
        }
    }

    const getTypeService = async () => {
        let listTmp = await handleRequest("GET", "WorkOrderServiceType/List", 'Tipo de Servicio');
        if (listTmp !== null && listTmp.length > 0) {
          
            let listTmpTypeService = [];

            listTmp.forEach(_typeService => {
                listTmpTypeService.push({
                    label: _typeService.name,
                    value: _typeService.id_work_order_type_service,
                    priority: _typeService.priority
                });
            });
            setListTypeService(listTmpTypeService)
            //console.log(listTypeService);
        }
    }

    const getSocialGender = async () => {
        let listTmp = await handleRequest("GET", `SocialGender/List`, 'Generos Sociales');

        if (listTmp !== null && listTmp.length > 0) {
            let listTmpSocialGenders = [{
                value: 0,
                label: "OTRO"
            }];

            listTmp.forEach(_gender => {
                listTmpSocialGenders.push({
                    label: _gender.name_social_gender,
                    value: _gender.id_social_gender
                });
            });

            setListsocialgender(listTmpSocialGenders);
        }
    }

    const getTaxRegime = async (_type_person = 1) => {
        const _list = await handleRequest("GET", `CfdiCatalog/ListTaxRegime/4.0?${_type_person === 1 ? 'physical_person=true' : 'moral_person=true'}`, 'Régimen fiscal');

        if (_list !== null && _list.length > 0) {
            let _list_tax_regime = [];

            _list.forEach(_tax => {
                _list_tax_regime.push({
                    value: _tax.id_tax_regime,
                    label: _tax.code + " " + _tax.name
                });
            });

            setListTaxRegime(_list_tax_regime);
        }
        else {
            setListTaxRegime([]);
        }
    }

    const getUseCfdi = async (_id_tax_regime, _type_person = 1) => {
        const _list = await handleRequest("GET", `CfdiCatalog/ListUseCfdi/${_id_tax_regime}/${_type_person === 1 ? true : false}`);

        if (_list !== null && _list.length > 0) {
            let _list_use = [];

            _list.forEach(_use => {
                _list_use.push({
                    label: _use.code + " " + _use.name,
                    value: _use.id_use_cfdi
                });
            });

            setListUseCfdiPatient(_list_use);
        }
        else {
            setListUseCfdiPatient([]);
        }
    }

    //#endregion

    //#region HANDLE METHODS                
    const handleChangePatient = (e) => {
        handleInputChangeWorkOrder(e);
        let patient = e.target.value;

        if (patient !== 0 && patient !== null && patient !== '') {
            handleGetInformationPatient(patient);
            setViewWorkOrder(true);
        }
        else {
            setInformationPatient({
                birthday: null,
                is_complete: false,
                phone: null,
                gender: null,
                age: null,
                email: null,
                email_patient: null,
                curp: null,
                membership: null,
                name: null,
                paternal_surname: null,
                maternal_surname: null,
                cardnumber: null,
                id_social_gender_patient: 0,
                img64FileI: "",
                cp: null,
                id_blood_type: 0,
                colony: "",
                address: "",
                id_municipality: 0,
                id_state: 0,
                expedient_number: "",
                id_pet: 0,
            });

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                id_patient: 0,
                checkEmail: false
            });

            setViewWorkOrder(false);
        }
    }

    const handleGetInformationPatient = async (id_patient, unique_uuid = 0) => {

        let alternative_methods = "";
        if (unique_uuid != 0) {
            alternative_methods = `?unique_uuid=${unique_uuid}`;
        }

        let listTmp = await handleRequest("GET", `Patient/FindPatientId/${id_patient}${alternative_methods}`, 'Paciente');
       
        if (listTmp != null) {
            let tmpPatient = {
                birthday: listTmp.birthday,
                is_complete: listTmp.is_complete,
                phone: listTmp.phone,
                gender: listTmp.gender,
                age: listTmp.age,
                email: listTmp.email,
                email_patient: listTmp.email,
                curp: listTmp.curp,
                membership: listTmp.membership,
                name: listTmp.name,
                paternal_surname: listTmp.paternal_surname,
                maternal_surname: listTmp.maternal_surname,
                cardnumber: listTmp.unique_uuid,
                id_social_gender_patient: listTmp.id_social_gender_patient === null ? 0 : {
                    label: listTmp.name_social_gender,
                    value: listTmp.id_social_gender
                },
                scan_ine_check: listTmp.scan_ine_check,
                img64FileI: listTmp.scan_ine_name,
                cp: listTmp.cp,
                //id_blood_type: listTmp.id_blood_type,
                name_blood_type: listTmp.name_blood_type,
                colony: listTmp.colony,
                address: listTmp.address,
                id_state: { value: listTmp.id_state_patient, label: listTmp.name_state_patient },
                id_municipality: { value: listTmp.id_municipality_patient, label: listTmp.name_municipality_patient },
                name_state: listTmp.name_state_patient,
                name_municipality: listTmp.name_municipality_patient,
                expedient_number: listTmp.expedient_number,
                id_pet: listTmp.id_pet,
            };

          
            setInformationPatient(tmpPatient);
            //console.log('Ya viene todo? ', informationPatient);

            if (listTmp.membership !== "" && listTmp.membership !== null) {
                setIs_membership(true);
            }

            if (unique_uuid !== 0) {
                setSearchPatientState({
                    value: listTmp.id_patient,
                    label: listTmp.name_patient + " - " + listTmp.curp
                });

                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    id_patient: listTmp.id_patient
                });
                setViewWorkOrder(true);
            }
        }
        else {
            setSearchPatientState([]);

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                id_patient: 0
            });

            setInformationPatient({
                birthday: null,
                is_complete: false,
                phone: null,
                gender: null,
                age: null,
                email: null,
                email_patient: null,
                curp: null,
                membership: null,
                cardnumber: null,
                name: null,
                paternal_surname: null,
                maternal_surname: null,
                id_social_gender_patient: 0,
                img64FileI: "",
                cp: null,
                // id_blood_type: 0,
                colony: "",
                address: "",
                id_state_patient: 0,
                id_municipality_patient: 0,
                expedient_number: "",
                id_pet: 0,
            });
        }
    }

    const handleCreatePatient = () => {
        handleUpdateValuesPatient({
            id_gender: 0,
            id_state: 0,
            id_municipality: 0,
            id_blood_type: 0,
            id_social_gender: 0,
            id_tax_regime: 0,
            id_use_cfdi: 0,
            rfc: "",
            type_person: 1,
            code: "",
            paternal_surname: "",
            maternal_surname: "",
            name: "",
            birthday: "",
            age: "",
            curp: "CURPDEFAULT",
            address: "",
            cp: "",
            colony: "",
            phone: "",
            email: "",
            who_collects_exams: "",
            membership: "",
            cardnumber: "",
            new_social_gender: "",
            scan_ine_check: false,
            setFileINE: "",
            img64File: "",
            expedient_number: "",
            id_pet: 0,
        });

        setValidationsPatient({
            id_gender_valid: false,
            paternal_surname_valid: false,
            maternal_surname_valid: true,
            name_valid: false,
            birthday_valid: false,
            age_valid: false,
            phone_patient_valid: false,
            email_patient_valid: false,
            curp_valid: false,
            cp_patient_valid: false,
            id_tax_regime_valid: false,
            rfc_valid: false,
            id_use_cfdi_valid: false,
            municipality_Valid: false,
            state_valid: false,
            expedient_number: true,
            id_work_order_type_service: true
        });
        setshowClassINE({
            ClassNameINE: "d-none",
            NameFile: ""
        });
        setMethodPatient("create");
        setModalTitle("Registrar nuevo paciente");

        getStates();
        getBloodType();
        getSocialGender();
        getTaxRegime();
        setViewNewSocialGender(false);
        togglePatient();
    }

    const handleUpdatePatient = () => {

        // console.log(informationPatient);
        // console.log("**************");
        let {
            id_social_gender_patient,
            birthday, phone, gender, email, curp, membership, name, paternal_surname, maternal_surname, cardnumber, age,
            scan_ine_check, img64FileI, cp, colony, address, id_state, id_municipality, expedient_number, id_pet
        } = informationPatient;


        let parseBirthday = moment(birthday, ["DD/MM/YYYY", "YYYY/MM/DD"]);

        if (moment(parseBirthday).format("DD/MM/YYYY") !== "Invalid date") {
            parseBirthday = moment(parseBirthday).format("DD/MM/YYYY");
        } else {
            parseBirthday = moment(parseBirthday).format("YYYY/MM/DD");
        }


        handleUpdateValuesPatient({
            id_gender: gender === "Masculino" || gender === "MASCULINO" ? 2 : 1,
            id_state: id_state,
            id_municipality: id_municipality,
            id_blood_type: 0,
            id_social_gender: id_social_gender_patient === undefined ? 0 : id_social_gender_patient,
            id_tax_regime: 0,
            id_use_cfdi: 0,
            rfc: "",
            type_person: 1,
            code: "",
            paternal_surname: paternal_surname,
            maternal_surname: maternal_surname,
            name: name,
            birthday: parseBirthday,
            age: age,
            curp: curp,
            address: "",
            cp_patient: cp,
            colony_patient: "",
            phone: phone,
            email: email,
            who_collects_exams: "",
            membership: membership,
            cardnumber: cardnumber,
            new_social_gender: "",
            scan_ine_check: scan_ine_check,
            setFileINE: "",
            img64File: img64FileI,
            cp: cp,
            colony: colony,
            address: address,
            expedient_number: expedient_number,
            id_pet: id_pet,
        });

        //console.log(formValuesPatient);

        setValidationsPatient({
            id_gender_valid: false,
            paternal_surname_valid: false,
            maternal_surname_valid: true,
            name_valid: false,
            birthday_valid: false,
            age_valid: false,
            phone_patient_valid: false,
            email_patient_valid: false,
            curp_valid: false,
            cp_patient_valid: false,
            id_tax_regime_valid: false,
            rfc_valid: false,
            id_use_cfdi_valid: false,
            municipality_Valid: false,
            state_valid: false,
            expedient_number: false,
            id_work_order_type_service: false
        });

        getSocialGender();
        setMethodPatient("updateData");
        setModalTitle("Actualizar datos del paciente");
        setViewNewSocialGender(false);
        getBloodType();
        getStates();
        togglePatient();
        setshowClassINE({
            ClassNameINE: "d-none",
            NameFile: ""
        });
    }

    const handleChangeTypeService = (e) => {
        //console.log(e);
        handleSelectValuesWorkOrder(e, "id_work_order_type_service");
        if (e.value !== undefined) {
            getTypeService(e.value);
        }
    }


    const handleChangeStatePatient = (e) => {
        //console.log(e);

        handleSelectValuesPatient(e, "id_state");

        if (e.value !== undefined) {
            //console.log("llevas");
            getMunicipality(e.value);
        }

    }

    const handleChangeTypePerson = (e) => {
        let _value = e.target.value;

        handleInputChangePatient(e);

        getTaxRegime(_value !== '' ? parseInt(_value) : 1);

        handleUpdateValuesPatient({
            ...formValuesPatient,
            id_tax_regime: 0,
            type_person: _value
        });
    }

    const handleChangeTaxRegime = (e) => {
        handleSelectValuesPatient(e, "id_tax_regime");

        if (e.value !== undefined) {
            getUseCfdi(e.value, formValuesPatient.type_person);
        }
    }

    const handleChangeSocialGender = (e) => {

        handleSelectValuesPatient(e, "id_social_gender");

        if (e.value !== undefined) {

            if (e.value === 0) {
                setViewNewSocialGender(true);
            }
            else {
                setViewNewSocialGender(false);
            }
        }
    }

    //FILES
    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
        setShowMessageWF({
            labelWF: "Orden Médica Cargada"
        });
        setshowClassSD({
            ClassSD: "f-w-600 f-12 badge badge-secondary"
        });
        setshowClass({
            ClassName: ""
        });
    };

    const DeleteFile = (e) => {

        setOrderMedicalEstatus(0);
        setShowMessageWF({
            labelWF: "Cargar Orden Médica"
        });
        setshowClassSD({
            ClassSD: "f-w-600 f-12 badge badge-primary"
        });
        setshowClass({
            ClassName: "d-none"
        });
    };

    const UpdateFilePatient = async (id_work_order) => {
        if (id_work_order > 0) {
            if (OrderMedicalEstatus == 1) {
                var formData = new FormData();
                formData.append('medical_order', medical_order);
                let requestOptions = {
                    method: 'PUT',
                    body: formData
                };
                const respuesta = await sendRequest(requestOptions, "WorkOrder/" + id_work_order, 'multipart/form-data');
                setShowMessageWF({
                    labelWF: ""
                });
            }
        }
    }
    //#endregion

    //#region METHDS POST, PUT
    const saveCreatePatient = async (e) => {
       
        e.preventDefault();
        setLoadingPatient(true)

        if (validationsPatientForm()) {
            setLoadingPatient(false);

            return;
        }

        let _arrBirthday = formValuesPatient.birthday.split('/');

        let _stringBirthday = `${_arrBirthday[2]}-${_arrBirthday[1]}-${_arrBirthday[0]}`;

        let mData = {
            id_gender: formValuesPatient.id_gender,
            id_state_patient: formValuesPatient.id_state.value,
            id_municipality_patient: formValuesPatient.id_municipality.value,
            id_social_gender_patient: formValuesPatient.id_social_gender.value === undefined ? null : formValuesPatient.id_social_gender.value,
            id_blood_type: formValuesPatient.id_blood_type.value === undefined ? null : formValuesPatient.id_blood_type.value,
            id_tax_regime: formValuesPatient.id_tax_regime.value === undefined ? null : formValuesPatient.id_tax_regime.value,
            id_use_cfdi: formValuesPatient.id_use_cfdi.value === undefined ? null : formValuesPatient.id_use_cfdi.value,
            paternal_surname: formValuesPatient.paternal_surname,
            maternal_surname: formValuesPatient.maternal_surname,
            name: formValuesPatient.name,
            birthday: _stringBirthday,
            age: formValuesPatient.age,
            curp: formValuesPatient.curp,
            address_patient: formValuesPatient.address,
            cp_patient: formValuesPatient.cp,
            colony_patient: formValuesPatient.colony,
            phone: formValuesPatient.phone,
            email_patient: formValuesPatient.email,
            membership: formValuesPatient.membership,
            cardnumber: formValuesPatient.cardnumber,
            rfc: formValuesPatient.rfc,
            type_person: formValuesPatient.type_person,
            new_social_gender: formValuesPatient.new_social_gender,
            scan_ine_check: formValuesPatient.scan_ine_check,
            setFileINE: formValuesPatient.setFileINE,
            expedient_number: formValuesPatient.expedient_number,
        };

        if (pets === "True" || pets) {
            mData.id_pet = formValuesPet.id_pet;
        }

        let listTmp = await handleRequest("POST", `Patient`, 'Paciente', mData);
        
        if (listTmp !== null) {
            setSearchPatientState({
                value: listTmp.id_patient,
                label: listTmp.name_patient
            });

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                id_patient: listTmp.id_patient
            });

            setViewWorkOrder(true);

            await handleGetInformationPatient(listTmp.id_patient);
            setLoadingPatient(false);
            togglePatient();
            createSweet("create", "success", "Paciente creado");
        }
        else {
            setLoading(false);
        }

        setLoading(false);
    }

    const saveUpdatePatient = async (e) => {
        //console.log("pasa");
        e.preventDefault();

        //debugger;

        setLoadingPatient(true);

        if (validationsPatientForm()) {
            setLoadingPatient(false);
            return;
        }




        let _arrBirthday = formValuesPatient.birthday.split('/');

        let _stringBirthday = `${_arrBirthday[2]}-${_arrBirthday[1]}-${_arrBirthday[0]}`;


        let isCalculating = false;

        async function getAge(dateString) {
            if (isCalculating) return; // Si ya hay una función en ejecución, salimos sin hacer nada.
            isCalculating = true; // Establecer la variable de control a true antes de iniciar la ejecución
            try {
                let today = new Date();
                let parts = dateString.split("/");
                let birthDate = new Date(parts[2], parts[1] - 1, parts[0]);
                let years = today.getFullYear() - birthDate.getFullYear();
                let m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    years--;
                }
                formValuesPatient.age = years;
            } catch (error) {
                console.error("Error al calcular la edad del paciente: " + error);
            } finally {
                isCalculating = false; // Restablecer la variable de control a false después de finalizar la ejecución
            }
        }

        if (formValuesPatient.birthday !== '') {
            getAge(formValuesPatient.birthday);
        }

        var formData = new FormData();
        formData.append('id_patient', id_patient);
        formData.append('id_gender', formValuesPatient.id_gender);
        ////console.log(formValuesPatient.maternal_surname);
        // formData.append('id_social_gender_patient', (formValuesPatient.id_social_gender === 0 || formValuesPatient.id_social_gender.value === undefined) ? 0 : formValuesPatient.id_social_gender.value);
        formData.append('paternal_surname', formValuesPatient.paternal_surname);
        formData.append('maternal_surname', (formValuesPatient.maternal_surname !== "" && formValuesPatient.maternal_surname !== null ? formValuesPatient.maternal_surname : ""));
        formData.append('name', formValuesPatient.name);
        formData.append('membership', formValuesPatient.membership !== "" && formValuesPatient.membership !== null ? formValuesPatient.membership : null);
        formData.append('cardnumber', formValuesPatient.cardnumber !== "" && formValuesPatient.cardnumber !== null ? formValuesPatient.cardnumber : null);
        formData.append('birthday', _stringBirthday);
        formData.append('age', formValuesPatient.age);
        formData.append('curp', formValuesPatient.curp);
        formData.append('phone', formValuesPatient.phone);
        formData.append('email_patient', formValuesPatient.email.trim());
        //  formData.append('new_social_gender', formValuesPatient.new_social_gender);
        //  formData.append('scan_ine_check', formValuesPatient.scan_ine_check);
        //  formData.append('setFileINE', formValuesPatient.setFileINE);
        formData.append('cp', formValuesPatient.cp);
        // formData.append('id_blood_type', formValuesPatient.id_blood_type.value);
        formData.append('colony', formValuesPatient.colony);
        formData.append('address', formValuesPatient.address);
        formData.append('id_state_patient', formValuesPatient.id_state.value);
        formData.append('id_municipality_patient', formValuesPatient.id_municipality.value);
        formData.append('expedient_number', formValuesPatient.expedient_number);
        formData.append('id_pet', formValuesPatient.id_pet);

        //  formData.append('age', age);

        let name_patient = `${formValuesPatient.name} ${formValuesPatient.paternal_surname} ${formValuesPatient.maternal_surname} - ${formValuesPatient.curp}`;

        const listTmp = await handleRequest("PUT", `Patient/UpdateShort`, 'Paciente', formData, true);

        //console.log('listTmp: ', listTmp);

        //console.log('listTmp: ', listTmp);
        if (listTmp !== null) {


            setInformationPatient({
                value: id_patient,
                label: name_patient
            });

            await handleGetInformationPatient(id_patient);

            setLoadingPatient(false);
            togglePatient();
            createSweet("create", "info", "Paciente actualizado");


        }
        else {
            setLoading(false);
        }

        setLoading(false);
    }

    // Esta fuhnción es para bloquear el campo de edad y que solo se puedn hacer modifiaciones con las flechas
    const [age, setAge] = useState(0);

    const handleWheel = (event) => {
        event.preventDefault(); // Evitar el comportamiento predeterminado de la rueda del mouse
        const direction = event.deltaY > 0 ? -1 : 1; // Determinar la dirección de la rueda
        const newValue = parseInt(age) + direction;
        age(formValuesPatient.age);
        // Actualizar el valor solo si es un número válido y está dentro del rango (min={0} en tu caso)
        if (!isNaN(newValue) && newValue >= 0) {
            setAge(newValue);
        }
    };

    let isCalculating = false; // Variable de control para indicar si una función está en ejecución

    //#region CALCULO PARA LA EDAD DEL PACIENTE

    async function getAge(dateString) {
        if (isCalculating) return; // Si ya hay una función en ejecución, salimos sin hacer nada.
        isCalculating = true; // Establecer la variable de control a true antes de iniciar la ejecución
        try {
            let today = new Date();
            let parts = dateString.split("/");
            let birthDate = new Date(parts[2], parts[1] - 1, parts[0]);
            let years = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                years--;
            }
            formValuesPatient.age = years;
        } catch (error) {
            console.error("Error al calcular la edad del paciente: " + error);
        } finally {
            isCalculating = false; // Restablecer la variable de control a false después de finalizar la ejecución
        }
    }

    //#endregion

    //#region CALCULO PARA FECHA DE NACIMIENTO DEL PACIENTE

    async function getBirthday(years) {
        if (isCalculating) return;// Si ya hay una función en ejecución, salimos sin hacer nada.
        isCalculating = true; // Establecer la variable de control a true antes de iniciar la ejecución
        try {
            let now = new Date().getFullYear();
            let calculateYear = now - years;
            let date = `01/01/${calculateYear}`;
            formValuesPatient.birthday = date;
        } catch (error) {
            console.error("Error al calcular la fecha de nacimiento del paciente: " + error);
        } finally {
            isCalculating = false; // Restablecer la variable de control a false después de finalizar la ejecución
        }
    }


    //#endregion


    async function processPatientData() {
        if ((formValuesPatient.age.length >= 1) && (formValuesPatient.age.length < 4)) {
            getBirthday(formValuesPatient.age);
        }
    }

    processPatientData();


    if (formValuesPatient.birthday !== '') {
        getAge(formValuesPatient.birthday);
    }

    const saveSocialGender = async (e) => {
        e.preventDefault();

        if (validationSocialGender()) {
            return;
        }
        let mData = {
            ...{ ...formSocialGender }
        };
        let mValues = JSON.stringify(mData);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "SocialGender");

        if (respuesta.code === 200) {

            createSweet("create", "success", "Exito!", "Género Social creado con exito");
            getSocialGender();
            formSocialGender.name_genderc = "";
            formSocialGender.abreviaturac = "";

        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveSocialGender);

        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Género Social", "Ocurrio un problema en el servidor");

        }
        else {
            createSweet("error", "warning", "Género Social", respuesta.data.msg);

        }
    }
    //#endregion

    //#endregion PATIENT METHODS

    //#endregion PATIENT

    //#region QR PATIENT

    //MODEL
    const [formValuesQr, handleInputChangeQr, resetQr, handleUpdateValuesQr, handleSelectValuesQr] = useForm({
        user_unique_code: ""
    });

    const {
        user_unique_code
    } = formValuesQr;

    const handleScannQrPatient = () => {
        toggleModalScannQr();

        handleUpdateValuesQr({
            user_unique_code: ""
        });
    }

    const inputReadQrCode = useRef(null);

    const qrCodeAutoFocus = () => {
        if (focusQrPatient) {
            if (inputReadQrCode.current !== null) {
                inputReadQrCode.current.focus();
            }
        }
    }

    const onKeyPressQr = (event) => {
        if (event.keyCode === 13) {

            let valueQrCode = event.target.value;

            if (valueQrCode !== '') {
                handleGetInformationPatient(0, valueQrCode);
                toggleModalScannQr();
            }
            else {
                createSweet("warning", "warning", "Membresia", "El código QR ingresado no contiene información");
            }
        }
    }

    useEffect(() => {
        qrCodeAutoFocus();
    }, [modalScannQrPatient]);

    //#endregion QR PATIENT

    //#region DOCTOR
    //#region DOCTOR MODEL
    //USE STATE 
    const [searchDoctorState, setSearchDoctorState] = useState([]);
    const [informationDoctor, setInformationDoctor] = useState([]);
    const [specialties, setSpecialties] = useState([]);
    const [idDoctor, setIdDoctor] = useState(0);
    const [loadingDoctor, setLoadingDoctor] = useState(false);
    const [idDoctorSelect, setIdDoctorSelect] = useState([]);

    //FORM VALUES DOCTOR
    const [formDoctor, handleInputChangeDoctor, resetDoctor, handleUpdateValuesDoctor] = useForm({
        id_specialty: 0,
        name_doctor: "",
        professional_license: "",
        phone_doctor: "",
        address_doctor: "",
        email_doctor: "",
        name_specialty: "",
        isCommercialPartner: false
    });

    const {
        id_specialty, name_doctor, professional_license, phone_doctor, address_doctor, email_doctor, name_specialty, isCommercialPartner
    } = formDoctor;


    //VALIDATIONs DOCTOR
    const [validationsDoctor, setValidationsDoctor] = useState({
        id_specialty: false,
        name: false,
        name_specialty: false
    });

    //DOCTOR NAME INPUTS
    const [nameInputsDoctor, setNameInputsDoctor] = useState({
        id_specialty: "id_specialty",
        name: "name_doctor",
        professional_license: "professional_license",
        phone: "phone_doctor",
        address: "address_doctor",
        email: "email_doctor"
    });
    //#endregion DOCTOR MDDEL   

    //#region DOCTOR METHODS
    const handleGetSpecialties = async () => {
        let listTmp = await handleRequest("GET", `Specialties/List`, 'Especialidades');

        if (listTmp !== null && listTmp.length > 0) {
            setSpecialties(listTmp);
        }
    }

    const handleChangeDoctor = (e) => {

        handleInputChangeWorkOrder(e);

        let doctor = e.target.value;

        let tmpDoctor = [];

        if (doctor.length > 0) {
            setIdDoctorSelect(doctor);

            doctor.forEach(d => {
                let iddoctor = d.value;
                handleGetInformationDoctor(iddoctor, tmpDoctor);
            })
        }
    }


    // const searchDoctor = () => {
    //     const arrayDoctors = informationDoctor.map(doctor => ({ 
    //         value: doctor.id_doctor, 
    //         label: doctor.name 
    //     }));
    //     setDoctList(arrayDoctors)
    //     //console.log('arrayDoctors', arrayDoctors)
    // }

    const handleGetInformationDoctor = async (id_doctor, tmpDataDoctor) => {
        // //console.log('tmpDataDoctor', tmpDataDoctor)
        // //console.log('id_doctor ----->', id_doctor)
        let listTmp = await handleRequest("GET", `Doctors/FindDoctorId/${id_doctor}`, 'Doctor');
        ////console.log('LISTTEMPO: ', listTmp)
        let list = [];
        if (listTmp !== null) {

            tmpDataDoctor.push(listTmp);
            //actualiza la información del Doctor:
            setInformationDoctor([
                ...tmpDataDoctor
            ]);
        }
    }

    const handleCreateDoctor = () => {
        handleUpdateValuesDoctor({
            id_specialty: 0,
            name_doctor: "",
            professional_license: "",
            phone_doctor: "",
            address_doctor: "",
            email_doctor: "",
            name_specialty: "",
            isCommercialPartner: false,
            checkDoctor: false,
        });

        setValidationsDoctor({
            id_specialty: false,
            name: false,
            name_specialty: false
        });

        setIdDoctor(0);
        setMethodDoctor("create");
        setModalTitle("Registrar nuevo doctor");
        toggleDoctor();
        handleGetSpecialties();
    }

    const [validaciones1, setValidaciones1] = useState({
        id_specialty: false,
        name: false,
    });

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};
        if (formDoctor.id_specialty <= 0) {
            // //console.log("pasas aqui??????????");
            newValidaciones = {
                ...newValidaciones,
                id_specialty: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                id_specialty: false
            }
        }
        if (formDoctor.name_doctor.length <= 2) {
            newValidaciones = {
                ...newValidaciones,
                name_doctor: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                name_doctor: false
            }
        }
        setValidaciones1({
            ...validaciones1,
            ...newValidaciones
        })
        return statusValidacion;
    }

    // //console.log(validaciones1);
    // //console.log("************");

    const saveCreateDoctor = async (e) => {
        ////console.log('acaaaa ando entrando joven')
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoadingDoctor(true);

        let newForm = {
            id_specialty: formDoctor.id_specialty === "new" ? null : formDoctor.id_specialty,
            name: formDoctor.name_doctor,
            professional_license: formDoctor.professional_license,
            phone: formDoctor.phone_doctor,
            address: formDoctor.address_doctor,
            email: formDoctor.email_doctor,
            name_specialty: formDoctor.name_specialty,
            isCommercialPartner: formDoctor.isCommercialPartner === true ? 1 : null
        };

        let listTmp = await handleRequest("POST", "Doctors", "Doctores", newForm);
        console.log("LISTTMP: ",listTmp)
        if (listTmp !== null) {
            setSearchDoctorState({
                value: listTmp.id_doctor,
                label: name_doctor
            });

            //ESTE CÓDIGO NO SIRVE SÍ ES DE EMPRESAS PARA MÉDICOS
            // let tmpIdDoctor = idDoctorSelect;

            // tmpIdDoctor.push({
            //     value: listTmp.id_doctor,
            //     label: listTmp.name
            // });

            //setIdDoctorSelect([...tmpIdDoctor]);

            const updatedIdDoctorSelect = Array.isArray(idDoctorSelect) ? [...idDoctorSelect, {
                value: listTmp.id_doctor,
                label: listTmp.name
            }] : [{
                value: listTmp.id_doctor,
                label: listTmp.name
            }];

            setIdDoctorSelect(updatedIdDoctorSelect);
            let tmpArray = [];

            updatedIdDoctorSelect.forEach(obj => {
                handleGetInformationDoctor(obj.value, tmpArray);

                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    id_doctor: obj.value
                });
            });

            handleUpdateValuesDoctor({
                id_specialty: 0,
                name_doctor: "",
                professional_license: "",
                phone_doctor: "",
                address_doctor: "",
                email_doctor: "",
                name_specialty: "",
                isCommercialPartner: false,
                checkDoctor: false,
            });

            setLoadingDoctor(false);
            toggleDoctor();
            createSweet("create", "success", "Doctor creado");
        }
    }

    const handleUpdateDoctor = (id_doctor) => {
        let findDoctor = informationDoctor.find(d => d.id_doctor === id_doctor);
        ////console.log(findDoctor);

        if (findDoctor) {
            handleUpdateValuesDoctor({
                id_specialty: findDoctor.id_specialty,
                name_doctor: findDoctor.name,
                professional_license: findDoctor.professional_license,
                phone_doctor: findDoctor.phone,
                address_doctor: findDoctor.address,
                email_doctor: findDoctor.email,
                name_specialty: findDoctor.name_specialty,
                isCommercialPartner: findDoctor.isCommercialPartner,
                listDoctores: findDoctor,
            });

            setValidationsDoctor({
                id_specialty: false,
                name: false,
                name_specialty: false
            });

            setMethodDoctor("update");
            setModalTitle("Actualizar información del doctor");
            toggleDoctor();
            handleGetSpecialties();
            setIdDoctor(id_doctor)
        }
    }

    const saveUpdateDoctor = async (e) => {
        e.preventDefault();

        setLoadingDoctor(true);

        let newForm = {
            id_doctor: idDoctor,
            id_specialty: (formDoctor.id_specialty === "new" ? null : formDoctor.id_specialty),
            name: formDoctor.name_doctor,
            professional_license: formDoctor.professional_license,
            phone: formDoctor.phone_doctor,
            address: formDoctor.address_doctor,
            email: formDoctor.email_doctor,
            isCommercialPartner: formDoctor.isCommercialPartner === true ? 1 : null,
        };

        ////console.log(newForm);

        let listTmp = await handleRequest("PUT", "Doctors/Update", "Doctores", newForm);
        if (listTmp !== null) {
            setSearchDoctorState({
                value: idDoctor,
                label: formDoctor.name_doctor
            });

            let tmpArray = [];

            idDoctorSelect.forEach(obj => {
                handleGetInformationDoctor(obj.value, tmpArray);
            });

            handleUpdateValuesDoctor({
                id_specialty: 0,
                name_doctor: "",
                professional_license: "",
                phone_doctor: "",
                address_doctor: "",
                email_doctor: "",
                name_specialty: "",
                checkDoctor: false,
            });

            setLoadingDoctor(false);
            toggleDoctor();
            createSweet("create", "success", "Doctor actualizado correctamente");
            setIdDoctor(0);
        }
    }


    // const handleDeleteDoctor = async (id_work_order_doctor) => {
    //     //console.log(id_work_order_doctor)

    //     let respuesta = await handleRequest("DELETE", `WorkOrder/DeleteDoctor/${id_work_order_doctor}`, "Doctores");

    //     if(respuesta !== null){

    //         const updatedDoctors = doctList.filter(doc => doc.id_work_order_doctor !== id_work_order_doctor);

    //         //console.log('updatedDoctors: ',updatedDoctors)
    //         setDoctList(updatedDoctors)
    //         //saveUpdateDoctor()
    //         setDoctList(updatedDoctors);
    //         //setInformationDoctor(updatedDoctors);
    //         //saveUpdateDoctor(updatedDoctors)
    //         setLoading(true);
    //         createSweet("success", "success", "Se elimino correctamente del Doctor");
    //     }
    //     //console.log('doctListor: ',doctList)

    // if (respuesta.code === 200) {
    //     createSweet("success", "success", "Se elimino correctamente del Doctor");
    //     setInformationDoctor([
    //         ...respuesta
    //     ]);
    // } else if (respuesta.code === 401) {
    //     validarSesion(history, respuesta.code, handleDeleteDoctor);
    // } else if (respuesta.code === 500) {
    //     createSweet("error", "error", "Doctor Eliminado", "Ocurrio un error en el servidor");
    // }
    // }


    const updateDoctorListAfterDeletion = (id) => {
        const updatedDoctors = doctList.filter(doc => doc.id_work_order_doctor !== id);
        let a = informationDoctor
        setDoctList(updatedDoctors);
        // setInformationDoctor(updatedDoctors)
        setLoading(true);
        createSweet("success", "success", "Se elimino correctamente del Doctor");
    }

    const handleDeleteDoctor = async (id_work_order_doctor) => {
        if (id_work_order_doctor === undefined) {

        }
        else {
            try {
                let respuesta = await handleRequest("DELETE", `WorkOrder/DeleteDoctor/${id_work_order_doctor}`, "Doctores");
                if (respuesta !== null) {
                    //updateDoctorListAfterDeletion(id_work_order_doctor);
                    let a = informationDoctor
                    let array1 = a.filter(doc => doc.id_work_order_doctor !== id_work_order_doctor);
                    if (array1) {
                        setInformationDoctor([...array1]
                        );
                    }
                }
            } catch (error) {
            }
        }
    }

    const [disableEdit, setDisableEdit] = useState(0);


    const handleSaveDoctor = async (id_work_order, id_doctor) => {

        let _valid = id_doctor === undefined ? 0 : 1;
        ////console.log(id_work_order, id_doctor)

        let listTmp = await handleRequest("POST", `WorkOrder/SaveDoctor/${id_work_order}?id_doctor=${id_doctor}`, 'Doctor');
        ////console.log('listTmp: ',listTmp);
        if (listTmp !== null) {
            createSweet("success", "success", "Se guardó correctamente del Doctor");
            setDisableEdit(_valid)
        } else {
            createSweet("warning", "warning", "Error al guardar");
        }
    }

    //#endregion DOCTOR METHODS

    //#endregion DOCTOR

    //#region SPECIAL DISCOUNTS

    //#region SPECIAL DISCOUNTS MODEL
    //SPECIAL DISCOUNT
    const [idSpecialDiscount, setIdSpecialDiscount] = useState(0);
    const [specialDiscount, setSpecialDiscount] = useState(null);

    const [formSpecialDiscount, handleInputChangeSpecialDiscount, resetSpecialDiscount, handleUpdateValuesSpecialDiscount] = useForm({
        special_discount_code: "",
        percentage: 0

    });

    const {
        special_discount_code, percentage
    } = formSpecialDiscount;
    //#endregion SPECIAL DISCOUNTS MODEL

    //#region SPECIAL DISCOUNTS METHODS
    const handleModalSpecialDiscount = () => {
        handleUpdateValuesSpecialDiscount({
            special_discount_code: ""
        });

        toggleSpecialDiscount();
    }

    const validateCodeSpecialDiscount = async () => {

        let listTmp = await handleRequest("GET", `SpecialDiscount/ValidateCode/${special_discount_code}`, "Descuento especial");

        let examenes_perfiles_seleccionados = formWorkOrder.listTest;

        if (listTmp !== null) {
            debugger;
            if (listTmp.examProfileInclude.length > 0) {

                listTmp.examProfileInclude.forEach(element => {
                    examenes_perfiles_seleccionados.map(ep => {
                        if (element.id_exam !== null) {
                            if (ep.id_exam !== null && element.id_exam === ep.id_exam) {
                                ep.id_common_discount = 0;
                                ep.id_special_discount = listTmp.id_special_discount;
                                ep.auto_redeem = true;
                                if (element.percentage > 0) {
                                    ep.discount = element.percentage;
                                }
                                else {
                                    ep.discount = listTmp.percentage;
                                }
                            }
                        }
                        else {
                            if (ep.id_profile !== null && element.id_profile === ep.id_profile) {
                                ep.id_common_discount = 0;
                                ep.id_special_discount = listTmp.id_special_discount;
                                ep.auto_redeem = true;
                                if (element.percentage > 0) {
                                    ep.discount = element.percentage;
                                }
                                else {
                                    ep.discount = listTmp.percentage;
                                }
                            }
                        }

                        return ep;
                    });
                });
            }

            if (listTmp.is_all_validate_in_exams) {
                examenes_perfiles_seleccionados.map(_test => {
                    if (_test.id_exam !== null) {
                        _test.id_common_discount = 0;
                        _test.id_special_discount = listTmp.id_special_discount;
                        _test.discount = listTmp.percentage;
                        _test.auto_redeem = true;
                    }
                })
            }

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: examenes_perfiles_seleccionados
            });

            setSpecialDiscount(listTmp);
            createSweet("info", "success", "El código ingresado es válido");
            toggleSpecialDiscount();
        }
    }


    const validatePercentajeSpecialDiscount = async () => {

        let _body = {
            percentage: formSpecialDiscount.percentage
        }

        let listTmp = await handleRequest("POST", "SpecialDiscount/ApplySpecialDiscountWorkOrder", "Porcentaje especial", _body);

        let examenes_perfiles_seleccionados = formWorkOrder.listTest;

        if (listTmp !== null) {
            if (listTmp.examProfileInclude.length > 0) {

                listTmp.examProfileInclude.forEach(element => {
                    examenes_perfiles_seleccionados.map(ep => {
                        if (element.id_exam !== null) {
                            if (ep.id_exam !== null && element.id_exam === ep.id_exam) {
                                ep.id_common_discount = 0;
                                ep.id_special_discount = listTmp.id_special_discount;
                                ep.auto_redeem = true;
                                if (element.percentage > 0) {
                                    ep.discount = element.percentage;
                                }
                                else {
                                    ep.discount = listTmp.percentage;
                                }
                            }
                        }
                        else {
                            if (ep.id_profile !== null && element.id_profile === ep.id_profile) {
                                ep.id_common_discount = 0;
                                ep.id_special_discount = listTmp.id_special_discount;
                                ep.auto_redeem = true;
                                if (element.percentage > 0) {
                                    ep.discount = element.percentage;
                                }
                                else {
                                    ep.discount = listTmp.percentage;
                                }
                            }
                        }

                        return ep;
                    });
                });
            }

            if (listTmp.is_all_validate_in_exams) {
                examenes_perfiles_seleccionados.map(_test => {
                    if (_test.id_exam !== null) {
                        _test.id_common_discount = 0;
                        _test.id_special_discount = listTmp.id_special_discount;
                        _test.discount = listTmp.percentage;
                        _test.auto_redeem = true;
                    }
                })
            }

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: examenes_perfiles_seleccionados
            });

            //   setSpecialDiscount(listTmp);
            createSweet("info", "success", "El Porcentaje asignado fue exitoso");
            toggleSpecialDiscount();
        }
    }

    //#endregion

    //#endregion SPECIAL DISCOUNTS

    //#region COMMON DISCOUNTS        
    //#region COMMON DISCOUNT MODEL
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const [listCampaigns, setListCampaigns] = useState([]);

    //#endregion

    //#region COMMON DISCOUNTS METHODS
    const handleModalListCommonDiscounts = async () => {

        if (specialDiscount !== null) {
            setArrayNameDiscount([`Descuento especial: COD-00${specialDiscount.id_special_discount}`]);
            toggleCommonDiscount();
        }
        else if (arrayAgreementDiscounts.length > 0) {
            toggleCommonDiscount();
        }
        else {
            if (arrayCommonDiscounts.length > 0) {
                let list_ids_commonDiscount = [];
                let _tmp_array_common_discounts = arrayCommonDiscounts;

                arrayCommonDiscounts.forEach(_commmon => {
                    list_ids_commonDiscount.push(_commmon.id_common_discount);
                });

                const listTmp = await handleRequest("POST", 'CommonDiscount/GetNameList', 'Descuentos', list_ids_commonDiscount);

                if (listTmp !== null && listTmp.length > 0) {

                    listTmp.forEach(_discount => {
                        let _find_common = _tmp_array_common_discounts.find(x => x.id_common_discount === _discount.item);

                        if (_find_common !== undefined) {
                            _find_common.name = _discount.name;
                            _find_common.valid_from = _discount.valid_from;
                            _find_common.valid_to = _discount.valid_to;
                        }
                    });

                    setArrayCommonDiscounts([..._tmp_array_common_discounts]);

                    toggleCommonDiscount();
                }
            }
        }
    }

    const handleModalSocialGender = async () => {
        toggleModalSocialGender();
        setvalidationsSG({
            name_genderc_valid: false,
            abreviaturac_valid: false,
        });
    }

    const clearDiscount = async (e, _id_common_discount) => {


        let _checked = e.target.checked//undefined  y aqui lo valide por eso se desactiva cuando terminas de
        // let _checked = false
        
        let _common_discounts = arrayCommonDiscounts.map(_common => {
            
            if (_common.id_common_discount === _id_common_discount) {
               
                _common.is_available_discount = _checked ? false : false
            }
            return _common;
        });

        
        let _list_test = listTest.map(_test => {
            
            if (_test.id_common_discount === _id_common_discount) {
               

                _test.auto_redeem = _checked ? false : false;
                //_test.auto_redeem = _checked
            }

            return _test;
        });
        


        setArrayCommonDiscounts([..._common_discounts]);

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: [..._list_test]
        });
    }

    const newDiscount = async (e, _id_common_discount) => {

       

        //mirA ES ESO

        

     
        let _checked = e === undefined ? false : e.target.checked//undefined
        // let _checked = false
       
        let _common_discounts = arrayCommonDiscounts.map(_common => {
            
            if (_common.id_common_discount === _id_common_discount) {
                
                _common.is_available_discount = true
            }
            return _common;
        });

        
        let _list_test = listTest.map(_test => {
           
            if (_test.id_common_discount === _id_common_discount) {
               

                _test.is_available_discount = true
                //_test.auto_redeem = _checked
            }

            return _test;
        });
        

        let _array = listTest
        _array.forEach(_f => {
            _f.auto_redeem = true;

            return _f;


        });

        setArrayCommonDiscounts([..._common_discounts]);

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: [..._list_test]
        });
    }


    const handleGetCampaigns = async (_id_patient, _id_commercial_line, _id_branch) => {
        const _listTmp = await handleRequest("GET", `CommonDiscount/SearchCommonDiscountByPatient/${_id_patient}/${_id_commercial_line}/${_id_branch}`, "Campañas");
     
        let list = [];
        if (_listTmp !== null && _listTmp.length > 0) {

            _listTmp.forEach(campaign => {
                list.push({
                    value: campaign.id_common_discount,
                    label: campaign.code_compaing + " " + campaign.name_compaing
                });
            });

            setListCampaigns(list);
        }
    }

    const handleChangeCampaign = (e) => {
        setSelectedCampaign(e);

        if (e.value !== undefined) {
            handleGetExamsProfileCommonDiscount(e.value);
        }
    }

    const handleGetExamsProfileCommonDiscount = async (_id_common_discount) => {

        const _listTmp = await handleRequest("GET", `CommonDiscount/GetExamsProfilesInCommonDiscount/${_id_common_discount}/${id_patient}/${id_commercial_line}/${id_branch}/${is_membership ? true : false}`);

        if (_listTmp !== null) {

            let list = listTest;

            let _list_exams = _listTmp.list_exams;
            let _list_profiles = _listTmp.list_profiles;

            _list_exams.forEach(_exam => {

                //#region VALIDATIONS DUPLICATE EXAM

                let stop_array_exam = 0;

                let validationExam = validationTest(_exam.id_exam);

                if (validationExam !== -1 && validationExam !== 2) {
                    sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                    stop_array_exam = 1;
                }

                //#endregion

                if (stop_array_exam === 0) {

                    //#region CHECK SPECIMENS
                    let valorCheck = true;
                    let valorCheckForwarded = false;

                    list.forEach(item => {
                        if (item.id_specimen === parseInt(_exam.id_specimen)) {
                            valorCheck = item.check_specimen;
                            valorCheckForwarded = false;
                        }
                    });
                    //#endregion

                    //#region DISCOUNTS
                    let descuentoEspecial = null;

                    let id_common_discount = null;

                    let is_auto_redeem = false;

                    let porcentaje_descuento = 0;

                    if (specialDiscount !== null) {
                        if (specialDiscount.examProfileInclude.length > 0) {
                            specialDiscount.examProfileInclude.forEach(obj => {
                                if (obj.id_exam === _exam.id_exam) {
                                    if (obj.percentege > 0) {
                                        porcentaje_descuento = obj.percentege;
                                        descuentoEspecial = specialDiscount.id_special_discount;
                                    }
                                    else {
                                        porcentaje_descuento = specialDiscount.percentege;
                                        descuentoEspecial = specialDiscount.id_special_discount;
                                    }
                                }
                            });
                        }
                        else {
                            if (!_exam.is_referenced) {
                                porcentaje_descuento = specialDiscount.percentege;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                        }
                    }
                    else if (_exam.validateExamCommonDiscount !== null) {
                        let _listCommonDiscounts = arrayCommonDiscounts;

                        let date_now = moment().format("YYYY-MM-DD");
                        let date_from = moment(_exam.validateExamCommonDiscount.valid_from).format("YYYY-MM-DD");
                        let date_to = moment(_exam.validateExamCommonDiscount.valid_to).format("YYYY-MM-DD");

                        if (date_now >= date_from && date_now <= date_to) {

                            id_common_discount = _exam.validateExamCommonDiscount.id_common_discount;
                            is_auto_redeem = true;
                            porcentaje_descuento = _exam.validateExamCommonDiscount.percentage_discount;

                            if (_listCommonDiscounts.find(x => x.id_common_discount === id_common_discount) === undefined) {
                                _listCommonDiscounts.push({
                                    id_common_discount: id_common_discount,
                                    is_available_discount: true,
                                    is_enable: false
                                });
                            }

                            setArrayCommonDiscounts(_listCommonDiscounts);
                        }
                    }
                    //#endregion

                    list.push({
                        id_exam: _exam.id_exam,
                        id_profile: null,
                        name: _exam.name,
                        abbreviation: _exam.abbreviation,
                        code: _exam.code,
                        id_rate: _exam.id_rate,
                        id_price: _exam.id_price,
                        name_rate: _exam.name_rate,
                        price: _exam.price,
                        specimen_name: _exam.name_specimen,
                        id_specimen: _exam.id_specimen,
                        check_specimen: valorCheck,
                        check_forwarded: valorCheckForwarded,
                        discount: porcentaje_descuento,
                        id_common_discount: id_common_discount,
                        id_special_discount: descuentoEspecial,
                        id_agreement: null,
                        id_agreement_test_range: null,
                        contract_number: null,
                        listIndications: _exam.listIndications,
                        is_urgent: false,
                        is_curve: false,
                        config_iva: _exam.config_iva,
                        value_iva: _exam.value_iva,
                        listExams: null,
                        urgent_price: 0,
                        auto_redeem: is_auto_redeem,
                        is_campaign: true,
                        name_campaign: _listTmp.name_common_discount,
                        is_referenced: _listTmp.is_referenced,
                        id_external_laboratory: _listTmp.id_external_laboratory,
                        name_external_laboratory: _listTmp.name_external_laboratory
                    });
                }
            });

            _list_profiles.forEach(_profile => {
                list = valid_and_add_profile(list, _profile, true, _listTmp.name_common_discount);
            });

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: list
            });

            setSelectedCampaign({});
        }
    }

    const valid_and_add_profile = (list, _profile, is_campaign = false, name_campaign = "", valid_exist = -1) => {

        //#region VALID DUPLICATE PROFILE

        let _add_profile = true;

        let validTest = validationTest(null, _profile.id_profile, _profile.listExams, _profile.name);

        if (validTest !== -1 && validTest !== 2) {
            sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
            _add_profile = false;
        }
        else if (validTest === 2 && valid_exist === -1) {
            toggleRepeatProfile();
            setModalTitle("Perfiles con exámenes duplicados");
            _add_profile = false;
        }
        //#endregion

        if (_add_profile) {

            //#region SPECIMENS
            let array_specimens = [];

            _profile.exams_specimens.forEach(obj => {
                if (!array_specimens.find(x => x.id_specimen === obj.id_specimen_origin)) {

                    let valorCheck = true;
                    let valorCheckForwarded = false;

                    listTest.forEach(item => {
                        if (item.id_specimen === parseInt(obj.id_specimen_origin)) {
                            valorCheck = item.check_specimen;
                            valorCheckForwarded = false;
                        }
                    });

                    array_specimens.push({
                        specimen_name: obj.name_specimen_origin,
                        id_specimen: obj.id_specimen_origin,
                        check_specimen: valorCheck,
                        check_forwarded: valorCheckForwarded,
                    });
                }
            });
            //#endregion

            let descuentoEspecial = null;

            let id_common_discount = null;

            let is_auto_redeem = false;

            let porcentaje_descuento = 0;

            if (specialDiscount !== null) {
                if (specialDiscount.examProfileInclude.length > 0) {
                    specialDiscount.examProfileInclude.forEach(obj => {
                        if (obj.id_profile === _profile.id_profile) {
                            if (obj.percentege > 0) {
                                porcentaje_descuento = obj.percentege;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                            else {
                                porcentaje_descuento = specialDiscount.percentage;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                        }
                    })
                }
            }
            else if (_profile.commonDiscounts !== null && _profile.commonDiscounts.length > 0) {
                let _list_common_discount_curves = listCommonDiscountsCurves;
                let _list_common_discount = arrayCommonDiscounts;

                if (_profile.is_curve) {
                    _profile.commonDiscounts.forEach(_common => {
                        id_common_discount = _common.id_common_discount;

                        if (_list_common_discount_curves.find(x => x.id_profile === _profile.id_profile && x.id_profile_curve_price === _common.id_profile_curve_price) === undefined) {
                            _list_common_discount_curves.push({
                                id_common_discount: _common.id_common_discount,
                                id_profile_curve_price: _common.id_profile_curve_price,
                                id_profile: _profile.id_profile,
                                percentage_discount: _common.percentage_discount,
                                auto_redeem: true,
                                valid_to: _common.valid_to,
                                valid_from: _common.valid_from
                            });
                        }
                    });

                    setListCommonDiscountsCurves(_list_common_discount_curves);
                }
                else {
                    let _common_discount = _profile.commonDiscounts[0];

                    let _date_now = moment().format("YYYY-MM-DD");
                    let _date_from = moment(_common_discount.valid_from).format("YYYY-MM-DD");
                    let _date_to = moment(_common_discount.valid_to).from("YYYY-MM-DD");

                    if (_date_now >= _date_from && _date_now <= _date_to) {

                        id_common_discount = _common_discount.id_common_discount;
                        porcentaje_descuento = _common_discount.percentage_discount;
                        is_auto_redeem = true;

                        if (_list_common_discount.find(x => x.id_common_discount === id_common_discount) === undefined) {
                            _list_common_discount.push({
                                id_common_discount: id_common_discount,
                                is_available_discount: true,
                                is_enable: false
                            });
                        }

                        setArrayCommonDiscounts(_list_common_discount);
                    }
                }
            }

            list.push({
                id_profile: _profile.id_profile,
                id_exam: null,
                name: _profile.name,
                abbreviation: _profile.abbreviation,
                code: _profile.code,
                id_rate: null,
                id_price: _profile.id_price,
                name_rate: "",
                price: _profile.price,
                specimen_name: "",
                id_specimen: null,
                array_specimens: array_specimens,
                discount: porcentaje_descuento,
                id_common_discount: id_common_discount,
                id_special_discount: descuentoEspecial,
                id_agreement: null,
                id_agreement_test_range: null,
                contract_number: null,
                listIndications: _profile.listExamIndications,
                is_urgent: false,
                is_curve: _profile.is_curve,
                curves: _profile.listCurves,
                listExams: _profile.listExams,
                config_iva: _profile.config_iva,
                value_iva: _profile.value_iva,
                is_quotation: false,
                id_profile_curve_price: 0,
                urgent_price: 0,
                auto_redeem: is_auto_redeem,
                is_campaign: is_campaign,
                name_campaign: name_campaign
            });
        }

        return list;
    }

    // const [searchSupplies, setSearchSupplies] = useState([]);

    // const removeSupplies = (id) => {
    //     //console.log("Intentando eliminar el suministro con id_additional:", id);

    //     // Busca el índice del primer suministro con el id dado
    //     const indexToRemove = listSupplies.findIndex(supply => supply.id_additional === id);

    //     if (indexToRemove !== -1) { // Si se encuentra el índice
    //         // Crea una copia de listSupplies y elimina el suministro en el índice encontrado
    //         const updatedSupplies = [...listSupplies];
    //         updatedSupplies.splice(indexToRemove, 1);

    //         handleUpdateValuesWorkOrder({
    //             ...formWorkOrder,
    //             listSupplies: updatedSupplies,
    //             listPayMethods: [{
    //                 id_pay_method: 0,
    //                 amount: 0.0
    //             }]
    //         });
    //     } else {
    //         //console.log("Suministro con id_additional:", id, " no encontrado.");
    //     }
    //     // //console.log("Intentando eliminar el suministro con id_additional:", id);

    //     // const updatedSupplies = listSupplies.filter(supply => supply.id_additional !== id);

    //     // handleUpdateValuesWorkOrder({
    //     //     ...formWorkOrder,
    //     //     listSupplies: updatedSupplies,
    //     //     listPayMethods: [{
    //     //         id_pay_method: 0,
    //     //         amount: 0.0
    //     //     }]
    //     // });
    // }

    const removeExamProfileByCampaign = (id_common_discount) => {
        let findTest = listTest.find(r => r.id_common_discount === id_common_discount);

        if (findTest !== null) {
            let newListTest = listTest.filter(item => {

                if (item.id_common_discount === id_common_discount) {

                    if (item.is_curve && item.id_profile !== null) {
                        let _filter_common_discount_curves = listCommonDiscountsCurves.filter(_curve => {
                            if (_curve.id_profile === item.id_profile) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        });

                        setListCommonDiscountsCurves([..._filter_common_discount_curves]);
                    }

                    return false;
                }
                else {
                    return true;
                }
            });

            if (newListTest.length == 0) {

                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    listTest: newListTest,
                    listPayMethods: [{
                        id_pay_method: 0,
                        amount: 0.0
                    }]
                });

                setMoneyEntered(0.00);
            }
            else {
                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    listTest: newListTest
                });
            }
        }

        let _common_discounts = arrayCommonDiscounts.filter(_common => {
            if (_common.id_common_discount === id_common_discount) {
                return false;
            }
            else {
                return true;
            }
        });

        setArrayCommonDiscounts([..._common_discounts]);
    }

    //#endregion
    //#endregion

    //#region QUOTER
    //#region QUOTER MODELS
    const [listQuoter, setListQuoter] = useState([]);
    const [loadingQuoter, setLoadingQuoter] = useState(false);
    const [viewTestQuoter, setViewTestQuoter] = useState({});
    const [enableViewTest, setEnableViewTest] = useState(false);



    const [formQuoter, handleInputChangeQuoter, resetQuoter, handleUpdateValuesQuoter] = useForm({
        code_quoter: "",
        date_quoter: ""
    });

    const { code_quoter, date_quoter } = formQuoter;

    const [validationsQuoter, setValidationsQuoter] = useState({
        code_quoter_valid: false,
        date_quoter_valid: false
    });
    //#endregion         

    //#region QUOTER METHODS
    const handleSearchQuoter = () => {
        setModalTitle("Búscar cotización");
        setMethodQuoter("list");
        toggleModalQuoter();
        handleUpdateValuesQuoter({
            code_quoter: "",
            date_quoter: ""
        });
        setListQuoter([]);
    }

    const handleGetQuoter = async (e) => {
        e.preventDefault();

        setLoadingQuoter(true);

        if (validationQuoter()) {
            return;
        }

        let listTmp = await handleRequest("GET", `Quoter/FilterQuoter/${id_branch}/${id_commercial_line}?code_or_name=${code_quoter}&date=${date_quoter}`, "Cotización");

        if (listTmp !== null && listTmp.length > 0) {
            setListQuoter(listTmp);
            setLoadingQuoter(false);
        }
        else {
            setLoadingQuoter(false);
        }
    }

    const handleViewTest = (id_quoter) => {
        setViewTestQuoter({});

        if (id_quoter > 0) {
            let find_quoter = listQuoter.find(x => x.id_quoter === id_quoter);

            if (find_quoter) {
                setViewTestQuoter(find_quoter);
                setEnableViewTest(true);
            }
        }
    }

    const handleCloseViewTest = () => {
        setEnableViewTest(!enableViewTest);
        setViewTestQuoter({});
    }

    const searchQuoterByCode = async (codeQuoter) => {

        setLoadingQuoter(true);

        let listTmp = await handleRequest("GET", `Quoter/GetTestByWorkOrder/${codeQuoter},${id_branch},${id_commercial_line},0,0`, "Cotización");

        if (listTmp != null) {

            let listExams = listTmp.listExamPrices;
            let listProfiles = listTmp.listProfilePrices;
            let id_quoter = listTmp.id_quoter;

            //let listTestQuoter = listTmp.listTestQuoter;

            let listTmpTest = listTest;

            listExams.forEach(e => {
                //console.log(e);

                let descuentoEspecial = null;
                let id_agreement = null;
                let id_agreement_test_range = null;
                let contract_number = null;
                let valorCheck = false;

                let validationExam = validationTest(e.id_exam);

                if (validationExam !== -1 && validationExam !== 2) {
                    sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                    return;
                }

                listTmpTest.forEach(item => {
                    if (item.id_specimen === parseInt(e.id_specimen)) {
                        valorCheck = item.check_specimen;
                    }
                });

                let porcentaje_descuento = 0;

                if (specialDiscount !== null) {
                    if (specialDiscount.examProfileInclude.length > 0) {
                        specialDiscount.examProfileInclude.forEach(obj => {
                            if (obj.id_exam === parseInt(e.id_exam)) {
                                porcentaje_descuento = obj.percentage;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                            else {
                                porcentaje_descuento = specialDiscount.percentage;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                        });
                    }
                    else {
                        if (!e.is_referenced) {
                            porcentaje_descuento = specialDiscount.percentage;
                            descuentoEspecial = specialDiscount.id_special_discount;
                        }
                    }
                }
                else if (e.percentage_discount > 0) {
                    porcentaje_descuento = e.percentage_discount;
                }
                else if (id_company > 0) {
                    if (e.agreement !== null) {
                        let convenio = e.agreement;

                        porcentaje_descuento = convenio.percentage;
                        id_agreement = convenio.id_agreement;
                        id_agreement_test_range = convenio.id_agreement_test_range;
                        contract_number = convenio.contract_number;
                    }
                }

                let _listExternalLaboratories = [];
                _listExternalLaboratories.push({
                    id_external_laboratory: e.id_external_laboratory,
                    id_price: e.id_price,
                    name_external_laboratory: e.name_external_laboratory,
                    price: e.price

                })

                listTmpTest.push({
                    id_exam: e.id_exam,
                    id_profile: null,
                    name: e.name,
                    abbreviation: e.abbreviation,
                    code: e.code,
                    id_price: e.id_price,
                    name_rate: e.name_rate,
                    price: e.price,
                    specimen_name: e.name_specimen,
                    id_specimen: e.id_specimen,
                    check_specimen: valorCheck,
                    check_forwarded: true,
                    discount: porcentaje_descuento,
                    id_common_discount: e.id_common_discount,
                    id_special_discount: descuentoEspecial,
                    id_agreement: id_agreement,
                    id_agreement_test_range: id_agreement_test_range,
                    contract_number: contract_number,
                    listIndications: e.listIndications,
                    is_urgent: false,
                    config_iva: e.config_iva,
                    value_iva: e.value_iva,
                    listExams: null,
                    is_campaign: false,
                    is_referenced: e.is_referenced,
                    id_external_laboratory: e.id_external_laboratory,
                    name_external_laboratory: e.name_external_laboratory,
                    listExternalLaboratories: e.id_external_laboratory === null ? null : _listExternalLaboratories


                });

                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    listTest: listTmpTest
                });
            });

            listProfiles.forEach(p => {
                console.log(p);
                let array_specimens = [];
                let descuentoEspecial = null;
                let id_agreement = null;
                let id_agreement_test_range = null;
                let contract_number = null;

                let validTest = validationTest(null, p.id_profile, p.listExams, p.name);

                if (validTest !== -1 && validTest !== 2) {
                    sweetConfirmRepeatExam("Estudios duplicados", validationTest.message_validation, "warning");
                }
                else if (validTest === 2) {
                    toggleRepeatProfile();
                    setModalTitle("Perfiles con exámenes duplicados");
                    return;
                }

                // if (validationTest(null, p.id_profile, p.listExams)) {
                //     sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                //     return;
                // }

                p.exams_specimens.forEach(obj => {
                    if (!array_specimens.find(x => x.id_specimen === parseInt(obj.id_specimen_origin))) {
                        let valorCheck = true;
                        let valorCheckForwarded = true;
                        listTmpTest.forEach(item => {
                            if (item.id_specimen === parseInt(obj.id_specimen_origin)) {
                                valorCheck = item.check_specimen;
                                valorCheckForwarded = item.check_specimen;
                            }
                        });

                        array_specimens.push({
                            specimen_name: obj.name_specimen_origin,
                            id_specimen: obj.id_specimen_origin,
                            check_specimen: valorCheck,
                            check_forwarded: valorCheckForwarded
                        });
                    }
                });

                let porcentaje_descuento = 0;

                if (specialDiscount !== null) {
                    if (specialDiscount.examProfileInclude.length > 0) {
                        specialDiscount.examProfileInclude.forEach(obj => {
                            if (obj.id_profile === parseInt(p.id_profile)) {
                                if (obj.percentage > 0) {
                                    porcentaje_descuento = obj.percentage;
                                    descuentoEspecial = specialDiscount.id_special_discount;
                                }
                                else {
                                    porcentaje_descuento = specialDiscount.percentage;
                                    descuentoEspecial = specialDiscount.id_special_discount;
                                }
                            }
                        });
                    }
                }
                else if (id_company > 0) {
                    if (p.agreement !== null) {
                        let convenio = p.agreement;

                        porcentaje_descuento = convenio.percentage;
                        id_agreement = convenio.id_agreement;
                        id_agreement_test_range = convenio.id_agreement_test_range;
                        contract_number = convenio.contract_number === null ? "" : convenio.contract_number;
                    }
                }

                let price = p.price;

                if (p.is_curve) {
                    if (p.listCurvesQuoter.length > 0) {

                        p.listCurvesQuoter.forEach((obj, i) => {
                            if (i == 0) {
                                price = obj.price;
                            }
                            obj.number_sampling_aux = (i + 1);
                        })
                    }
                }

                listTmpTest.push({
                    id_exam: null,
                    id_profile: p.id_profile,
                    name: p.name,
                    abbreviation: p.abbreviation,
                    code: p.code,
                    id_rate: null,
                    id_price: p.id_price,
                    name_rate: "",
                    price: price,
                    specimen_name: "",
                    id_specimen: null,
                    check_specimen: false,
                    check_forwarded: true,
                    is_campaign: false,
                    is_referenced: false,
                    discount: porcentaje_descuento,
                    id_common_discount: p.id_common_discount,
                    id_special_discount: descuentoEspecial,
                    id_agreement: id_agreement,
                    id_agreement_test_range: id_agreement_test_range,
                    contract_number: contract_number,
                    array_specimens: array_specimens,
                    listIndications: p.listExamIndications,
                    is_urgent: false,
                    is_curve: p.is_curve,
                    curves: p.listCurves,
                    curves_aux: p.listCurvesQuoter,
                    listExams: p.listExams,
                    config_iva: p.config_iva,
                    value_iva: p.value_iva,
                    is_quotation: true,
                    id_profile_curve_price: (p.is_curve ? (p.listCurvesQuoter.length > 0 ? p.listCurvesQuoter[0].id_profile_curve_price : 0) : 0)
                });
            });


            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: listTmpTest,
                id_quoter: id_quoter
            });

            toggleModalQuoter();
            setLoadingQuoter(false);
        }
        else {
            setLoadingQuoter(false);
        }
    }




    //#endregion
    //#endregion QUOTER

    //#region INVOICING
    //#region INVOICING MODELS
    const [loadingInvoice, setLoadingInvoice] = useState(false);

    const [validationsInvoice, setValidationsInvoice] = useState({
        business_name_valid: false,
        rfc_valid: false,
        phone_valid: false,
        email_valid: false,
        id_use_cfdi_valid: false,
    });
    //#endregion


    //#region INVOICING METHODS
    const handleCreateInvoice = (e) => {
        handleInputChangeWorkOrder(e);

        if (e.target.checked) {
            setModalTitle("Emitir factura");
            setMethodInvoice("create");
            toggleModalInvoice();
        }
        else {
            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                business_name: "",
                rfc: "",
                phone: "",
                email: "",
                id_use_cfdi: 0,
                print_invoice: false,
                send_invoice: false,
            })
        }
    }
    //ENABLE COMmENTS
    const enableComments = () => {
        if (checkComments == false) {
            setcheckComments(true);
            SetShowClassNameC("");
        } else {
            setcheckComments(false);
            SetShowClassNameC("d-none mb-2");
        }
    }

    //ENABLE DISCOUNT
    const enableDiscount = () => {
        if (checkDiscopunt == false) {
            setCheckDiscopunt(true);
            SetShowDiscount("");
        } else {
            setCheckDiscopunt(false);
            SetShowDiscount("d-none mb-2");
        }
    }

    const saveInvoice = () => {
        setLoadingInvoice(true);

        if (validateInvoice()) {
            setLoadingInvoice(false);
            return;
        }

        setLoadingInvoice(false);
        toggleModalInvoice();
    }

    const cancelInvoice = () => {
        toggleModalInvoice();

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            checkInvoice: false
        });
    }

    const validateInvoice = () => {
        let statusValidation = false;

        let newValidations = {};

        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (formWorkOrder.business_name.length < 3) {
            newValidations = {
                ...newValidations,
                business_name_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                business_name_valid: false
            };
        }

        if (formWorkOrder.rfc.length < 12) {
            newValidations = {
                ...newValidations,
                rfc_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                rfc_valid: false
            };
        }

        if (formWorkOrder.phone.length < 10 || formWorkOrder.phone === "") {
            newValidations = {
                ...newValidations,
                phone_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                phone_valid: false
            };
        }

        if (!pattern.test(formWorkOrder.email)) {
            newValidations = {
                ...newValidations,
                email_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                email_valid: false
            };
        }

        if (formWorkOrder.id_use_cfdi === 0) {
            newValidations = {
                ...newValidations,
                id_use_cfdi_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_use_cfdi_valid: false
            };
        }


        setValidationsInvoice(newValidations);

        return statusValidation;
    }
    //#endregion INVOICING METHODS
    //#endregion

    //#region DEVOLUTIONS
    //MODEL
    const [listDevolutions, setListDevolutions] = useState([]);

    const [validateDevolutions, setValidateDevolutions] = useState({
        devolutionValid: false,
        testDevolution: false
    });

    //HANDLE METHODS 
    const handleChangeDevolution = (e) => {
        setDevolutionsW({
            ...devolutionsW,
            id_return_reason: e
        });
    }

    const handleCancelDevolution = () => {

        let _listTest = listTest.map(test => {

            if (test.id_exam === idTestDelete.id_exam && test.id_profile === idTestDelete.id_profile) {
                test.is_canceled = false;
            }

            return test;
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: [..._listTest]
        });

        setValidateDevolutions({
            devolutionValid: false,
            testDevolution: false
        });

        setIdTestDelete({
            id_exam: 0,
            id_profile: 0
        });

        setDevolutionsW({
            id_work_order: 0,
            id_return_reason: 0,
            listTestDevolution: []
        });

        setModalTitle("");
        setLoading(false);
        toggleModalDevolutions();
    }

    //METHODS
    const GetDevolutions = async () => {
        let request = await handleRequest("GET", "ReturnReason/List?is_sample=false&is_work_order=true&is_tracking=false&is_send=false", "Devoluciones");

        if (request !== null && request.length > 0) {
            let _listReasons = [];

            request.forEach(reason => {
                _listReasons.push({
                    value: reason.id_return_reason,
                    label: reason.reason
                });
            });

            setListDevolutions(_listReasons);
        }
        else {
            setListDevolutions([]);
        }
    }

    const saveDevolution = async () => {
        setLoading(true);

        let _take_number_decimals = 2;

        if (validationDevolution()) {
            setLoading(false);
            return;
        }
        //verificar esta para d e a  aqui entro  mi log de los datos l
        let _totalDevolutions = 0;

        devolutionsW.listTestDevolution.forEach(_devolutions => {

            let _totalDev = (_devolutions.is_edited_test ? _devolutions.amount : ((_devolutions.amount + _devolutions.extra)));

            _totalDev = format_trunc_number(_totalDev, _take_number_decimals);

            _totalDev = ((_totalDev - _devolutions.discount) + _devolutions.iva);
            _totalDev = format_trunc_number(_totalDev, _take_number_decimals);

            _totalDevolutions += _totalDev;
        });

        _totalDevolutions = Math.round(_totalDevolutions); //90
        let _devolitionsAmount = _totalDevolutions;

        let _listPayMethods = listPayMethods.map(_payment => {
            let _amountPayment = _payment.amount !== "" ? parseFloat(_payment.amount) : 0;

            if (_payment.name === "CREDITO") {

                if (_devolitionsAmount >= _amountPayment) {
                    _devolitionsAmount -= _amountPayment;

                    _payment.amount = 0;
                    _payment.deleted = true;
                }
                else {
                    _amountPayment -= _devolitionsAmount;
                    _devolitionsAmount = 0;
                    _payment.amount = _amountPayment;
                    _payment.is_edited = true;
                }
            }

            return _payment;
        });

        if (_devolitionsAmount > 0) {
            _listPayMethods.forEach(_paymethod => {
                let _amountPayment = _paymethod.amount !== "" ? parseFloat(_paymethod.amount) : 0;

                if (_paymethod.name === "EFECTIVO") {
                    if (_devolitionsAmount >= _amountPayment) {
                        _paymethod.amount = 0;
                        _paymethod.deleted = true;
                    }
                    else {
                        _amountPayment -= _devolitionsAmount;
                        _devolitionsAmount = 0;

                        _paymethod.amount = _amountPayment;
                        _paymethod.is_edited = true;
                    }
                }
            });
        }

        _totalDevolutions = Math.round(format_trunc_number(_totalDevolutions, _take_number_decimals));
        ////console.log(_totalDevolutions);

        devolutionsW.listTestDevolution.forEach(_devolutions => {

            let _priceWithOutIva = _totalDevolutions / 1.16;
            _priceWithOutIva = format_trunc_number(_priceWithOutIva, _take_number_decimals);
            ////console.log(_priceWithOutIva);

            let _priceTax = (_priceWithOutIva * 16) / 100;
            _priceTax = format_trunc_number(_priceTax, _take_number_decimals);

            _devolutions.amount = _priceWithOutIva;
            _devolutions.extra = 0;
            _devolutions.discount = 0;
            _devolutions.iva = _priceTax;
        });

        let _filterDevolutions = listPayMethods.map(_payment => {
            if (_payment.id_work_order_pay !== undefined) {
                return _payment;
            }
        })

        let mData = {
            listDevolutions: {
                id_return_reason: devolutionsW.id_return_reason.value,
                id_work_order: formWorkOrder.id_work_order,
                listTestDevolution: devolutionsW.listTestDevolution,
                id_branch: id_branch
            },
            listPayments: _filterDevolutions
        };


        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listPayMethods: [..._listPayMethods]
        });

        const response = await handleRequest("PUT", "WorkOrder/SaveDevolution", "Guardar devolución", mData);

        if (response !== null) {
            createSweet("create", "success", "Exito!", "Devolucion realizada con exito!");

            setDevolutionsW({
                id_work_order: 0,
                id_return_reason: 0,
                listTestDevolution: []
            });

            setValidateDevolutions({
                devolutionValid: false,
                testDevolution: false
            });

            setLoading(false);
            setModalTitle("");
            setIdTestDelete({
                id_exam: 0,
                id_profile: 0
            });
            toggleModalDevolutions();

            handleGetUpdateWorkOrder(idWorkOrder, id_commercial_line);
        }

        setLoading(false);
    }

    //#endregion

    //#region PAYMENT BILLS
    //#region PAYMENT BILLS MODEL   
    const [formPaymentBills, handleInputChangePaymentBills, resetPaymentBills, handleUpdateValuesPaymentBills] = useForm({
        id_method_liquided: 0,
        amount_liquided: 0.0,
        id_method_pay: 1,
        amount_liq: 0.0,
    });

    const {
        id_method_liquided,
        amount_liquided,
        id_method_pay,
        amount_liq,
    } = formPaymentBills;

    const [validationsPaymentBills, setValidationsPaymentBills] = useState({
        id_method_pay_valid: false,
        amount_liquided_valid: false
    });
    //#endregion

    //#region METHODS
    //HANDLE METHODS
    const handlePayBill = (id_work_order_pay, amount) => {
        console.log('amount: ', amount)
        handleUpdateValuesPaymentBills({
            ...formPaymentBills,
            id_method_liquided: id_work_order_pay,
            amount_liq: amount
        });

        toggleModalPayBills();
    }


    //POST METHODS
    const savePaymentBill = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validationPaymentBills()) {
            setLoading(false);
            return;
        }

        const discountedAmount = amount_liq - (amount_liq * (percentageDiscount / 100));

        // Actualizar la lista de pagos con el nuevo amount si hay descuento
        let _listPay = listPayMethods.map(payment => {
            if (percentageDiscount > 0) {
                return {
                    ...payment,
                    amount: amount_liq,
                };
            }
            return payment;
        });

        let body = {
            id_work_order_pay: id_method_liquided,
            id_method_pay: id_method_pay,
            id_branch: id_branch,
            amount_liquided: amount_liquided,
            amount_liq: amount_liq,
            //amount: discountedAmount,
        };

        const request = await handleRequest("PUT", "WorkOrder/LiquidedWorkOrder", "Liquidar adeudo", body);
        if (request !== null) {
            console.log('listPayMethods:', listPayMethods);
            console.log('_listPay:', _listPay);
            console.log('body:', body);

            // Actualizar la lista de métodos de pago si hay descuento
            let _listPayMethodsW = listPayMethods.map(payment => {
                if (payment.id_work_order_pay === id_method_liquided) {

                    payment.id_pay_method = id_method_pay;
                    payment.fully_paid = true;

                }
                return payment;
            });

            console.log('listPayMethods antes de actualizar:', listPayMethods);
            console.log('_listPayMethodsW:', _listPayMethodsW);

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listPayMethods: _listPayMethodsW
            });
            createSweet("create", "success", "Exito!", "Se liquido correctamente la orden");
            setLoading(false);
            // toggleModalPayBills();

        }

        setTimeout(() => {
            toggleModalPayBills();
            handleGetUpdateWorkOrder(id_work_order, id_commercial_line);

        }, 300);
        setLoading(false);
        //  window.location.reload()
    };

    //#endregion


    //#endregion

    //#region CANCEL WORK ORDER UPDATE

    //#region CANCEL WORK ORDER MODELS
    const [totalCancellationWorkOrder, setTotalCancellationWorkOrder] = useState(0.0);

    const [formCancelWorkOrder, handleInputChangeCancelWorkOrder, resetCancelWorkOrder, handleUpdateValuesCancelWorkOrder] = useForm({
        user_authorize: "",
        password_authorize: "",
        id_return_reason_woc: "",
        percentage_return: ""
    });

    const {
        user_authorize, password_authorize, id_return_reason_woc, percentage_return
    } = formCancelWorkOrder;

    //#endregion

    //#region VALIDATIONS WORK ORDER CANCELL
    const [validationsCancelWorkOrder, setValidationsCancelWorkOrder] = useState({
        user_authorize_valid: false,
        password_authorize_valid: false,
        id_return_reason_cancel_valid: false,
        percentage_return_cancel_valid: false
    });
    //#endregion

    //#region CANCEL WORK ORDER METHODS
    const handleCancelWorkOrderUpdate = () => {

        GetDevolutions();
        toggleModalCancellWorkOrderUpdate();
    }

    const handleValidateReturnAmount = (e) => {
        handleInputChangeCancelWorkOrder(e);
        let percentage = e.target.value;
        let quantityPercentage = 0;
        let totalCancellations = 0;

        if (percentage > 0) {
            quantityPercentage = ((percentage * totalW) / 100);
            totalCancellations = quantityPercentage.toFixed(2);
        }
        else {
            quantityPercentage = 0;
            totalCancellations = 0.0;
        }

        setTotalCancellationWorkOrder(totalCancellations);
    }

    const saveCancelWorkOrderUpdate = async () => {
        setLoading(true);

        if (validationCancelWorkOrder()) {

            setLoading(false);
            return;
        }

        let body = {
            id_work_order: formWorkOrder.id_work_order,
            user_autorize: formCancelWorkOrder.user_authorize,
            password_autorize: formCancelWorkOrder.password_authorize,
            id_return_reason_woc: formCancelWorkOrder.id_return_reason_woc,
            percentage_return: formCancelWorkOrder.percentage_return,
            total_devolution: totalCancellationWorkOrder
        };

        const response = await handleRequest("PUT", "WorkOrder/CancelWorkOrder", "Cancelar admisión", body);

        if (response !== null) {
            setLoading(false);

            createSweet("create", "success", "Exito!", "Admisión eliminada con exito!");

            setTimeout(() => {
                window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`
            }, 1000);
        }
        else {
            setLoading(false);
        }
    }
    //#endregion

    //#endregion

    //*MODAL UPDATE PAY METHOD
    const [modalPreviewUpdate, setModalPreviewUpdate] = useState(false);

    const toggleModalUpdate = () => {
        setModalPreviewUpdate(!modalPreviewUpdate);
    }

    //*FORM PAYMETHOD

    const [formQuery, handleInputChangeMethod, reset, handleUpdateValuesMethod] = useForm({
        user_method: "",
        user_password_method: "",
        id_pay_method_method: 0
    });

    const {
        user_method, user_password_method, id_pay_method_method
    } = formQuery;


    //*ACTIVE MODAL PAY METHOD
    const handleUpdateMethod = () => {

        setModalTitle("Actualizar metódo de pago");
        toggleModalUpdate();

    }

    //*SAVE METHOD
    const saveUpdateMethod = async () => {
        setLoading(true);


        let _body = {
            id_work_order: formWorkOrder.id_work_order,
            user_autorize: formQuery.user_method,
            password_autorize: formQuery.user_password_method,
            id_pay_method: formQuery.id_pay_method_method,
        };


        const response = await handleRequest("PUT", "WorkOrder/UpdatePayMethod", "Actualizar Metodos", _body);

        if (response !== null) {
            setLoading(false);

            createSweet("create", "success", "Exito!", "Método de Pago actualizado");
            handleGetUpdateWorkOrder(id_work_order, id_commercial_line);

            handleUpdateValuesMethod({
                user_method: "",
                user_password_method: "",
                id_pay_method_method: 0
            })
        }

        setTimeout(() => {
            toggleModalUpdate();
        }, 2000);

    }



    //GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null, isMultipartForm = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            if (isMultipartForm !== null && isMultipartForm) {
                requestOptions = {
                    ...requestOptions,
                    body: body
                }
            }
            else {
                requestOptions = {
                    ...requestOptions,
                    body: JSON.stringify(body)
                };
            }
        }

        const response = await sendRequest(requestOptions, methodUrl, ((isMultipartForm !== null && isMultipartForm) ? "multipart/form-data" : "application/json"));

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            if (methodUrl = 'Patient/UpdateShort') {
                createSweet("warning", "warning", nameMethod, "Verifica que los campos requeridos estén correctos")
            }
            else {
                createSweet("error", "error", nameMethod, nameMethod, "Ocurrio un error en el servidor");
            }

        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    const number_format = (cantidad, decimals) => {
        cantidad += '';
        cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

        decimals = decimals || 0;

        if (isNaN(cantidad) || cantidad === 0) {
            return parseFloat(0).toFixed(decimals);
        }

        cantidad = '' + cantidad.toFixed(decimals);

        let cantidad_parts = cantidad.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(cantidad_parts[0]))
            cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

        return cantidad_parts.join('.');
    }

    //#region ALL VALIDATIONS
    const validArrayCurva = () => {

        let statusValidacion = false;

        if (formWorkOrder.listTest.length >= 1) {

            formWorkOrder.listTest.forEach(element => {
                if (element.is_curve) {
                    element.curves_aux.forEach(l => {
                        if (l.time === "") {
                            statusValidacion = true;
                            return;

                        }

                    });

                }
            });

        }
        else {
            statusValidacion = true;
        }
        return statusValidacion;
    }

    const validArrayCurva1 = () => {

        let statusValidacion = false;

        if (formWorkOrder.listTest.length >= 1) {

            formWorkOrder.listTest.forEach(element => {

                if (element.is_curve) {
                    if (element.price === 0) {
                        statusValidacion = true;
                        return;
                    }

                }
            });

        }
        else {
            statusValidacion = true;
        }
        return statusValidacion;
    }

    const validationTest = (id_exam, id_profile = null, listExams = [], name_profile = "") => {

        let statusValidation = -1;
        let newValidations = {};
        let valid_exist_test = {
            name_profile: "",
            repeat_profiles: [],
            repeat_exams: []
        };

        if (formWorkOrder.listTest.length > 0) {
            let findTest = formWorkOrder.listTest.find(x => (id_exam !== null ? x.id_exam === parseInt(id_exam) : x.id_profile === parseInt(id_profile)));

            if (findTest) {
                newValidations = {
                    ...newValidations,
                    valid_repeat_test: true,
                    message_validation: `${id_exam !== null ? 'El examen: ' + findTest.name + ', ya existe' : 'El perfil: ' + findTest.name + ', ya existe'}`
                };

                statusValidation = 0;
            }
            else {
                //VALIDATION REMOVED, FROM CLIENTS
                // if (id_exam !== null) {
                //     let listProfiles = formWorkOrder.listTest.filter(x => x.id_profile !== null);

                //     listProfiles.forEach(p => {

                //         if (p.listExams.find(x => x.id_exam === parseInt(id_exam))) {

                //             newValidations = {
                //                 message_validation: "El examen seleccionado ya existe en el perfil: " + p.name
                //             };
                //             statusValidation = 1;

                //             return true;
                //         }
                //     })
                // }
                // else if (id_exam === null && id_profile !== null) {
                if (id_exam === null && id_profile !== null) {
                    let filterProfiles = formWorkOrder.listTest.filter(x => x.id_profile !== null);

                    filterProfiles.forEach(p => {
                        let examsRepeat = [];
                        let examsNotRepeat = [];

                        p.listExams.forEach(e => {

                            if (listExams.find(x => x.id_exam === e.id_exam)) {
                                examsRepeat.push(e);
                            }
                        });

                        let notExist = [];
                        listExams.forEach(x => {
                            if (!examsRepeat.find(r => r.id_exam === x.id_exam)) {
                                notExist.push(x);
                            }
                        });

                        examsNotRepeat = notExist;

                        if (examsRepeat.length > 0) {
                            valid_exist_test.repeat_profiles.push({
                                name_profile: p.name,
                                listExamsRepeat: examsRepeat,
                                listExamsNotRepeat: examsNotRepeat
                            });
                        }

                        if (valid_exist_test.repeat_profiles.length > 0 || valid_exist_test.repeat_exams.length > 0) {
                            valid_exist_test.name_profile = name_profile;
                            newValidations = {
                                message_validation: "Hay examenes o perfiles repetidos"
                            };

                            statusValidation = 2;
                        }
                    });

                    if (valid_exist_test.repeat_profiles.length > 0 || valid_exist_test.repeat_exams.length > 0) {
                        valid_exist_test.name_profile = name_profile;
                        newValidations = {
                            message_validation: "Hay examenes o perfiles repetidos"
                        };

                        statusValidation = 2;
                    }

                    // let listExamsW = formWorkOrder.listTest.filter(x => x.id_exam !== null);

                    // listExamsW.forEach(e => {
                    //     if (listExams.find(x => x.id_exam === parseInt(e.id_exam))) {

                    //         newValidations = {
                    //             message_validation: "Tienes exámenes duplicados: " + e.name + ", al seleccionar el perfil"
                    //         };

                    //         statusValidation = 3;

                    //         return true;
                    //     }
                    // });
                }
            }
        }

        setValidationsTest(newValidations);
        setValidationsRepeat({
            ...validationsRepeat,
            name_profile: valid_exist_test.name_profile,
            repeat_profiles: valid_exist_test.repeat_profiles,
            repeat_exams: valid_exist_test.repeat_exams
        });

        console.log(valid_exist_test);

        return statusValidation;
    }

    const validationQuoter = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formQuoter.code_quoter === "" && formQuoter.date_quoter === "") {
            newValidations = {
                ...newValidations,
                code_quoter_valid: true,
                date_quoter_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                code_quoter_valid: false,
                date_quoter_valid: false
            };
        }

        setValidationsQuoter(newValidations);
        return statusValidation;
    }

    const validationWorkOrder = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formWorkOrder.id_patient === 0) {
            newValidations = {
                ...newValidations,
                id_patient_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_patient_valid: false
            };
        }

        if (formWorkOrder.id_printer_point.value === undefined) {
            newValidations = {
                ...newValidations,
                id_printer_point_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_printer_point_valid: false
            };
        }

        // if(formWorkOrder.id_work_order_type_service.value === undefined){
        //     newValidations =  {
        //         ...newValidations,
        //         id_work_order_type_service_valid: true
        //     };
        //     statusValidation = true;
        // }else{
        //     newValidations= {
        //         ...newValidations,
        //         id_work_order_type_service_valid: false
        //     }
        // }

        if (formWorkOrder.listTest.length === 0) {
            newValidations = {
                ...newValidations,
                listTest_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                listTest_valid: false
            };
        }

        if (formWorkOrder.listPayMethods.length > 0) {

            formWorkOrder.listPayMethods.forEach(obj => {
                if (obj.id_pay_method === "0" || obj.id_pay_method === "") {

                    newValidations = {
                        ...newValidations,
                        listPayMethods_valid: true
                    };

                    statusValidation = true;

                    return;
                }

                if (obj.amount < 0 || obj.amount === "") {
                    newValidations = {
                        ...newValidations,
                        listPayMethods_valid: true
                    };

                    statusValidation = true;

                    return;
                }
            })
        }
        else {
            newValidations = {
                ...newValidations,
                listPayMethods_valid: true
            };

            statusValidation = true;
        }

        if (checkTypeClient !== 1) {
            if (formWorkOrder.id_company === 0) {
                newValidations = {
                    ...newValidations,
                    id_company_valid: true
                };
                statusValidation = true;
            } else {
                newValidations = {
                    ...newValidations,
                    id_company_valid: false
                };
            }
        }

        setValidationsWorkOrder(newValidations);

        return statusValidation;
    }

    const validationSocialGender = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formSocialGender.name_genderc === "") {
            newValidations = {
                ...newValidations,
                name_genderc_valid: true,
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_genderc_valid: false,
            };
        }

        setvalidationsSG(newValidations);
        return statusValidation;
    }

    const validationsPets = () => {
        let _statusValidation = false;
        let _newValidations = {};

        if (formValuesPet.pet.length < 2) {
            _newValidations = {
                ..._newValidations,
                pet_valid: true
            }
            _statusValidation = true
        } else {
            _newValidations = {
                ..._newValidations = {
                    ..._newValidations,
                    pet_valid: false
                }
            }
        }

        if (formValuesPet.sex === 0) {
            _newValidations = {
                ..._newValidations,
                sex: true
            };
            _statusValidation = true
        } else {
            _newValidations = {
                ..._newValidations,
                sex: false
            };
        }

        if (formValuesPet.birthday === "") {
            _newValidations = {
                ..._newValidations,
                birthday_valid: true
            };
            _statusValidation = true;
        } else if (formValuesPet.birthday !== "") {
            let _stringBirthday = "";
            if (formValuesPet.birthday.length === 10) {
                let _arrBirthday = formValuesPet.birthday.split('/');
                _stringBirthday = `${_arrBirthday[2]}-${_arrBirthday[1]}-${_arrBirthday[0]}`;
                let _validate_date = moment(_stringBirthday).format("YYYY-MM-DD");

                if (_validate_date === "Fecha inválida") {
                    _newValidations = {
                        ..._newValidations,
                        birthday_valid: true
                    }
                    _statusValidation = true;
                } else {
                    let _actualDate = new Date();
                    let _newDate = new Date(_validate_date);

                    if (_actualDate < _newDate) {
                        _newValidations = {
                            ..._newValidations,
                            birthday_valid: true
                        };
                        _statusValidation = true;
                    } else {
                        _newValidations = {
                            ..._newValidations,
                            birthday_valid: false
                        }
                    }
                }
            }
        }

        if (formValuesPet.id_pet_type === 0) {
            _newValidations = {
                ..._newValidations,
                id_pet_type_valid: true
            }
            _statusValidation = true;
        } else {
            _newValidations = {
                ..._newValidations,
                id_pet_type_valid: false
            };
        }

        if (formValuesPet.id_pet_specie === 0) {
            _newValidations = {
                ..._newValidations,
                id_pet_specie_valid: true
            }
            _statusValidation = true;
        } else {
            _newValidations = {
                ..._newValidations,
                id_pet_specie_valid: false
            }
        }

        setValidationsPet(_newValidations);
        return _statusValidation;
    }

    const validationsPatientForm = () => {
        let _statusValidation = false;
        let _newValidations = {};
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (formValuesPatient.name.length < 2) {
            _newValidations = {
                ..._newValidations,
                name_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                name_valid: false
            };
        }

        if (formValuesPatient.paternal_surname.length < 2) {
            _newValidations = {
                ..._newValidations,
                paternal_surname_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                paternal_surname_valid: false
            };
        }

        if (formValuesPatient.birthday === "") {
            _newValidations = {
                ..._newValidations,
                birthday_valid: true
            };

            _statusValidation = true;
        }
        else if (formValuesPatient.birthday !== "") {

            let _stringBirthday = "";

            if (formValuesPatient.birthday.length === 10) {
                let _arrBirthday = formValuesPatient.birthday.split('/');

                _stringBirthday = `${_arrBirthday[2]}-${_arrBirthday[1]}-${_arrBirthday[0]}`;

                let _validate_date = moment(_stringBirthday).format("YYYY-MM-DD");

                if (_validate_date === "Fecha inválida") {
                    _newValidations = {
                        ..._newValidations,
                        birthday_valid: true
                    };

                    _statusValidation = true;
                }
                else {
                    let _actualDate = new Date();
                    let _newDate = new Date(_validate_date);

                    if (_actualDate < _newDate) {
                        _newValidations = {
                            ..._newValidations,
                            birthday_valid: true
                        };

                        _statusValidation = true;
                    }
                    else {
                        _newValidations = {
                            ..._newValidations,
                            birthday_valid: false
                        };
                    }
                }
            }
            else {
                _newValidations = {
                    ..._newValidations,
                    birthday_valid: true
                };
                _statusValidation = true;
            }
        }



        if (formValuesPatient.id_state.value === undefined || formValuesPatient.id_state.value === null) {
            _newValidations = {
                ..._newValidations,
                state_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                state_valid: false
            };
        }

        if (formValuesPatient.id_municipality.value === undefined || formValuesPatient.id_state.value === null) {
            _newValidations = {
                ..._newValidations,
                municipality_valid: true
            }

            _statusValidation = true;

        } else {
            _newValidations = {
                ..._newValidations,
                municipality_valid: false
            }
        }

        if (formValuesPatient.phone === "") {

            _newValidations = {
                ..._newValidations,
                phone_valid: true
            }

            _statusValidation = true;

        } else {
            _newValidations = {
                ..._newValidations,
                phone_valid: false
            }
        }

        if (formValuesPatient.id_gender === 0) {
            _newValidations = {
                ..._newValidations,
                id_gender_valid: true
            };

            _statusValidation = true
        }
        else {
            _newValidations = {
                ..._newValidations,
                id_gender_valid: false
            };
        }

        if (formValuesPatient.curp.length < 10) {
            _newValidations = {
                ..._newValidations,
                curp_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                curp_valid: false
            };
        }

        if (formValuesPatient.cp === "" || formValuesPatient.cp !== "" && (formValuesPatient.cp === 0 || formValuesPatient.cp === "0")) {
            _newValidations = {
                ..._newValidations,
                cp_patient_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                cp_patient_valid: false
            };
        }

        let _trimEmailPatient = (formValuesPatient.email !== "" && formValuesPatient.email !== null ? formValuesPatient.email.trim() : "");

        if (!pattern.test(_trimEmailPatient)) {
            _newValidations = {
                ..._newValidations,
                email_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                email_valid: false
            };
        }

        if (isInvoiceService) {
            if ((formValuesPatient.type_person == 1 && formValuesPatient.rfc.length < 12) || (formValuesPatient.type_person === 2 && formValuesPatient.rfc.length < 11)) {
                _newValidations = {
                    ..._newValidations,
                    rfc_valid: true
                };

                _statusValidation = true;
            }
            else {
                _newValidations = {
                    ..._newValidations,
                    rfc_valid: false
                };
            }

            if (formValuesPatient.id_tax_regime.value == undefined) {
                _newValidations = {
                    ..._newValidations,
                    id_tax_regime_valid: true
                };

                _statusValidation = true;
            }
            else {
                _newValidations = {
                    ..._newValidations,
                    id_tax_regime_valid: false
                };
            }

            if (formValuesPatient.id_use_cfdi.value == undefined) {
                _newValidations = {
                    ..._newValidations,
                    id_use_cfdi_valid: true
                };

                _statusValidation = true;
            }
            else {
                _newValidations = {
                    ..._newValidations,
                    id_use_cfdi_valid: false
                }
            }
        }

        //console.table('Validaciones: ', _newValidations);

        setValidationsPatient(_newValidations);

        return _statusValidation;
    }

    const validationDevolution = () => {
        let statusValidation = false;
        let newValidations = {};

        if (devolutionsW.id_return_reason <= 0 || devolutionsW.id_return_reason === null) {
            newValidations = {
                ...newValidations,
                devolutionValid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                devolutionValid: false
            };
        }

        if (devolutionsW.listTestDevolution.length === 0) {
            newValidations = {
                ...newValidations,
                testDevolution: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                testDevolution: false
            };
        }

        setValidateDevolutions(newValidations);

        return statusValidation;
    }

    const validationPaymentBills = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formPaymentBills.id_method_pay === "" || formPaymentBills.id_method_pay === 0) {
            newValidations = {
                ...newValidations,
                id_method_pay_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_method_pay_valid: false
            }
        }

        if (formPaymentBills.amount_liquided === "" || formPaymentBills.amount_liquided === 0) {
            newValidations = {
                ...newValidations,
                amount_liquided_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                amount_liquided_valid: false
            };
        }

        setValidationsPaymentBills(newValidations);

        return statusValidation;
    }

    const validationCancelWorkOrder = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formCancelWorkOrder.user_authorize === "") {
            newValidations = {
                ...newValidations,
                user_authorize_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                user_authorize_valid: false
            };
        }

        if (formCancelWorkOrder.password_authorize === "") {
            newValidations = {
                ...newValidations,
                password_authorize_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                password_authorize_valid: false
            };
        }

        if (formCancelWorkOrder.id_return_reason_woc === 0) {
            newValidations = {
                ...newValidations,
                id_return_reason_cancel_valid: true
            };

            statusValidation = statusValidation;
        }
        else {
            newValidations = {
                ...newValidations,
                id_return_reason_cancel_valid: false
            };
        }

        if (formCancelWorkOrder.percentage_return === "" || (parseFloat(formCancelWorkOrder.percentage_return) <= 0) || (parseFloat(formCancelWorkOrder.percentage_return) > 100)) {
            newValidations = {
                ...newValidations,
                percentage_return_cancel_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                percentage_return_cancel_valid: false
            };
        }

        setValidationsCancelWorkOrder(newValidations);

        return statusValidation;
    }
    //#endregion

    //#region SCANEO INE
    const setScan_ine_check = async () => {
        if (formValuesPatient.scan_ine_check == true) {
            handleUpdateValuesPatient({
                ...formValuesPatient,
                scan_ine_check: false,
                setFileINE: "",
            });
            setshowClassINE({
                ClassNameINE: "d-none",
                NameFile: ""
            });
        } else if (formValuesPatient.scan_ine_check == false) {
            handleUpdateValuesPatient({
                ...formValuesPatient,
                scan_ine_check: true,
            });
        }


    }

    const [showClassINE, setshowClassINE] = useState({
        ClassNameINE: "d-none",
        NameFile: ""
    });

    const DeleteINE = (e) => {
        handleUpdateValuesPatient({
            ...formValuesPatient,
            setFileINE: "",
        });
        setshowClassINE({
            ClassNameINE: "d-none",
            NameFile: ""
        });
    };

    const handleOnChangeINE = async (e) => {
        handleUpdateValuesPatient({
            ...formValuesPatient,
            setFileINE: e.target.files[0],
        });
        setshowClassINE({
            ClassNameINE: "f-w-600 f-12",
            NameFile: e.target.files[0].name
        });
        var formData = new FormData();
        formData.append('rutaImagen', e.target.files[0]);
        let requestOptions = {
            method: 'POST',
            body: formData
        };
        const respuesta = await sendRequest(requestOptions, "OCR/prueba", 'multipart/form-data');


        if (respuesta !== null) {
            handleUpdateValuesPatient({
                ...formValuesPatient,
                curp: respuesta.data.curp,
                paternal_surname: respuesta.data.paternal_surname,
                maternal_surname: respuesta.data.maternal_surname,
                name: respuesta.data.name,
                setFileINE: respuesta.data.name_ine,
                birthday: respuesta.data.birthday,
                age: respuesta.data.age
            });
        }
        else {

        }

        handleUpdateValuesPatient({
            ...formValuesPatient,
            curp: respuesta.data.curp,
            paternal_surname: respuesta.data.paternal_surname,
            maternal_surname: respuesta.data.maternal_surname,
            name: respuesta.data.name,
            setFileINE: respuesta.data.name_ine,
            birthday: respuesta.data.birthday,
            age: respuesta.data.age
        });
        handleUpdateValuesPatient({
            ...formValuesPatient,
            setFileINE: e.target.files[0],
        });
        setshowClassINE({
            ClassNameINE: "f-w-600 f-12",
            NameFile: e.target.files[0].name
        });

    }

    const handleOnChangeINEU = async (e) => {

        handleUpdateValuesPatient({
            ...formValuesPatient,
            setFileINE: e.target.files[0],
            img64File: ""
        });

        setshowClassINE({
            ClassNameINE: "f-w-600 f-12",
            NameFile: e.target.files[0].name
        });


    }

    useEffect(() => {
        if (informationPatient !== null) {
            if (formWorkOrder.checkEmail === true) {
                if (informationPatient.email === "") {
                    createSweet("create", "warning", "Advertencia", "El paciente no cuenta con correo");
                }
            }
        }


        if (checkDoctor === true) {

            if (informationDoctor.length > 1) {
                informationDoctor.forEach(_find => {
                    if (_find.email === null || _find.email === "") {
                        createSweet("create", "warning", "Advertencia", "Uno o más doctores no cuentan con correo");
                    }
                });
            }
            else {
                informationDoctor.forEach(_find => {
                    if (_find.email === null || _find.email === "") {
                        createSweet("create", "warning", "Advertencia", "El doctor no cuenta con correo");
                    }
                });
            }

        }


    })

    //#endregion SCANEO INE
    return {
        //veterinary
        toggleTab, activeTab, pets, getPetType, type, listPetType, validationsPet, handleWheelPet,
        handleInputChangePet, handleDatePet, formValuesPet, isPetsTabLocked,
        handleCreatePet, 
        listPetSpecie, listPetBreed,
        handleChangePetType, handleChangePetSpecie, handleChangePetBreed, 
        saveCreatePet,
        //getBirthday,
        modalTitle, number_format,

        //GLOBAL VARIABLES
        isEditWorkOrder, loadingEditWorkOrder,

        //#region WORK ORDER
        //VALUES
        checkTypeClient,
        listPrinterPoint, listPayMethod, arrayAgreementDiscounts, listType,
        id_patient, id_company, id_printer_point, id_work_order_type_service,
        observations, observations_sample, observations_general, checkPrint, checkPartial, checkEmail, checkWhats,
        checkDoctor, checkInvoice, listTest, listPayMethods, listDoctors, listTypeServices,
        listCompanies, listAgreements,
        times, loading,
        searchExamProfileState, subTotalW, totalDiscountW, totalTaxesW, totalW, totalDevolutions,
        showMessageW, showMessageWF, showClassSD, showClass, OrderMedicalEstatus, viewExamsProfile, validationsWorkOrder, viewWorkOrder,
        enableBtnSaveWorkOrder, validationsRepeat, checkComments, ShowClassNameC, ShowDiscount, checkDiscopunt,
        devolutionsW, isPayForCompany, requiredInvoiceCompany, availableInvoicePatient,
        searchExtra, idAgreementCompany,

        //FILTER TYPE SEARCH
        typeSearch, typeNumber,

        //FILES PATIENT
        medical_order, handleOnChange, DeleteFile,

        //METHODS
        handleChangeTypeClient, handleInputChangeWorkOrder, handleDinamicInputWorkOrder, handleSelectValuesWorkOrder,
        handleSelectExamChange, changeSelectSamplings, changeValueTimeSampling,
        removeExamProfile, handleCheckUrgent, handleCheckSpecimen, handleCheckForwarded, handleAddPayMethodInputs, handleGiveChange,
        handleDeletePayMethod, handleViewExamsProfile, saveWorkOrder, cancellWorkOrder,
        handleChangeTypeFilterTest, saveUpdateWorkOrder, handleChangeExternalLaboratories,
        handleChangeTypeFilter, handleChangeGeneratePay, handleChangeClient, handleChangeAgreement,
        // handleSelectSupplies,
        //MODALS WORK ORDER
        toggleModalViewExams, modalViewExamsP, modalRepeatProfile, toggleRepeatProfile,
        toggleModalDevolutions, modalDevolutions,
        //#endregion

        //#region PATIENT
        //VALUES
        formValuesPatient, listBloodType, formSocialGender,
        searchPatientState, informationPatient, listStates, listMunicipalities, listTaxRegime, listUseCfdiPatient,
        listTypeService, modalPatient, togglePatient,
        methodPatient, loadingPatient,
        handleInputChangePatient, handleInputChangeSocialGender, handleSelectValuesPatient,
        handlePickerValuesPatient, validationsPatient, validationsSG,
        toggleModalScannQr, modalScannQrPatient, focusQrPatient, inputReadQrCode, Listsocialgender, modalSocialGender,
        isInvoiceService, setIsInvoiceService,
        viewNewSocialGender, setViewNewSocialGender, showDiscountWarning, showDiscountWarningCredit, showDiscountWarningPay,
        // listSupplies,
        //METHODS
        handleChangePatient,
        handleChangeStatePatient, handleDatePatient, handleChangeTypeService,
        handleCreatePatient, saveCreatePatient,
        handleUpdatePatient, saveUpdatePatient,
        handleScannQrPatient, toggleModalSocialGender, saveSocialGender,
        handleChangeTypePerson, handleChangeTaxRegime, handleChangeSocialGender,
        //#endregion

        //#region DOCTOR
        //VALUES
        formDoctor, informationDoctor, doctList,
        modalDoctor, toggleDoctor,
        methodDoctor, loadingDoctor,
        handleInputChangeDoctor, nameInputsDoctor, validationsDoctor,
        handleChangeDoctor, specialties, searchDoctorState,
        id_specialty, name_doctor, professional_license, phone_doctor,
        address_doctor, email_doctor, name_specialty, isCommercialPartner,
        id_work_order, workOrderId, isFullyPaid,
        //METHODS
        handleCreateDoctor, saveCreateDoctor, handleDeleteDoctor,
        handleUpdateDoctor, saveUpdateDoctor, validaciones1, handleSaveDoctor,
        //#endregion

        //#region SPECIAL DISCOUNTS
        //VALUES
        specialDiscount, special_discount_code, handleInputChangeSpecialDiscount,

        //SPECIAL DISCOUNTS METHODS
        handleModalSpecialDiscount, validateCodeSpecialDiscount,
        //MODALS SPECIAL DISCOUNTS
        modalSpecialDiscounts, toggleSpecialDiscount,
        //#endregion

        //#region COMMON DISCOUNTS
        //VALUES
        arrayCommonDiscounts, arrayNameDiscount,
        selectedCampaign, listCampaigns,

        //METHODS
        handleModalListCommonDiscounts, handleModalSocialGender,
        clearDiscount, handleChangeCampaign, removeExamProfileByCampaign,
        // removeSupplies,
        //MODALS COMMON DISCOUNTS
        modalCommonDiscount, toggleCommonDiscount,
        //#endregion

        //#region QUOTER
        //VALUES
        loadingQuoter, code_quoter, validationsQuoter, date_quoter,
        handleInputChangeQuoter, listQuoter, enableViewTest, viewTestQuoter,

        //METHODS
        handleGetQuoter, handleSearchQuoter, searchQuoterByCode,
        handleViewTest, handleCloseViewTest,

        //MODALS QUOTER
        modalQuoter, toggleModalQuoter, methodQuoter,
        //#endregion

        //#region INVOICE
        //VALUES
        validationsInvoice, business_name, rfc, phone, email, id_use_cfdi, print_invoice, send_invoice, listUseCfdi,
        loadingInvoice,
        //METHODS
        handleCreateInvoice, saveInvoice, cancelInvoice, enableComments, enableDiscount,

        //MODALS INVOICE
        modalInvoice, toggleModalInvoice, methodInvoice,
        //#endregion

        //#region PATIENT QR
        qrCodeAutoFocus, onKeyPressQr,
        user_unique_code, handleSelectValuesQr,
        //#endregion

        //#region INE SCAN
        setScan_ine_check, handleOnChangeINE, DeleteINE, showClassINE, handleOnChangeINEU,
        //#endregion

        //#region DEVOLUTIONS
        listDevolutions,
        handleChangeDevolution, handleCancelDevolution,

        validateDevolutions, saveDevolution,
        //#endregion

        //#region PAYMENT BILLS
        //MODALS
        modalPayBills, toggleModalPayBills,
        //MODELS
        id_method_liquided, amount_liquided, id_method_pay, amount_liq,
        handleInputChangePaymentBills, validationsPaymentBills,

        //METHODS
        handlePayBill, savePaymentBill,
        //#endregion

        //#region CANCELL WORK ORDER UPDATE
        //MODALS
        modalCancelWorkOrderUpdate, toggleModalCancellWorkOrderUpdate,

        //MODELS
        user_authorize, password_authorize, id_return_reason_woc, percentage_return,
        handleInputChangeCancelWorkOrder, totalCancellationWorkOrder,

        //VALIDATIONS
        validationsCancelWorkOrder,

        //METHODS
        handleCancelWorkOrderUpdate, handleValidateReturnAmount, saveCancelWorkOrderUpdate, validatePercentajeSpecialDiscount, percentage,
        //#endregion

        //CALCULO DE EDAD Y FECHA DE NACIMIENTO DE PACIENTE
        getBirthday, getAge, handleWheel,
        //ENDREGION
        validPorcentejeSpecial, disableEdit, validHopitalInterfaced, newDiscount,
        validateCommonDiscountDetail, setValidateCommonDiscountDetail,
        //*MODAL PAY METHOD
        modalPreviewUpdate, toggleModalUpdate, handleUpdateMethod,

        //*UPDATE METHOD
        user_method, user_password_method, id_pay_method_method, handleInputChangeMethod,

        //*SAVE PAY METHOD
        saveUpdateMethod,

        //*VALID BUTTON
        validButton
    }
}
