import React, { useEffect, useState, useRef } from 'react';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';
import { useHistory } from 'react-router-dom';
import { ConfigServer } from '../../data/config';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import * as XLSX from 'xlsx';
import { useModal } from "react-morphing-modal";
import 'moment/locale/es';
import moment from 'moment';
import "react-datetime/css/react-datetime.css";
import { Input, Card, CardBody, Container, Row, Col, Button, FormGroup, Label, List, Table, Badge, CardHeader, Collapse, Form, Tooltip } from 'reactstrap';
import { C } from '@fullcalendar/core/internal-common';




export const useDashboardCard = () => {

    //LOCAL VARIABLES
    let id_branch = localStorage.getItem('branchId');
    let enable_tracking_toma = localStorage.getItem('enable_tracking_toma');
    let credit_payment = localStorage.getItem('credit_payment');
    let validPorcentejeSpecial = localStorage.getItem('url_helen');



    const history = useHistory();
    const [orderTotal, setOrderTotal] = useState(0);
    const [examsAndProfile, setExamsAndProfile] = useState(0);
    const [countTotal, setCountTotal] = useState(0)
    const [nimData, setNimData] = useState([]);
    const [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett, sweetConfirmationUpdate, sweetConfirmRepeatExam] = useSweetAlert();
    const [patientTotal, setPatientTotal] = useState(0);
    const [patientCompany, setPatientCompany] = useState(0);
    const [loading, setLoading] = useState(false)
    const [classT, setClassT] = useState(false);
    const [orderCancell, setOrderCancell] = useState(0);
    //#region pdf individual
    const [typeModalPrintI, setTypeModalPrintI] = useState("viewPrintI");
    const [modalPrintI, setModalPrintI] = useState(false);
    const [dataExams, setdataExams] = useState([]);
    const [id_workorder_print, setid_workorder_print] = useState(0);
    //#endregion pdf individual
    //#region pdf invoice
    const [typeModalInvoice, setTypeModalInvoice] = useState("viewInvoice");
    const [modalInvoice, setModalInvoice] = useState(false);
    const [InvoicePDF, setInvoicePDF] = useState("");
    //#endregion pdf invoice
    const [nimDataExcel, setNimDataExcel] = useState([]);

    //Message
    const [respuestaAPI, setRespuestaAPI] = useState({});


    const [dataCompany, setDataCompany] = useState([]);

    const [dataSend, setDataSend] = useState([]);
    const [sendTo, setSendTo] = useState([]);
    const [id_workorder_send, setid_workorder_send] = useState(0);
    const [commentPatient, setCommentPatient] = useState('');
    const [validinvoice, setvalidInvoice] = useState(false);


    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({
        nim: "",
        mDateRange1: "",
        mDateRange2: "",
        mSelectStatus: 0,
        ids_users: 0,
        id_company: 0,
        is_pending: 0,
        letter: false,

    });

    const { nim, mDateRange1, mDateRange2, mSelectStatus, ids_users, id_company, is_pending, letter } = formValues;

    const [pending, setpending] = useState([
        {
            value: 1,
            label: "Pendientes"
        }
    ])


    const [statusOrder, setstatusOrder] = useState([
        {
            value: 0,
            label: "Sin Estatus"
        },
        {
            value: 1,
            label: "Abierta"
        },
        {
            value: 2,
            label: "En Progreso"
        },

        {
            value: 4,
            label: "Validado"
        },
        {
            value: 5,
            label: "Parcial"
        },
        {
            value: 6,
            label: "Liberado"
        },
        {
            value: 7,
            label: "Enviado"
        },
        {
            value: 8,
            label: "Entregado"
        },
        {
            value: 9,
            label: "Entregado-Enviado"
        },
        {
            value: 10,
            label: "Autorizado"
        },

        {
            value: 13,
            label: "Impreso"
        },
        {
            value: 17,
            label: "Desvalidado"
        },
        {
            value: 20,
            label: "Cancelada"
        },

    ]);


    const [validationsSG, setvalidationsSG] = useState({
        mDateRange1: false,
        mDateRange2: false
    });

    const [validTab, setValidTab] = useState(1);

    const [filterSections, setFilterSections] = useState('TODOS');

    //#region GET DASHBOARD
    const getdashboard = (e, search = true, valid = 0) => {
        e.preventDefault();

        if (search) {

            let query = "";

            let mfrom = (mDateRange1) ? moment(mDateRange1).format("YYYY-MM-DD") : "";
            let mto = (mDateRange2) ? moment(mDateRange2).format("YYYY-MM-DD") : "";

            if (nim !== "") {
                query += `&nim=${nim}`;
            }

            if (mfrom !== "") {
                query += `&date_from=${mfrom}`;
            }

            if (mto !== "") {
                query += `&date_to=${mto}`;
            }

            if (mSelectStatus !== 0) {
                query += `&status_work_order=${mSelectStatus.value}`;
            }

            if (ids_users !== 0) {
                query += `&id_user=${ids_users.value}`;
            }

            if (id_company != 0) {
                query += `&id_company=${id_company.value}`;
            }

            if (valid != 0) {
                query += `&is_pending=${1}`;
            }

            getAmount(true, query)
        }
    }

    useEffect(() => {
        if (nimData.length === 0) {
            getAmount();
        }
    }, []);



    const getAmount = async (search = false, filter = null, first) => {

        let requestOptions = {
            method: 'GET'
        };

        var respuesta = {};
        let respuestaEmail = {};
        const jsonData = localStorage.getItem('dashboard_currentpage');
        let persistence = null;
        if (jsonData) {
            persistence = JSON.parse(jsonData);

            if (persistence.search == true && filter == null) {
                search = true
                setViewFormFilters(false);
                handleUpdateValues({
                    nim: persistence.nim,
                    mDateRange1: persistence.mDateRange1,
                    mDateRange2: persistence.mDateRange2,
                    mSelectStatus: persistence.mSelectStatus,
                    ids_users: persistence.ids_users,
                    id_company: persistence.id_company
                });

                filter = "";
                if (persistence.nim !== "") {
                    filter += `&nim=${persistence.nim}`;
                }

                if (persistence.mDateRange1 !== "") {
                    filter += `&date_from=${persistence.mDateRange1}`;
                }

                if (persistence.mDateRange2 !== "") {
                    filter += `&date_to=${persistence.mDateRange2}`;
                }

                if (persistence.mSelectStatus !== 0) {
                    filter += `&status_work_order=${persistence.mSelectStatus}`;
                }

                if (persistence.ids_users !== 0) {
                    filter += `&id_user=${persistence.ids_users}`;
                }

                if (persistence.id_company != 0) {
                    filter += `&id_company=${persistence.id_company}`;
                }
            }
        }

        if (search === false) {
            respuesta = await sendRequest(requestOptions, `WorkOrder/DetailDashboard${id_branch}`);

            respuestaEmail = respuesta.data.workOrderDetailPatient.filter((order) => order.send_doctor === true)
            setLoading(true);
        }

        else {
            setDatanim([]);
            respuesta = await sendRequest(requestOptions, `WorkOrder/DetailDashboard${id_branch}?` + filter);
        }

        let tmpSend = [];
        let tmpDetail = [];
        let tmpComment = [];
        let tmpCompanies = [];
        let tmpDetailExcel = [];
        let tmpDetailExcel2 = [];

        if (respuesta.code === 200) 
         {
            setLoading(false);

            console.log(respuesta);

            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;

            let idW = 0

            if (respuesta.data.workOrderDetailPatient.length >= 1) {

                respuesta.data.workOrderDetailPatient.forEach((element, index) => {

                    setCommentPatient(element.comment_patient);

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    let validar_faltantes = false;
                    let validador = [];
                    idW = element.id_work_order

                    if (element.listProfileWorkOrders.length > 0) {
                        if (element.listProfileWorkOrders[0].listExam.length > 0) {
                            element.listProfileWorkOrders[0].listExam.forEach(({ check_specimen, id_specimen_origin }) => {
                                if (!check_specimen) {
                                    validar_faltantes = true;
                                }
                                if (validador.find(x => x.id === id_specimen_origin) === undefined) {
                                    validador.push({
                                        id: id_specimen_origin,
                                    })
                                }
                            });
                        }
                    }

                    if (element.listExam.length > 0) {
                        element.listExam.map(({ check_specimen, id_specimen_origin, }) => {
                            if (!check_specimen) {
                                validar_faltantes = true;
                            }
                            if (validador.find(x => x.id === id_specimen_origin) === undefined) {
                                validador.push({
                                    id: id_specimen_origin,
                                })
                            }
                        });
                    }

                    let commentsForOrder = null;

                    if (element.comment_patient && Array.isArray(element.comment_patient)) {
                        commentsForOrder = element.comment_patient.find(comment => comment.id_work_order === idW);
                    } else if (element.comment_patient && !Array.isArray(element.comment_patient)) {
                        commentsForOrder = { comment_patient: element.comment_patient };
                    }

                    tmpComment.push({
                        id_work_order: element.id_work_order,
                        id_patient: element.id_patient,
                        id_estatus_order: element.id_estatus_order,
                        comment_patient: commentsForOrder ? commentsForOrder.comment_patient : null,
                    })

                    tmpDetail.push({
                        id_work_order: element.id_work_order,
                        id_patient: element.id_patient,
                        id_estatus_order: element.id_estatus_order,
                        is_micro:element.is_micro,
                        nim: element.name_company === null ?
                            <div>
                                <label>{element.nim}</label> <br />
                                <label style={{ fontSize: "11px" }} >{"PARTICULAR"}</label>
                            </div>
                            :
                            <div>
                                <label>{element.nim}</label> <br />
                                <label className='txt-secondary' style={{ fontSize: "10px", color: "txt-secondary" }}>{element.name_company}</label> <br />
                            </div>
                        ,
                        fechAndDate: element.validF === null ?
                            <div>
                                <Button style={{ pointerEvents: "none" }} color='info' outline className={`btn btn-pill btn-xs`}>{element.fechAndDate}</Button> <br />
                                <label className='txt-primary' style={{ fontSize: "10px" }} > {element.dateFech}</label>
                            </div>
                            :
                            <div>
                                <Button style={{ pointerEvents: "none" }} color='danger' outline className={`btn btn-pill btn-xs`}>{element.fechAndDate}</Button> <br />
                                <label className='txt-danger' style={{ fontSize: "10px" }} > {element.dateFech}</label>
                            </div>,
                        name_patient: element.name_patient === "  " ?
                            <div>
                                <label className='txt-secondary' style={{ fontSize: "12px", color: "txt-secondary" }}>MICROSANITARIA</label>
                            </div>
                            :
                            element.name_patient,
                        name_branch: element.name_branch,
                        income_type: element.income_type,
                        is_parcial: element.is_parcial,
                        listExam: element.listExam,
                        listProfileWorkOrders: element.listProfileWorkOrders,
                        cont_check_specimen: validador.length,
                        validar_faltantes: validar_faltantes,
                        paid: element.paid,
                        indices: false,
                        valid_invoice: element.valid_invoice,
                        UUID: element.uuid,
                        is_send_email: element.is_send_email,
                        send_email: element.send_patient1,
                        send_doctor: element.send_doctor1,
                        id_company: element.id_company,
                        nim2: element.nim,
                        deliver: element.deliver,
                        valid_user_deliveri:
                            <div>
                                <label>Entregado por: {element.username_deliver}</label> <br />
                                <label>{element.fechAndHour_deliver}</label>
                                {element.comment_patient && (
                                    <label>Comentario: {element.comment_patient}</label>)}
                            </div>,
                        valid_sendAndDelivery:
                            <div>
                                <label>Entregado por: {element.username_deliver}</label> <br />
                                <label>{element.fechAndHour_deliver}</label>   <br />  <br />
                                <label>Enviado a correo por: {element.username_send}</label> <br />
                                <label>{element.fechAndHour_send}</label>   <br />
                                {element.comment_patient && (
                                    <label>Comentario: {element.comment_patient}</label>)}
                            </div>,
                        valid_sendAndDelivery2:
                            <div>
                                <label>Enviado a correo por: {element.username_send2}</label> <br />
                                <label>{element.fechAndHour_send2}</label>   <br />  <br />
                                {element.comment_patient && (
                                    <label>Comentario: {element.comment_patient}</label>)}
                            </div>,
                        send: element.send,
                        send2: element.send2,
                        is_invoiced: element.is_invoiced,
                        invoice_is_general_public: element.invoice_is_general_public,
                        show_print: element.show_print,
                        visibleTable: visibleTable,

                    })
                });

                let consecutivo = 1;
                let statusExcel = "";

                respuesta.data.workOrderDetailPatient.forEach((item, index) => {

                    (item.id_estatus_order === 1) ? statusExcel = "ABIERTA" :
                        (item.id_estatus_order === 2) ? statusExcel = "EN PROGRESO" :
                            (item.id_estatus_order === 4) ? statusExcel = "VALIDADO" :
                                (item.id_estatus_order === 5) ? statusExcel = "PARCIAL" :
                                    (item.id_estatus_order === 6) ? statusExcel = "LIBERADO" :
                                        (item.id_estatus_order === 7) ? statusExcel = "ENVIADO" :
                                            (item.id_estatus_order === 8) ? statusExcel = "ENTREGADO" :
                                                (item.id_estatus_order === 9) ? statusExcel = "ENTREGADO-ENVIADO" :
                                                    (item.id_estatus_order === 10) ? statusExcel = "AUTORIZADO" :
                                                        (item.id_estatus_order === 13) ? statusExcel = "IMPRESO" :
                                                            (item.id_estatus_order === 17) ? statusExcel = "VALIDADO" :
                                                                (item.id_estatus_order === 20) ? statusExcel = "CENCELADO" :
                                                                    (item.id_estatus_order >= 21) ? statusExcel = item.id_estatus_order : statusExcel = item.id_estatus_order

                    tmpDetailExcel2.push({
                        Consecutivo: consecutivo++,
                        Nombre: item.name_patient,
                        Empresa: item.name_company == null ? "PARTICULAR" : item.name_company,
                        NIM: item.nim,
                        Estatus: statusExcel,
                        Pago: item.paid === true ? "PAGADO" : "ADEUDO"
                    })

                    tmpSend.push({
                        nim: item.nim,
                        send_patient: item.send_patient1,
                        send_doctor: item.send_doctor1,
                    })
                });

                const companyNamesSet = new Set();

                respuesta.data.workOrderDetailPatient.forEach((item, index) => {
                    if (item.id_company !== null && item.id_company !== 0) {
                        const companyName = item.name_company;

                        if (!companyNamesSet.has(companyName)) {
                            companyNamesSet.add(companyName);

                            tmpCompanies.push({
                                value: item.id_company,
                                label: companyName,
                            });
                        }
                    }
                });
            }

            if (jsonData) {
                if (persistence.search == true) {
                    goToPage(persistence.currentPage)
                }
                else {
                    const today = new Date().toISOString().split('T')[0];
                    if (persistence.today === today) {
                        goToPage(persistence.currentPage)
                    }
                    else {
                        localStorage.removeItem('dashboard_currentpage');
                    }
                }
            }

            setRespuestaAPI(respuestaEmail);
            setOrderTotal(respuesta.data.total_Work_Orders);
            setExamsAndProfile(respuesta.data.total_ExamAndPerfil);
            setNimData([...tmpDetail]);
            setNimDataExcel([...tmpDetailExcel2]);
            setCountTotal(respuesta.data.total_MountEP);
            setPatientTotal(respuesta.data.total_Patient);
            setPatientCompany(respuesta.data.total_PatienCompany);
            setOrderCancell(respuesta.data.total_cancell);
            setDatanim([...tmpDetail]);
            setDataCompany([...tmpCompanies]);
            setDataSend([...tmpSend]);
            setCommentPatient([...tmpComment])
            setvalidInvoice(respuesta.data.service_invoice)
        }
    }


    const handleCleanFilter = () => {
        localStorage.removeItem('dashboard_currentpage');
        handleUpdateValues({
            nim: "",
            mDateRange1: "",
            mDateRange2: "",
            mSelectStatus: 0,
            ids_users: 0,
            id_company: 0
        })
        getAmount(false)
    }

    const [viewFormFilters, setViewFormFilters] = useState(true);


    const handleViewFilters = () => {
        setViewFormFilters(!viewFormFilters);
    }


    //#endregion GET DASHBOARD

    //#regin UPDATE WORK ORDER
    const navigateUpdate = (workOrderId) => {
        let value = "" + workOrderId + "";
        history.push(`../admisiones/${value}/editar`);
    }
    //#endregio

    //#region method absolutes
    const printTicket = async (id_workOrder) => {

        let order = nimData.find(w => w.id_work_order === parseInt(id_workOrder));

        let id = order.id_work_order

        if (id_workOrder > 0) {

            let token = localStorage.getItem("token");

            await fetch(ConfigServer.serverUrl + "/api/PrintTicket/PrintTicket/" + id, {
                method: 'POST',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
                .then(response => {
                    if (response.status !== 200) {
                        createSweet("warning", "warning", "No se descargo el Ticket");
                        return;
                    }
                    return response.blob();
                })
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "ticket.pdf")
                    document.body.appendChild(link);
                    link.style.display = 'none';
                    link.click();
                })
        }
    }

    const printSticker = async (id_workOrder) => {

        let order = nimData.find(w => w.id_work_order === parseInt(id_workOrder));

        let id = order.id_work_order;
        let is_micro_sanitary=order.is_micro;
        let url="/api/PrintTicket/PrintStickers/";
        if(is_micro_sanitary==true){
            url="/api/PrintTicket/PrintStickersMicro/";
        }

        if (id_workOrder > 0) {

            let token = localStorage.getItem("token");

            await fetch(ConfigServer.serverUrl +url + id, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
                .then(response => {
                    if (response.status !== 200) {
                        createSweet("warning", "warning", "No se descargo la Etiqueta");
                        return;
                    }
                    return response.blob();
                })
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "etiquetas.pdf")
                    document.body.appendChild(link);
                    link.style.display = 'none';
                    link.click();
                })
        }
    }
   

    const navigateConfigEtiqueta = (id_workOrder) => {

        let order = nimData.find(w => w.id_work_order === parseInt(id_workOrder));

        let id = order.id_work_order

        history.push("../busqueda/" + id + "/detalle-orden");
    }


    //#endregion method absolutes

    //#region Medical
    const [typeModall, setTypeModall] = useState("ViewO");
    const [modalOrdenMedical, setModalOrdenMedical] = useState(false);
    const [workMedicalOrderPDF, setworkMedicalOrderPDF] = useState("");
    const [workMedicalOrderPDFDownload, setworkMedicalOrderPDFDownload] = useState('');
    const [loadingPdf, setLoadingPdf] = useState(false);

    const toggleMedical = () => {
        setModalOrdenMedical(!modalOrdenMedical);
    }

    const handleMedical = (id_workOrder) => {

        setTypeModall("ViewO")
        toggleMedical();
        previewWorkOrder(id_workOrder);
    }

    const previewWorkOrder = async (id_workOrder) => {
        setLoadingPdf(true)
        let token = localStorage.getItem("token");

        let order = nimData.find(w => w.id_work_order === parseInt(id_workOrder));
        let id = order.nim2

        await fetch(ConfigServer.serverUrl + `/api/PrintResult/PreviewAlt?nim=${id}&type=NP&is_micro_sanitary=${false}&is_preview=true`, {
            method: 'GET',
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
        .then(response => {
            if (response.status !== 200) return;
            return response.json();
        })
        .then(async data => {
            await fetch(`${ConfigServer.serverUrl}/api/PrintResult/GetFile/${data.watermark}`, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
            .then(response => {
                if (response.status !== 200) {
                    return;
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                setLoadingPdf(false);
                setworkMedicalOrderPDF(url);
                setworkMedicalOrderPDFDownload(`${ConfigServer.serverUrl}/api/PrintResult/DownloadFile/${id}/${data.raw}?jwt=${token}`);
            });
        });
    }

    //#endregion Medical

    //#region print Result

    const printWorkOrder = async (id_workOrder) => {

        let order = nimData.find(w => w.id_work_order === parseInt(id_workOrder));

        let id = order.nim2

        let payload = {
            "nim": id,
            "password": "",
            "type": "NP"
        }
        let data = JSON.stringify(payload);
        let token = localStorage.getItem("token");

        await fetch(ConfigServer.serverUrl + "/api/PrintResult/DownloadSheetResults", {
            method: 'POST',
            body: data,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {
                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se ha podido descargar el pdf");
                    return;
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "hoja_resultados_" + nim + ".pdf");
                document.body.appendChild(link);
                link.click();
                createSweet("success", "success", "Descarga completa");
            });
    }

    const saveComment = async (id_workOrder, comment) => {

        let _body = [{
            "id_work_order": id_workOrder,
            "comment_patient": comment,
        }];

        const _response = await handleRequest("POST", "WorkOrder/UpdateComments", "refused", _body);

        if (_response !== null) {
            createSweet("", "success", "Éxito", "Éxito, comentario guardado");
        }
        else {
            createSweet("error", "error", "El NIM no cuenta con una toma de muestra");
        }
    }

    const changeEstatus = async (id_workOrder, comment) => {
        let newForm = {
            id_work_order: id_workOrder,
            id_estatus_order: 8,
            commentPatient: comment, // Usar el comentario específico del paciente
        }

        let listTmp = await handleRequest("PUT", "WorkOrder/UpdateStatusWorkOrder", "Dashboard", newForm);
        if (listTmp !== null) {
            let nueva_info = nimData.map(xx => {

                if (xx.id_work_order === id_workOrder) {
                    if (xx.id_estatus_order == 7) { //6
                        xx.id_estatus_order = 9;
                        xx.id_estatus_order = 6;

                    } else {
                        xx.id_estatus_order = 8;
                    }
                }
                return xx;
            })
            setNimData([...nueva_info]);
            createSweet("update", "success", "El estatus de la Ordén de Trabajo ha sido modificado.");
            if (comment !== undefined && comment !== null) {
                getAmount();
            }
        }
        saveComment(id_workOrder, comment)
        toggleCheckDelivery();
    }

    //#region filtro de empresas

    //endregion

    //#endregion print Result

    //#region Muestra

    const [checkSpecimens, setCheckSpecimens] = useState([]);
    const [specimensUncheck, setSpecimensUncheck] = useState([]);
    const [loadingSpecimen, setLoadingSpecimen] = useState(false);
    const [idW, setIdW] = useState(0)
    const [modalCheckSpecimen, setModalCheckSpecimen] = useState(false);
    const [chek, setChek] = useState([]);
    const [modalCheckDelivery, setModalCheckDelivery] = useState(false);
    const [idWorkOrder, setIdWorkOrder] = useState(0)

    const toggleCheckSpecimen = () => {
        setModalCheckSpecimen(!modalCheckSpecimen);
    }

    //#region Check Modal 

    const toggleCheckDelivery = () => {
        setModalCheckDelivery(!modalCheckDelivery)
    }

    const handleCheckDelivery = (id_workOrder) => {
        setIdWorkOrder(id_workOrder)
        toggleCheckDelivery();
    }

    //#endregion

    const [itemsTree, setItemsTree] = useState([]);
    const treeviewRef = useRef(null);
    const [dataPending, setDataPending] = useState([]);



    const handleModalCheckSpecimen = async (id_workOrder) => {

        let work_order = nimData.find(x => x.id_work_order === id_workOrder);
        let array_specimens = [];
        let array_nuevo = [];
        let arrayChek = [];
    

        if (work_order.listProfileWorkOrders.length > 0) {
            if (work_order.listProfileWorkOrders[0].listExam.length > 0) {
                work_order.listProfileWorkOrders[0].listExam.map(obj => {
                    if (!array_specimens.find(x => x.id_specimen_origin === obj.id_specimen_origin)) {
                        array_nuevo.push({
                            id: obj.id_specimen_origin,
                            check: obj.check_specimen,
                            name_specimen_origin: obj.name_specimen_origin
                        })
                        arrayChek.push({
                            status: obj.check_specimen
                        })
                    }
                    array_specimens.push(obj);
                })
            }
        }

        let _isToma = await handleRequest("GET", `Sample/DetailSample/${work_order.nim2}`, 'sample');
     
        

        if (work_order.listExam.length > 0) {
            work_order.listExam.map(obj => {
                if (!array_specimens.find(x => x.id_specimen_origin === obj.id_specimen_origin)) {
                    array_nuevo.push({
                        id: obj.id_specimen_origin,
                        check: obj.check_specimen,
                        name_specimen_origin: obj.name_specimen_origin
                    })
                    arrayChek.push({
                        status: obj.check_specimen
                    })
                }
                array_specimens.push(obj)
            });
        }
        setCheckSpecimens(array_nuevo);
        setSpecimensUncheck(array_specimens);
        setChek(arrayChek)
        setIdW(id_workOrder)





        //este es el mio

        let arrayNuevo = [];

        arrayNuevo.push({
            "profiles": work_order.listProfileWorkOrders,
            "exams": work_order.listExam,
            "is_toma": _isToma.length > 0 ? _isToma : ''
        })

        
       

        let tree = createTree(arrayNuevo);
        setItemsTree(tree);

        toggleCheckSpecimen();

    }

    const createTree = (arrayNuevo) => {

        let tree = [];

        arrayNuevo.forEach(element => {
            //exam
            if (element.exams.length > 0) {
                element.exams.forEach(x => {
                    tree.push({
                        "children": [],
                        "id": x.id_work_order_exam,
                        "state": (x.check_forwarded === false && element.is_toma !== '' && x.refused_check_specimen === false)? 1 : x.check_forwarded === true ? 1 : 2,
                        "text": x.name,
                        "isLeaf": x.check_forwarded,
                        "refused_check_specimen": x.refused_check_specimen,
                        "check_forwarded": x.check_forwarded,
                        "is_send1": x.is_send1,
                        "is_send2": x.is_send2,
                        "is_send3": x.is_send3,
                        "is_toma": element.is_toma,
                        "valueDontSend": x.valueDontSend,
                        "pending": x.pending
                    })
                });
            }
            //profile
            if (element.profiles.length > 0) {
                element.profiles.forEach(x => {
                    let examenes_aux = [];
                    let id = true;
                    let checkParent = 1;
                    let checy = 0;
                    let is_send1 = false;
                    let is_send2 = false;
                    let is_send3 = false;
                    let refused_check_specimen = false;
                    let check_f = false;
                    let valueDontSend = 0;
                    let pending = 0;

                    x.listExam.forEach(exam => {

                        if ((!exam.check_specimen && element.is_toma === '') || (exam.refused_check_specimen === true && exam.check_forwarded === false)) {
                            checkParent = 2;
                        }
                        if(exam.check_forwarded === true)
                        {
                            check_f = true;
                        }

                        examenes_aux.push({
                            "id": exam.id_work_order_exam,
                            "isLeaf": exam.check_forwarded,
                            "state": (exam.check_forwarded === false && element.is_toma !== '' && exam.refused_check_specimen === false)? 1 : exam.check_forwarded === true ? 1 : 2,
                            "text": exam.name,
                            "refused_check_specimen": exam.check_forwarded === true ? false : exam.refused_check_specimen,
                            "check_forwarded": exam.check_forwarded,
                            "is_send1": exam.is_send1,
                            "is_send2": exam.is_send2,
                            "is_send3": exam.is_send3,
                            "valueDontSend" : exam.valueDontSend,
                            "pending" : exam.pending,
                            "is_toma": exam.refused_check_specimen === false ? element.is_toma : ''

                        })
                        id = exam.check_specimen
                        checy = exam.refused_check_specimen === false ? 1 : 2
                        is_send1 = exam.is_send1
                        is_send2 = exam.is_send2
                        is_send3 = exam.is_send3
                        refused_check_specimen = exam.refused_check_specimen
                        check_f= exam.check_forwarded
                        pending = exam.pending
                        valueDontSend = exam.valueDontSend
                        
                    });

                    tree.push({
                        "children": examenes_aux,
                        "state": checkParent,
                        "text": x.name_profile,
                        "isLeaf": id,
                        "is_send1": is_send1,
                        "is_send2": is_send2,
                        "is_send3": is_send3,
                        "check_forwarded": check_f,
                        "is_toma" : checkParent === 1 && check_f === false ? element.is_toma : '',
                        "pending": pending,
                        "valueDontSend" : valueDontSend
                    })

                });
            }
        });
        return tree;
    }


    const changeTree = (e) => {
        changeStatus(e)
    }

    const changeStatus = async (e, id_work_order) => {

        const api = treeviewRef.current.api
        const items = api.getItems();

        let pending = [];

        //exams
        items.forEach(element => {
            if (element.id !== undefined) {
                if ((element.state === 1 && element.is_toma === '') || 
                (element.refused_check_specimen === true && element.state === 1 )) {
                    pending.push({
                        "id_work_order": idW,
                        "id_work_order_exam": element.id,
                        "list_specimensToma": [
                            {
                                "check_forwarded": true,

                                "name": element.text
                            }
                        ]
                    });
                }
            }
            //profiles
            else {
                element.children.forEach(ex => {
                    if ((ex.state === 1 && ex.is_toma === '') ||
                       (ex.refused_check_specimen === true && ex.state === 1 )) {
                        pending.push({
                            "id_work_order": idW,
                            "id_work_order_exam": ex.id,
                            "list_specimensToma": [
                                {
                                    "check_forwarded": true,
                                    "name": ex.text

                                }
                            ]
                        });

                    }

                })
            }

        });
        setDataPending(pending)


    }


    const savePendingMuestra = async () => {

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(dataPending)
        };


        const respuesta = await sendRequest(requestOptions, "Sass/CheckSpecimenFlebotomia");
        if (respuesta.code === 200) {

            let newWorkOrders = nimData;

            newWorkOrders = newWorkOrders.map(element => {
                let validar_faltantes = false;
                let validador = [];

                if (element.id_work_order === idW) {
                    if (element.listProfileWorkOrders.length > 0) {
                        if (element.listProfileWorkOrders[0].listExam.length > 0) {
                            element.listProfileWorkOrders[0].listExam.map(obj => {

                                dataPending.forEach(elem => {

                                    elem.list_specimensToma.forEach(elements => {



                                        if (elem.id_work_order_exam === obj.id_work_order_exam) {
                                            obj.check_forwarded = elements.check_forwarded
                                        }

                                        if (!elements.check_forwarded) {
                                            validar_faltantes = true;
                                        }
                                    });
                                });
                                return obj;
                            });
                        }
                    }

                    if (element.listExam.length > 0) {
                        element.listExam.map(obj => {
                            dataPending.forEach(ele => {


                                ele.list_specimensToma.forEach(e => {
                                    if (ele.id_work_order_exam === obj.id_work_order_exam) {
                                        obj.check_forwarded = e.check_forwarded;
                                    }

                                    if (!e.check_forwarded) {
                                        validar_faltantes = true;
                                    }
                                });

                            });
                            return obj;
                        })
                    }
                    element.cont_check_specimen = validador.length;
                    element.validar_faltantes = validar_faltantes;
                }
                return element;
            });

            setNimData(newWorkOrders);

            createSweet("success", "success", "Muestra Pendiente", "Muestra Actualizada");
        }
        toggleCheckSpecimen();

    }


    const checkSpecimenFRomWorkOrder = (id_specimen_origin) => {

        let checados = checkSpecimens;
        let nueevo = checados.map(obj => {
            if (obj.id === id_specimen_origin) {
                obj.check = !obj.check;
            }
            return obj;
        });

        setCheckSpecimens(nueevo);
    }



    //#endregion Muestra

    //#region pagination

    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(nimData.length, numberItems);

    useEffect(() => {
        if (nimData.length > 0) {
            const today = new Date().toISOString().split('T')[0];
            const data = {
                currentPage: currentPage,
                search: !viewFormFilters,
                today: today,
                nim: nim,
                mDateRange1: mDateRange1,
                mDateRange2: mDateRange2,
                mSelectStatus: mSelectStatus,
                ids_users: ids_users,
                id_company: id_company
            };
            const jsonData = JSON.stringify(data);
            localStorage.setItem('dashboard_currentpage', jsonData);
        }

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listDashboard = [];
        nimData.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listDashboard.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setNimData(listDashboard);

    }, [totalPageCount, currentPage]);

    const [datanim, setDatanim] = useState([]);

    const handleSearchNim = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        datanim.forEach(element => {
            let cadena = element.name_patient.toUpperCase();
            let abbreviation = element.name_patient.toUpperCase();
            let code = element.nim.toUpperCase();

            if ((cadena.indexOf(busqueda.toUpperCase()) > -1) || (abbreviation.indexOf(busqueda.toUpperCase()) > -1) || (code.indexOf(busqueda.toUpperCase()) > -1)) {
                element.visibleTable = true;
                newMethod.push(element);
            }

        });
        setNimData(newMethod);
    }



    //#endregion pagination

    //#region Exam

    const [enableViewTest, setEnableViewTest] = useState(false);

    const handleViewTest = () => {
        setEnableViewTest(true);
    }

    const handleCloseViewTest = () => {
        setEnableViewTest(!enableViewTest);

    }

    //#endregion Exam

    //#region Historic Sample

    // modalFullRef

    const [typePending, setTypePending] = useState("Pending");
    const [modalPending, setModalPending] = useState(false);
    const [loadingSample, setLoadingSample] = useState(false);
    const [dataSample, setDataSample] = useState([]);
    const [dataSendProcess2, setDataSendProcess2] = useState([]);
    const [dataSendProcess3, setDataSendProcess3] = useState([]);
    const [dataTrackingRecepetion, setDataTrackingRecepetion] = useState([]);
    const [dataSection, setDataSection] = useState([]);
    const [dataID, setDataID] = useState([]);

    const [key, setKey] = useState(0);
    const [namePatient, setNamePatient] = useState("");
    const [idGender, setIdGender] = useState(0);



    const [state, setState] = useState(prevState => ({
        ...prevState,
        text: "",
        number: 0
    }));


    const { close, modalProps, open } = useModal({
        onClose: () => setKey(key => key + 1),
        background: "#fff"
        //  background: rgba(0, 121, 199, 0.15)

    });



    const handleTrazabilidad = (_idW, _nim, _modalFullRef, _namePatient, _idGender) => {

        let work_order = nimData.find(x => x.id_work_order === _idW);
        let arrayNuevo = [];

      

        arrayNuevo.push({
            "profiles": work_order.listProfileWorkOrders,
            "exams": work_order.listExam
        })

        let tree = createTree(arrayNuevo);
        setItemsTree(tree);
        handleGetSample(work_order.nim2);  //
        handleGetSendProcess2(_idW);//
        handleGetSendProcess3(_idW);//
        handleGetTrackingReception(_idW);//
        setNamePatient(_namePatient);
        setIdGender(_idGender);
        open(_modalFullRef)
    }

    const toggleHistoric = () => {
        setModalPending(!modalPending);
    }

    const handleGetSample = async (_nim) => {

        setLoadingSample(false);


        let _listTmp = await handleRequest("GET", `Sample/DetailSample/${_nim}`, 'sample');


        if (_listTmp !== null) {
            setLoadingSample(true);
            setDataSample(_listTmp)
        }


    }

    const [refusedGlobal, setRefusedGlobal] = useState([]);

    const handleGetSendProcess2 = async (_idW) => {

        let _listTmp = await handleRequest("GET", `Sample/DetailSendSample1/${_idW}`, 'sample');
        if (_listTmp !== null) {
            setDataSendProcess2(_listTmp)
        }

        let _reasonRefused = await handleRequest("GET", `Sass/GetRefusedGlobal/${_idW}`);

       
        let _refusedGlobal = [];

        if (_refusedGlobal !== null)
        {
            _refusedGlobal.push({
                "reason":_reasonRefused.reason,
                "user": _reasonRefused.user,
                "origen": _reasonRefused.origen
             });
        }

        setRefusedGlobal(...[_refusedGlobal]);

    }

    const handleGetSendProcess3 = async (_idW) => {

        let _listTmp = await handleRequest("GET", `Sample/DetailSendSample2/${_idW}`, 'sample');
        if (_listTmp !== null) {
            setDataSendProcess3(_listTmp)
        }
    }

    const handleFilterSections = (filterTo) => {
        setFilterSections(filterTo);
    }

    const handleGetTrackingReception = async (_idW) => {

        let _listTmp = await handleRequest("GET", `Sample/DetailtrackingReception/${_idW}`, 'sample');
        if (_listTmp !== null) {
            let nuevoObjeto = [];
            let indexObject = [];

            if (_listTmp.length >= 0) {
                _listTmp.forEach(x => {
                    if (!nuevoObjeto.hasOwnProperty(x.nameSection)) {
                        nuevoObjeto[x.nameSection] = {
                            secciones: []
                        }
                        indexObject.push(x.nameSection);
                    }
                    nuevoObjeto[x.nameSection].secciones.push({
                        nombre: x.nameExam,
                        proces: x.initFech
                    })
                });
                setDataSection(nuevoObjeto);
                setDataID(indexObject);
            }
            else {
                setDataSection([]);
                setDataID([]);

            }
            setDataTrackingRecepetion(_listTmp)
        }
    }
    //#endregion Historic Sample

    //#region Invoice Patient
    const navigateInvoice = (_workOrderId, _idPatient, _id_company) => {

        let _validCompany = _id_company == null ? 0 : _id_company
        let _validPatient = _idPatient == null ? 0 : _idPatient


        history.push(`../invoice/${_workOrderId}/${_validPatient}/${_validCompany}/service`);
    }
    //#endregion Invoice Patient

    //#region PRINT PDF RESULT
    const handleListPrint = async (id_work_order) => {

        let _listTmp = await handleRequest("GET", `WorkOrder/GetExambyWorkOrder/${id_work_order}`, 'invoice');

        if (_listTmp !== null) {
            let dataExamsTemp = [];
            _listTmp.forEach((data, index) => {
                if (data.listExam != "[]") {
                    data.listExam.forEach((data, index) => {
                        dataExamsTemp.push({
                            "id_work_order_exam": data.id_work_order_exam,
                            "id_work_order_profile": null,
                            "id_estatus": data.id_estatus,
                            "id_profile": null,
                            "id_exam": data.id_exam,
                            "name": data.name,
                            "listExam": [],
                            "check_box": false,
                            "sendBy": data.sendBy,
                            "show_print": data.show_print

                        });
                    });

                }
                if (data.listProfile != "[]") {
                    data.listProfile.forEach((data, index) => {
                        dataExamsTemp.push({
                            "id_work_order_exam": data.id_work_order_exam,
                            "id_work_order_profile": data.id_work_order_profile,
                            "id_estatus": data.id_status,
                            "id_profile": data.id_profile,
                            "id_exam": null,
                            "name": data.name_profile,
                            "listExam": data.listExam,
                            "check_box": false,
                            "gatos": 155,
                            "show_print": data.show_print

                        });
                    });
                }
                setid_workorder_print(id_work_order);
                setdataExams(dataExamsTemp);
                setTypeModalPrintI("viewPrintI")
                togglePrintI();
            });
        }
    }

    const handleCheckProfile = (e, id_profile, id_exam, id_work_order_profile, id_work_order_exam) => {
        let copyTest = [...dataExams];
        let valor = e.target.checked;
        let elementToUpdate = copyTest.find(x => id_profile != null && id_exam != null ?
            x.id_work_order_profile === id_work_order_profile && x.id_profile === id_profile :
            x.id_work_order_exam === id_work_order_exam && x.id_exam === id_exam);
        if (elementToUpdate) {
            if (id_profile != null && id_exam != null) {
                elementToUpdate.listExam.map(i => {
                    if (i.id_exam == id_exam) {
                        i.check_box = valor;
                    }
                    return i;
                });

            } else {
                elementToUpdate.check_box = valor;
            }
        }
        setdataExams(copyTest);
    };

    const handleCheckAll = (e) => {
        let copyTest = [...dataExams];
        let valor = e.target.checked;
        
        if(validPorcentejeSpecial === "https://centroquimico.helen-sw.com"){
            if (copyTest) {

                copyTest.map(x => {
                    if (!x.show_print) {
                        return;
                    }
                    if ((x.id_estatus >= 7 && x.id_estatus <= 10) || x.id_estatus == 12
                        || x.id_estatus == 13 || x.id_estatus == 15 || x.id_estatus == 18 || x.id_estatus == 5) {
                        x.check_box = valor;
                        x.listExam.map(i => {
                            if ((i.id_estatus >= 7 && i.id_estatus <= 10) || i.id_estatus == 12
                                || i.id_estatus == 13 || i.id_estatus == 15 || i.id_estatus == 18 || i.id_estatus == 5) {
                                i.check_box = valor;
                            }
                            return i;
                        });
                    }
                    return x;
                });
            }
        }else{
            if (copyTest) {
                copyTest.map(x => {
                    if (!x.show_print) {
                        return; 
                    }
                    if ((x.id_estatus >= 6 && x.id_estatus <= 10) || x.id_estatus == 12
                        || x.id_estatus == 13 || x.id_estatus == 15 || x.id_estatus == 18 || x.id_estatus == 5) {
                        x.check_box = valor;
                        x.listExam.map(i => {
                            if (!i.show_print) {
                                return; 
                            }
                            if ((i.id_estatus >= 6 && i.id_estatus <= 10) || i.id_estatus == 12
                                || i.id_estatus == 13 || i.id_estatus == 15 || i.id_estatus == 18 || i.id_estatus == 5) {
                                i.check_box = valor;
                            }
                            return i;
                        });
                    }
                    return x;
                });
            }
        }
        setdataExams(copyTest);
    };
    const handleCheckAllProfile = (e, id_profile, id_work_order_profile) => {
        let copyTest = [...dataExams];
        let valor = e.target.checked;
        let elementToUpdate = copyTest.find(x => x.id_work_order_profile === id_work_order_profile
            && x.id_profile == id_profile);
        if(validPorcentejeSpecial === "https://centroquimico.helen-sw.com"){ 
            if (elementToUpdate) {
                elementToUpdate.check_box = valor;
                elementToUpdate.listExam.map(i => {
                    if (!i.show_print) {
                        return false;
                    }
                    if ((i.id_estatus >= 7 && i.id_estatus <= 10) || i.id_estatus == 12
                        || i.id_estatus == 13 || i.id_estatus == 15 || i.id_estatus == 18 || i.id_estatus == 5) {
    
                        i.check_box = valor;
                    }
                    return i;
                });
            }
        }else{
            if (elementToUpdate) {
                elementToUpdate.check_box = valor;
                elementToUpdate.listExam.map(i => {
                    if (!i.show_print) {
                        return false;
                    }
                    if ((i.id_estatus >= 6 && i.id_estatus <= 10) || i.id_estatus == 12
                        || i.id_estatus == 13 || i.id_estatus == 15 || i.id_estatus == 18 || i.id_estatus == 5) {
    
                        i.check_box = valor;
                    }
                    return i;
                });
            }
        }
        
        setdataExams(copyTest);
    };

    const Impresionbyexam = async () => {

        let order = nimData.find(w => w.id_work_order === parseInt(id_workorder_print));

        let id = order.nim2
        let _valid = false

     
        dataExams.forEach(_valid1 => {
            if (_valid1.check_box === true) {
                _valid= _valid1.check_box            
            }
            _valid1.listExam.forEach(_f => {
                if (_f.check_box === true) {
                    _valid= _f.check_box
                  }
            });         
        });


        let payload = {
            "nim": id,
            "password": "",
            "type": "NP",
            "lista_exames": dataExams,
            "letter": letter,
        }

        if (_valid !== false) {

            
               let data = JSON.stringify(payload);
        let token = localStorage.getItem("token");

        await fetch(ConfigServer.serverUrl + "/api/PrintResult/DownloadSheetResultsByExam?is_printed=true", {
            method: 'POST',
            body: data,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {
                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se ha podido descargar el pdf");
                    return;
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "hoja_resultados_" + nim + ".pdf");
                document.body.appendChild(link);
                link.click();
                createSweet("success", "success", "Descarga completa");
            });
        }
        else {
            createSweet("warning", "warning", "Debe de seleccionar al menos un estudio para poder imprimir");

        }
    }

    const togglePrintI = () => {
        setModalPrintI(!modalPrintI);
    }
    //#endregion PRINT PDF RESULT

    //#region pdf Invoice
    const handleInvoice = async (id_work_order) => {
        setTypeModalInvoice("viewInvoice");
        toggleInvoice();
        previewInvoice(id_work_order);
    }

    const toggleInvoice = () => {
        setModalInvoice(!modalInvoice);
    }

    const previewInvoice = async (id_work_order) => {

        let order = nimData.find(w => w.id_work_order === parseInt(id_work_order));
        let id = order.id_work_order;
        let uuid_send = order.UUID;

        setLoadingPdf(true);

        let newForm = {
            "UUID": uuid_send,
            "id_work_order": id
        }

        let listTmp = await handleRequest("POST", "Invoice/donwloadinvoice", "Dashboard", newForm);
        if (listTmp !== null) {
            setLoadingPdf(false)
            setInvoicePDF(listTmp.cadena);
        }
    }


    const sendResultForEmail = async (id_work_order) => {

        let order = nimData.find(w => w.id_work_order === parseInt(id_work_order));
        let id_wo = order.id_work_order;
        let uuid_send = order.UUID;

        let payload = {
            "UUID": uuid_send,
            "id_work_order": id_wo,
        }

        createSweet("create", "warning", "Enviando correo...", "");
        const _response = await handleRequest("POST", "Invoice/SendInvoiceEmail", "Invoice", payload);

        if (_response !== null) {
            let nueva_info = nimData.map(xx => {
                if (xx.id_work_order === id_work_order) {
                    if (xx.is_send_email == false) {
                        xx.is_send_email = true;
                    }
                }
                return xx;
            })
            setNimData([...nueva_info]);
            createSweet("create", "success", "Exito!", "Los resultados se han enviado correctamente");
        }
    }


    function exportToExcel(data) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = 'data.xlsx';

        if (typeof window !== 'undefined' && window.navigator && window.navigator.msSaveOrOpenBlob) {
            // Para Internet Explorer
            window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
        } else {
            // Para otros navegadores
            const url = window.URL.createObjectURL(dataBlob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }
    //#endregion pfd Invoice


    //#region users

    const [user, setUser] = useState([]);


    const getUser = async () => {

        let _listTmp = await handleRequest("GET", "WorkOrder/ListUser", 'Userdashboard');

        if (_listTmp !== null && _listTmp.length > 0) {
            let _listuser = []

            _listTmp.forEach(_find => {
                _listuser.push({
                    label: _find.name,
                    value: _find.id_user
                });
            });

            setUser(_listuser);
        }

    }

    //#endRegion users


    //#region Send Exams 
    const [typeModalExamEmail, setTypeModalExamEmail] = useState("viewExamEmail");
    const [nimSend, setNimSend] = useState(0);
    const [modalExamsEmail, setModalExamsEmail] = useState(false);
    const [modalCheckEmail, setModalCheckEmail] = useState(false);




    const toggleListExamEmail = () => {
        setModalExamsEmail(!modalExamsEmail);
    }

    const toggleExamEmail = () => {
        setModalCheckEmail(!modalCheckEmail)
    }

    const handleFindExam = () => {

        let checkExam = dataExams.find(w => w.check_box === true)


        let individual = [];
        let profile = [];
        let profiles = [];

        dataExams.forEach((data, index) => {
            individual.push({
                "check_box": data.check_box,
            })
            profile.push({
                "listExam": data.listExam
            });
        });

        profile.forEach((data, index) => {

            data.listExam.forEach((check) => {
                profiles.push({
                    "check_box": check.check_box,
                })
            })
        })



        let checkIndividual = false;
        let checkProfiles = false;
        let _checkIndividualTemp = individual.find(w => w.check_box)
        if (_checkIndividualTemp !== undefined) {
            checkIndividual = _checkIndividualTemp.check_box
        }


        let _checkProfilesTemp = profiles.find(w => w.check_box);
        if (_checkProfilesTemp !== undefined) {
            checkProfiles = _checkProfilesTemp.check_box;
        }

        if (checkIndividual === true || checkProfiles === true) {
            setModalExamsEmail(!modalExamsEmail);
            if (modalExamsEmail === true) {
                handleSendExam();
            }
        }
        else {
            createSweet("", "warning", "Advertencia", "No se ha seleccionado ningún examen");
        }






    }

    const handleSendExam = async (id_workOrder, nim2) => {
        let order = nimData.find(w => w.id_work_order === parseInt(id_workorder_send));
        let id = order.nim2
        let patient = dataSend.find(x => x.nim === id)
        setSendTo(patient);
        setNimSend(nim2)
        toggleExamEmail();
    }


    const handleListExamEmail = async (id_work_order) => {

        let _listTmp = await handleRequest("GET", `WorkOrder/GetExambyWorkOrder/${id_work_order}`, 'invoice');

        if (_listTmp !== null) {
            let dataExamsTemp = [];
            _listTmp.forEach((data, index) => {
                if (data.listExam != "[]") {
                    data.listExam.forEach((data, index) => {
                        dataExamsTemp.push({
                            "nim": data.nim,
                            "id_work_order_exam": data.id_work_order_exam,
                            "id_work_order_profile": null,
                            "id_estatus": data.id_estatus,
                            "id_profile": null,
                            "id_exam": data.id_exam,
                            "name": data.name,
                            "listExam": [],
                            "check_box": false,
                        });
                    });

                }
                if (data.listProfile != "[]") {
                    data.listProfile.forEach((data, index) => {
                        dataExamsTemp.push({
                            "nim": data.nim,
                            "id_work_order_exam": data.id_work_order_exam,
                            "id_work_order_profile": data.id_work_order_profile,
                            "id_estatus": data.id_status,
                            "id_profile": data.id_profile,
                            "id_exam": null,
                            "name": data.name_profile,
                            "listExam": data.listExam,
                            "check_box": false,
                        });
                    });
                }

                setid_workorder_send(id_work_order);
                setdataExams(dataExamsTemp);
                setTypeModalExamEmail("viewExamEmail")
                toggleListExamEmail();
            });
        }
    }
    const sendResultEmail = async (sendDoctor) => {
        let order = nimData.find(w => w.id_work_order === parseInt(id_workorder_send));

        let id = order.nim2

        let payload = {
            "nim": id,
            "password": "",
            "type": "NP",
            "doctor": sendDoctor,
            "lista_exames": dataExams
        }





        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(payload)
        };

        const respuesta = await sendRequest(requestOptions, "PrintResult/SendResultForEmailByExam");


        if (respuesta.code === 200) {
            createSweet("create", "success", "Exito!", "Los resultados se han enviado correctamente");

            toggleExamEmail();
            getAmount();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, sendResultForEmail);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Resultados", respuesta.data.msg);
        }
        else {
            createSweet("error", "warning", "Resultados", respuesta.data.msg);
        }
    }
    //#endregion Send Exams


    //#region validations

    const validationDetailDashboard = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.mDateRange1 !== "") {
            newValidations = {
                ...newValidations,
                mDateRange1: true,
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                mDateRange1: false,
            };
        }

        if (formValues.mDateRange2 !== "") {
            newValidations = {
                ...newValidations,
                mDateRange2: true,
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                mDateRange2: false,
            };
        }

        setvalidationsSG(newValidations);
        return statusValidation;
    }

    //#endregion validations


    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    const [typeNumber, setTypeNumber] = useState(0);

    const handleChangeTypeFilter = (_type) => {
        setTypeNumber(_type)
        if (_type === 1) {
        }
    }



    return {
        // check Delivery
        toggleCheckDelivery, handleCheckDelivery, modalCheckDelivery, idWorkOrder,
        //pagination
        totalPageCount, currentPage, nextPage, previousPage, goToPage, handleSearchNim, getdashboard, nim, handleInputChange, loading,
        //dashboard general and indications
        nimData, patientCompany, patientTotal, countTotal, examsAndProfile, orderTotal, orderCancell,
        //methods absolutes
        printTicket, printSticker, navigateConfigEtiqueta,
        //modal Medical
        typeModall, modalOrdenMedical, toggleMedical, handleMedical, workMedicalOrderPDF, loadingPdf, workMedicalOrderPDFDownload,
        //print result
        printWorkOrder, changeEstatus, commentPatient, nimData,
        //check specimen
        handleModalCheckSpecimen, toggleCheckSpecimen, modalCheckSpecimen,
        specimensUncheck, checkSpecimenFRomWorkOrder, checkSpecimens, loadingSpecimen, classT, chek,

        //nuevo
        treeviewRef, itemsTree, changeTree, savePendingMuestra, enable_tracking_toma,

        //update work order
        navigateUpdate,
        //exam
        handleViewTest, handleCloseViewTest, enableViewTest,
        //Print PDF individual
        handleListPrint, typeModalPrintI, modalPrintI, togglePrintI, dataExams, handleCheckProfile, handleCheckAll, handleCheckAllProfile,
        Impresionbyexam, id_workorder_print, letter,
        //Invoice
        toggleInvoice, handleInvoice, typeModalInvoice, modalInvoice, InvoicePDF, sendResultForEmail, exportToExcel, nimDataExcel,
        //historic Sample
        typePending, modalPending, toggleHistoric,
        dataSample, loadingSample, dataSendProcess2, dataSendProcess3, dataTrackingRecepetion,

        handleTrazabilidad, close, modalProps, namePatient, idGender, dataSection, dataID,
        mDateRange1, mDateRange2, handlePickerValues,
        mSelectStatus, handleSelectValues, statusOrder, handleCleanFilter, viewFormFilters, handleViewFilters, navigateInvoice,
        ids_users, user,
        //validation date
        validationsSG, handleSendExam, toggleExamEmail, modalCheckEmail, sendResultEmail, modalExamsEmail, handleListExamEmail, toggleListExamEmail,
        typeModalExamEmail, id_workorder_send, sendTo, handleFindExam,
        //EmailDoctor
        respuestaAPI, dataCompany, id_company, credit_payment,
        //button
        typeNumber, handleChangeTypeFilter,
        pending, is_pending,validinvoice,
        //MICROSANITARY

        //Refused 
        refusedGlobal, filterSections,handleFilterSections,
        
    }
}