import React, { Fragment, useState } from 'react'
import {useParams } from "react-router-dom";
import { Card, FormGroup, Row, Col, CardBody, CardHeader, Button, Container, Table, Badge, Alert, Form, Label, Input } from 'reactstrap';
import 'animate.css';
import { TableCash } from './TableCash';
import './styleCash.css';
import { ModalCash } from './ModalCash';
import { useCash } from '../../hooks/cash/useCash';
import { ModalPreviewCash } from './ModalPreviewCash';
import { ModalPreviewCredt } from './ModalPreviewCredt';
import { ModalReportCash } from './ModalReportCash';
import Select from 'react-select';
import { ToolTips } from '../../components/tooltips/ToolTips';







export const IndexCash = () => {

    //localStorage
    let branchs = localStorage.getItem('branchName');
    let user = localStorage.getItem('nameUser');

    const {
        navigatePrev, viewTab,
        capture_amount, handleInputChangeCash,
        data,
        method, modal, modalTitle, loading, toggle, formCash, indicador1,
        countPatient, cancell, discountT, comments, saveCreate, formatter, validationsSG, totalCancell,
        realCash, creditTotal
        , dataHistoric,
        dateInit, dateEnd, changeDate, handleHistoric,
        previewCash, toggleCash, typeModal, modalC,
        previewCredit, toggleCredit, modalCredit, typeModalCredit, dataCredit,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,
        typeAmoun, amountfin,
        //Report cash
        dataReport, modalFullRef, handleReport, close, modalProps, handleSelectValues,
        //pagination cassh
        totalPageCount1, currentPage1, nextPage1, previousPage1, goToPage1,
        dataPaymets,
        dataCancell, dataDiscount,
        dataPatient, dataDevolution, mDateRange1, mDateRange2, getReport,
        mSelectBranch, handleSelectValuesCash, branches,
        handleViewFilters, viewFormFilters, handleCloseViewTest, printCashClosing,totalPaymets,
        exportToExcel,titleCash,
        //#region type client
        handleTypeCompany,type_company,handleClean,dataMDiscount

    } = useCash();

    let wilso = amountfin[0]

    let prueba = 0

    const { Admin,User } = useParams();

    console.log(Admin);
    console.log(User);




    let amount1 = formCash.capture_amount

    if (realCash.length >= 1) {

        let amount2 = (realCash[0].total)

        prueba = Math.round(amount2 - amount1)

    }


    let filter = "1"

    const AlertItem = props => {
        const { item } = props;
        const [Open, setOpen] = useState(true);

        return (
            <Alert className="alert-dismissible"
                color={item.alertcolor}
                isOpen={Open}
                target={"Alert-" + item.id}
            >
                {item.icon}
                <p>{item.alerttxt}</p>
            </Alert>
        );
    }


    return (
        <Fragment>
            <Container fluid={true}>
                <Row className="mt-2 mb-3">
                    <Col sm="2" md="2" lg="2" className="default-according style-1 faq-accordion job-accordion position-relative">
                        <Row className="position-sticky">
                            <Col sm="12" className='p-l-0 p-r-0' >
                                <Card className='shadow' >
                                    <Row>
                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-2'>
                                            <img style={{ filter: "sepia(1)" }} className="img-fluid" src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/Egress.svg")} />
                                        </Col>
                                        <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-l-0 p-r-0' >
                                            <br />
                                            <label className='labelIndicadorCash'>Credito</label> <br />
                                            <b className='f-14'>${Math.round(creditTotal).toFixed(2)}</b>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col sm="12" md="12" className='pr-0 file-spacing p-l-0'>
                                <div className='file-sidebar'>
                                    <Card className='' >
                                        <ul>
                                            <li>
                                                <div className={`btn ${filter === '1' ? 'btn-primary-gradien' : 'btn-outline-info-2x'}`}   ><i className="icofont icofont-list"></i> &nbsp;&nbsp; Corte de Caja</div>
                                            </li>
                                            <li>
                                                <div className={`btn ${filter === '2' ? 'btn-primary-gradien' : 'btn-outline-info-2x'}`} onClick={previewCash}  > Historico de Cortes</div>
                                            </li>
                                            <li>
                                                <div className={`btn ${filter === '2' ? 'btn-primary-gradien' : 'btn-outline-danger-2x'}`} onClick={previewCredit} > Creditos Pendientes</div>
                                            </li>
                                            {
                                                Admin ===  undefined ? ""
                                                :
                                                <li >
                                                <div className={`btn ${filter === '3' ? 'btn-primary-gradien' : 'btn-outline-dark-2x'}`} ref={modalFullRef} onClick={() => handleReport()}>Reportes</div>
                                            </li>
                                           
                                            }
                                           
                                        </ul>
                                    </Card>
                                </div>
                            </Col>


                        </Row>

                    </Col>
                    <Col sm="10" md="10" lg="10">
                        <Col sm="12">
                            <Row>
                                <Col sm="3" >
                                    <Card className='shadow' >
                                        <Row>
                                            <Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-2'>
                                                <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/Egress.svg")} />
                                            </Col>
                                            <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-l-0 p-r-0' >
                                                <br />
                                                <label className='labelIndicadorCash'>Total en Devoluciones</label> <br />
                                                {/* <b className='f-16'>{countPatient}</b> */}
                                                <b className='f-16'> ${dataDevolution}</b>

                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col sm="3" >
                                    <Card className='shadow' >
                                        <Row>
                                            <Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-2'>
                                                <img className="img-fluid" style={{ filter: "invert(1)" }} src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/Egress.svg")} />
                                            </Col>
                                            <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-l-0 p-r-0' >
                                                <br />

                                                <label className='labelIndicadorCash'>Ordenes Canceladas</label> <br />
                                                <Col sm="12">
                                                    <b className='f-16 txt-danger'>{totalCancell}</b>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col sm="3" >
                                    <Card className='shadow' >
                                        <Row>
                                            <Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-2'>
                                                <img className="img-fluid" style={{ filter: "invert(1)" }} src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/Egress.svg")} />
                                            </Col>
                                            <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-l-0 p-r-0' >
                                                <br />
                                                <label className='labelIndicadorCash'>Cancelaciones</label> <br />
                                                <b className='f-14'>${Math.round(cancell).toFixed(2)}</b>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col sm="3" >
                                    <Card className='shadow' >
                                        <Row>
                                            <Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-2'>
                                                <img className="img-fluid" style={{ filter: "brightness(0.5)" }} src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/Egress.svg")} />
                                            </Col>
                                            <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-l-0 p-r-0' >
                                                <br />
                                                <label className='labelIndicadorCash'>Descuentos</label> <br />
                                                <b className='f-14'>${Math.round(discountT)}</b>
                                            </Col>
                                        </Row>
                                    </Card>

                                </Col>
                            </Row>
                        </Col>
                        <Card className='shadow'>
                            <CardBody>
                                <Col sm="12">
                                    <label className='f-14' >Generar Corte de Caja</label>
                                </Col>
                                <hr />
                                <div className="table-responsive">
                                    <Table className="table-border-vertical">
                                        <thead className="theadInvoice">
                                            <tr>
                                                <th className='labelThead' scope="col">#</th>
                                                <th className='labelThead' scope="col">Método de pago</th>
                                                <th className='labelThead' scope="col">Calculado en el Sistema</th>
                                            </tr>
                                        </thead>
                                        {
                                            realCash.length === 0 ?
                                                <th>
                                                    <th rowSpan={7} colSpan="2" style={{ verticalAlign: "middle", width: '550px' }} className='text-center '>
                                                        <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} style={{ width: '120px' }} />
                                                        <br /> <br />
                                                        <p className='f-12 text-primary'> <b>No se podra realizar un corte de caja, hasta no registrar una venta.</b></p>
                                                    </th>
                                                </th>
                                                :
                                                <tbody>
                                                    {
                                                        typeAmoun.length > 0 && typeAmoun.sort() && typeAmoun.map((a, b) => {
                                                            return (
                                                                <tr>
                                                                    <td>{b + 1}</td>
                                                                    <td>{a}</td>
                                                                    <td> <label className='f-14'>{formatter.format(wilso[a].Amount != undefined && wilso[a].Amount)}</label> </td>
                                                                </tr>

                                                            )
                                                        })
                                                    }

                                                </tbody>

                                        }
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                        <Card className={indicador1.length === 0 ? 'd-none' : 'm-b-15 animate__animated animate__fadeInDown  '}>
                            <CardBody >
                                <label className='f-16'>Detalles del Corte de Caja</label>
                                <hr />
                                <Col sm="12">
                                    <Row>
                                        <Col sm="7" md="7" lg="7">
                                            <Badge className='f-12 theadInvoice' style={{ width: '100%' }}>Datos generales</Badge>
                                            <Col sm="12" className='p-l-0 p-r-0' >
                                                <Card className='shadow  disabled disableLabel ' >
                                                    <CardBody className='p-t-0' > <br />
                                                        <i className="fa fa-circle-o text-info"> </i> <b>Responsable:</b> <label className='text-secondary'>&nbsp;&nbsp;{user}</label>
                                                        <br />
                                                        <i className="fa fa-circle-o text-info"> </i>  <b>Sucursal:</b><label className='text-secondary'>&nbsp;&nbsp;{branchs}</label>
                                                        <br />
                                                        <i className="fa fa-circle-o text-info"> </i> <b>Monto a reportar en caja:</b> <label htmlFor="">{formatter.format(formCash.capture_amount)}</label>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Col>
                                        <Col sm="5" md="5" lg="5" >
                                            {
                                                (validationsSG.capture_amount) &&
                                                <AlertItem
                                                    item={
                                                        {
                                                            alerttxt: "Ingrese una cantidad minima para poder generar el corte de caja de este dia.",
                                                            alertcolor: 'danger inverse',
                                                            btnclose: 'default',
                                                            icon: <i className="icon-alert"></i>
                                                        }
                                                    }
                                                />
                                            }
                                            <tr>
                                                <th> <label htmlFor="">Efectivo</label>  </th>
                                                <th>
                                                    <label className='f-16 txt-secondary'>$</label> <input type="number" className='inputCash f-16' placeholder='Ingrese la cantidad' value={capture_amount} name="capture_amount" onChange={handleInputChangeCash} />
                                                </th>
                                            </tr>
                                            <hr />
                                            <th> <br /> <label htmlFor="">Observaciones:</label>  </th>
                                            <th style={{ verticalAlign: "middle", width: '550px' }}>
                                                <textarea
                                                    className='form-control form-control-sm input-air-primary'
                                                    name="comments"
                                                    rows="3"
                                                    value={comments}
                                                    onChange={handleInputChangeCash}
                                                >
                                                </textarea>
                                            </th>
                                            <hr />
                                            <br />
                                            <Col sm="12" className='p-r-0 p-l-0'>
                                                <Row>
                                                    <Col sm="6">
                                                        <div className="btn btn-primary-gradien" onClick={saveCreate} > &nbsp;&nbsp; Cerrar Caja</div>
                                                    </Col>
                                                    <Col sm="6">
                                                        <div className="btn btn-danger-gradien" onClick={() => navigatePrev(viewTab)} > &nbsp;&nbsp; Cancelar Caja</div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Col>
                            </CardBody>


                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalCash
                {
                ...{ method, modal, modalTitle, loading, toggle }
                }
            />
            <ModalPreviewCash
                {
                ...{ toggleCash, typeModal, modalC, handleHistoric, dateEnd, dateInit, changeDate, dataHistoric, formatter, user, branchs }
                }
            />
            <ModalPreviewCredt
                {
                ...{
                    toggleCredit, modalCredit, typeModalCredit,
                    totalPageCount, currentPage, nextPage, previousPage, goToPage, dataCredit
                }
                }
            />
            <ModalReportCash
                {...modalProps}
                close={close}
                data={dataReport}
                branchs={branchs}
                totalPageCount1={totalPageCount1}
                currentPage1={currentPage1}
                nextPage1={nextPage1}
                previousPage1={previousPage1}
                goToPage1={goToPage1}
                dataPaymets={dataPaymets}
                dataCancell={dataCancell}
                dataDiscount={dataDiscount}
                dataPatient={dataPatient}
                mDateRange1={mDateRange1}
                mDateRange2={mDateRange2}
                handleInputChangeCash={handleInputChangeCash}
                getReport={getReport}
                loading={loading}
                mSelectBranch={mSelectBranch}
                handleSelectValuesCash={handleSelectValuesCash}
                branches={branches}
                formatter={formatter}
                viewFormFilters={viewFormFilters}
                handleCloseViewTest={handleCloseViewTest}
                dataCredit={dataCredit}
                printCashClosing={printCashClosing}
                totalPaymets={totalPaymets}
                exportToExcel={exportToExcel}
                dataMDiscount={dataMDiscount}
                handleClean={handleClean}
                footer={
                    <div
                        className="clearfix"
                    >
                        <Col sm="12">
                            <Row>
                                <br />
                                <Col sm="11" className={`${viewFormFilters ? 'animate__animated animate__fadeIn m-t-11' : 'd-none'} `} >
                                    <Form onSubmit={(e) => getReport(e, true)} >
                                        <Row>
                                            <Col sm="2" className='p-1' >
                                                <FormGroup>
                                                    <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Fecha de inicio</Label>
                                                    <Input type='date' value={mDateRange1} name='mDateRange1' onChange={handleInputChangeCash} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="2" className='p-1' >
                                                <FormGroup>
                                                    <Label className='f-w-600 f-12 badge badge-light-primary'>Fecha de cierre</Label>
                                                    <Input type='date' value={mDateRange2} name="mDateRange2" onChange={handleInputChangeCash} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="3" >
                                                <FormGroup>
                                                <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Sucursal</Label>
                                                    <Select
                                                        name="mSelectBranch"
                                                        isClearable={true}
                                                        placeholder="Selecciona una sucursal"
                                                        options={branches}
                                                        onChange={(e) => handleSelectValuesCash(e, 'mSelectBranch')}
                                                        value={mSelectBranch}
                                                        styles={{
                                                            zIndex : "999999999999"
                                                           
                                                          }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="1">
                                            <Label type="text" className='f-w-600 f-12 badge badge-light-primary'  >Particular</Label>

                                            <div id='btn-tooltip-filter-public' className={type_company === '1' ? 'typeInvoicelabel' : 'InvalidtypeCash'} onClick={() => handleTypeCompany("1")}  >
                                                            <i className="fa fa-user f-20">
                                                                <ToolTips
                                                                    placement="top"
                                                                    dataTarget='btn-tooltip-filter-public'
                                                                    dataText='Ventas'
                                                                />
                                                            </i>
                                                        </div>
                                            </Col>
                                            <Col sm="1">
                                            <Label type="text" className='f-w-600 f-12 badge badge-light-primary'  >Empresas</Label>

                                            <div id='btn-tooltip-filter-public' className={type_company === '2' ? 'typeInvoicelabel' : 'InvalidtypeCash'} onClick={() => handleTypeCompany("2")}  >
                                                            <i className="icofont icofont-industries-alt-4 f-20">
                                                                <ToolTips
                                                                    placement="top"
                                                                    dataTarget='btn-tooltip-filter-public'
                                                                    dataText='Ventas'
                                                                />
                                                            </i>
                                                        </div>
                                            </Col>
                                            <Col sm="1">
                                            <Label className='f-w-600 f-12 badge badge-light-primary'>Acciones Rapidas</Label> <br />
                                                <Button type="submit" outline color="primary" size='sm' className={(loading ? "disabled progress-bar-animated progress-bar-striped mt-1 text-center" : "text-center mt-1")} >Filtrar</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                }
            >
            </ModalReportCash>
        </Fragment >
    )
}
