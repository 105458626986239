import React, { Fragment, useState } from 'react'
import { SearchAsync } from '../../components/elements/SearchAsync';
import { Button, Card, CardBody, Col, Container, FormGroup, Row, Table, CardHeader, Collapse, Label, Input, ButtonGroup, Badge, Alert, FormFeedback } from 'reactstrap';
import { useQuatation } from '../../hooks/quoter/useQuatation'
import './QuoteStyle.css';
import { useHistory } from 'react-router-dom';
import 'animate.css';
import { TableQuoter } from './TableQuoter';
import { ModalViewExamsQuoter } from './ModalViewExamsQuoter';
import { ModalRepeatProfile } from '../../components/workOrders/ModalRepeatProfile';

export const IndexQuoter = () => {

	const history = useHistory();

	const {
		//MODALS
		method, modalTitle, modal, modalRepeatProfile, modalViewExamsP,
		toggle, toggleModalViewExams, toggleRepeatProfile,

		//QUOTER VALUES
		observations, listQuoterTest, id_profileExam, name, paternal_surname, maternal_surname, email,
		handleInputChange,
		cardExam, cardProfile, totalW, subTotalW, totalTaxesW,
		examProfileState, viewExamsProfile, times,
		viewButtons, viewButtonsCreate, validationsRepeat,

		//QUOTER METHODS
		handleSelectExamChange, changeSelectSamplings, changeValueTimeSampling,
		deleteExamProfile, handleCheckUrgent, handleCheckSpecimen,
		handleViewExamsProfile, handleCancellQuoter, saveCreate,
		printPdf, sentPdf, downdloadpdf, formValues, phone,
		validationsSG,
		handleChangePercentage,
		totalDiscountW
	} = useQuatation();

	let filter = "1"
	let branchs = localStorage.getItem('branchName');
	let user = localStorage.getItem('nameUser');


	const [filterDate, setFilterDate] = useState(true);
	const [observationm, setObservationm] = useState(true)

	const AlertItem = props => {
		const { item } = props;
		const [Open, setOpen] = useState(true);

		return (
			<Alert className="alert-dismissible"
				color={item.alertcolor}
				isOpen={Open}
				target={"Alert-" + item.id}
			>
				{item.icon}
				<p>{item.alerttxt}</p>
			</Alert>
		);
	}

	const {
		nameV, paternal_surnameV, maternal_surnameV
	} = validationsSG;


	return (
		<Fragment>
			<Container fluid={true}>
				<Col sm="12">
					<Row>
						<Col sm="12">
							<Row>
								<Col sm="3" >
									<Card className='shadow' >
										<Row>
											<Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-2'>
												<img style={{ filter: "saturate(0.5)" }} className="img-fluid" src={require("../../assets/images/loginHelenLabs/Rates/Rates.svg")} />
											</Col>
											<Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-l-0 p-r-0' >
												<br />
												<label className='txt-primary'>Exámenes Totales</label> <br />
												<b className='f-16'>{cardExam}</b>
											</Col>
										</Row>
									</Card>
								</Col>
								<Col sm="3" >
									<Card className='shadow' >
										<Row>
											<Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-2'>
												<img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Rates/Rates.svg")} />
											</Col>
											<Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-l-0 p-r-0' >
												<br />
												<label className='txt-primary'>Perfiles Totales</label> <br />
												<b className='f-16'>{cardProfile}</b>
											</Col>
										</Row>
									</Card>
								</Col>
								<Col sm="3" >
									<Card className='shadow' >
										<Row>
											<Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-2'>
												<img style={{ filter: "brightness(0.5)" }} className="img-fluid" src={require("../../assets/images/loginHelenLabs/Rates/Rates.svg")} />
											</Col>
											<Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-l-0 p-r-0' >
												<br />
												<label className='txt-primary'>Total</label> <br />
												<b className='f-16'>$ {(totalW).toFixed(2)}</b>
											</Col>
										</Row>
									</Card>
								</Col>
								<Col sm="2"> <br /> <br />
									<div className='file-sidebar'>
										<ul>
											<li>
												<div onClick={() => history.goBack()} className={`btn ${filter === '2' ? 'btn-primary-gradien' : 'btn-outline-info-2x'}`}   ><i className="icofont icofont-list"></i> &nbsp;&nbsp; Regresar</div>
											</li>

										</ul>

									</div>

								</Col>

							</Row>
						</Col>



						<Col sm="12">
							<Card className={viewButtons ? "shadow" : " shadow disabled disableLabel"} >
								< CardBody >
									<Col sm="12" className='p-l-0 p-r-0' >
										<b className='f-14 txt-secondary' >Estudios Solicitados<span style={{ color: "#0079C7" }}>*</span></b>
									</Col>
									<hr />
									<Col sm="12">
										<Row>
											<Col md="6" className='p-0'>
												<FormGroup>
													<SearchAsync
														className="col-md-12"
														method={handleSelectExamChange}
														loincState={examProfileState}
														url="Profiles/SearchProfileExam"
														maxLenght={3}
														selectSize="col-md-12"
														isWorkOrder={true}
													/>
												</FormGroup>
											</Col> <br /> <br /> <br />
										</Row>
									</Col>
									<div className='table-responsive'>
										{
											listQuoterTest.length > 0 &&
											<TableQuoter
												key={"table"}
												items={listQuoterTest}
												{...{ handleCheckUrgent, deleteExamProfile, changeSelectSamplings, changeValueTimeSampling, times, 
													handleCheckSpecimen, deleteExamProfile, viewButtons, handleViewExamsProfile,handleChangePercentage }}
											/>
										}
									</div>
									<br /> <br />
									<Col sm="12" className={listQuoterTest.length === 0 ? 'd-none' : 'animate__animated animate__fadeInDown  '} >
										<Row>
											<Col sm="8">
											</Col>
											<Col sm="4">
												<Table className="table-xs">
													<thead>
														<tr>
															<th className='p-1  theadA f-12' colSpan={2} style={{ textAlign: "center" }} >
																<b className='labelThead'>Resúmen</b>
															</th>
														</tr>
													</thead>
													<tbody>
														<tr className='border-bottom-info'>
															<td className='f-14'><label className='labelPrices'>Subtotal</label></td>
															<td className='text-right f-14'><label className='labelPrices'>$ {(subTotalW).toFixed(2)}</label></td>
														</tr>
														<tr className='border-bottom-info'>
															<td className='f-14'><label className='txt-danger'>Descuento</label></td>
															<td className='text-right f-14'><label className='txt-danger'>$ {(totalDiscountW).toFixed(2)}</label></td>
														</tr>

														<tr className='border-bottom-info'>
															<td className='f-14'><label>I.V.A</label></td>
															<td className='text-right f-14'><label><b>${(totalTaxesW.toFixed(2))}</b></label></td>
														</tr>
														<tr>
															<td className='f-14'> <b className='f-14'>Total</b></td>
															<td className="text-right f-14"><b className='f-14'>$ {(totalW).toFixed(2)}</b></td>
														</tr>
													</tbody>
												</Table>
											</Col>
										</Row>
									</Col>
								</CardBody>
							</Card>
						</Col>

						<Col sm="12" className={listQuoterTest.length === 0 ? 'd-none' : 'animate__animated animate__fadeInDown  '} >
							<Card className='shadow'>
								<CardBody>
									<Col sm="12" className='p-l-0 p-r-0' >
										<b className='f-14 txt-secondary' >Generar Cotización<span style={{ color: "#0079C7" }}>*</span></b>
										{
											((validationsSG.nameV) || (validationsSG.paternal_surnameV) || (validationsSG.maternal_surnameV)) &&
											<AlertItem
												item={
													{
														alerttxt: "Debes de ingresar el nombre y los apellidos obligatoriamente.",
														alertcolor: 'danger inverse',
														btnclose: 'default',
														icon: <i className="icon-alert"></i>
													}
												}
											/>
										}
									</Col>
									<hr />
									<Col sm="12">
										<Row>
											<Col sm="6">
												<Row>
													<Col sm="6">
														<Label className="col-form-label">Nombres <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
														<Input
															type="text"
															name="name"
															value={name}
															required={true}
															autoComplete="nope"
															onChange={handleInputChange}
															className={`form-control form-control-sm input-air-primary b-r-9 ${(nameV ? 'border-danger' : '')}`}
															invalid={nameV}
														/>
														<FormFeedback>Debes llenar este campo</FormFeedback>
													</Col>
													<Col sm="6">
														<Label className="col-form-label">Apellido Paterno <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
														<Input
															type="text"
															name="paternal_surname"
															value={paternal_surname}
															required={true}
															autoComplete="nope"
															onChange={handleInputChange}
															className={`form-control form-control-sm input-air-primary b-r-9 ${(paternal_surnameV ? 'border-danger' : '')}`}
															invalid={paternal_surnameV}
														/>
														<FormFeedback>Debes llenar este campo</FormFeedback>
													</Col>
												</Row>
												<Row>
													<Col sm="6">
														<Label className="col-form-label">Apellido Materno <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
														<Input
															type="text"
															name="maternal_surname"
															value={maternal_surname}
															required={true}
															autoComplete="nope"
															onChange={handleInputChange}
															invalid={maternal_surnameV}
															className={`form-control form-control-sm input-air-primary b-r-9 ${(maternal_surnameV ? 'border-danger' : '')}`}
														/>
														<FormFeedback>Debes llenar este campo</FormFeedback>
													</Col>
													<Col sm="6">
														<Label className="col-form-label">Correo electronico </Label>
														<Input
															type="text"
															name="email"
															value={email}
															required={true}
															autoComplete="nope"
															onChange={handleInputChange}
															className="form-control form-control-sm input-air-primary b-r-9"
														/>
													</Col>
												</Row>
												<Row>
													<Col sm="6">
														<Label className="col-form-label"> Telefono </Label>
														<Input
															type="text"
															name="phone"
															value={phone}
															required={true}
															autoComplete="nope"
															onChange={handleInputChange}
															className="form-control form-control-sm input-air-primary b-r-9"
														/>
													</Col>
												</Row>
											</Col>
											<Col sm="6">
												<Row className={viewButtons ? "" : "d-none"} >
													<Col sm="12" md="12" lg="12">
														<Badge className='f-12' color='primary' style={{ width: '100%' }}>Datos generales</Badge>
														<Col sm="12" className='p-l-0 p-r-0' >
															<Card className='shadow  disabled disableLabel ' >
																<CardBody className='p-t-0' > <br />
																	<i className="fa fa-circle-o text-info"> </i> <b>Responsable:</b> <label className='text-secondary'>&nbsp;&nbsp;{user}</label>
																	<br />
																	<i className="fa fa-circle-o text-info"> </i>  <b>Sucursal:</b><label className='text-secondary'>&nbsp;&nbsp;{branchs}</label>
																	<br />
																	<i className="fa fa-circle-o text-info"> </i>  <b>Paciente:</b><label className='text-secondary'>&nbsp;{formValues.name}&nbsp;{formValues.paternal_surname}&nbsp;{formValues.maternal_surname}</label>

																</CardBody>
															</Card>
														</Col>
													</Col>
												</Row>
												<div className={viewButtons ? "d-none" : ""}  >
													<Col sm="12">
														<Badge className='f-12' color='primary' style={{ width: '100%' }}>Acciones al generar Cotización</Badge>
														<Col sm="12" className='p-l-0 p-r-0'>
															<Card className='shadow   '>
																<CardBody className='p-t-0' >  <br />
																	<Row>
																		<Col sm="4" className={viewButtons ? "d-none" : "p-2"}> <br />
																			<button color="secondary" class="btn btn-outline-secondary   btn-xs" onClick={() => sentPdf()}>{"Enviar"} &nbsp; <i className="icofont icofont-envelope colorbutton"></i></button>
																		</Col>
																		<Col sm="4" className={viewButtons ? "d-none" : "p-2"}> <br />
																			<button color="info" class="btn btn-outline-info  btn-xs" onClick={() => downdloadpdf()}>{"Descargar"}&nbsp;<i className="icofont icofont-download-alt"> </i></button>
																		</Col>
																		<Col sm="1" className={viewButtons ? "d-none" : "p-2"}> <br />
																			<button color="info" class="btn btn-outline-primary b btn-xs" onClick={() => printPdf()}>{"Imprimir"}&nbsp;<i className="icofont icofont-print"></i></button>
																		</Col>
																	</Row>

																</CardBody>

															</Card>

														</Col>


													</Col>
												</div>
												<Col sm="12"> <br />
													{
														listQuoterTest.length > 0 &&
														<ButtonGroup className={viewButtons ? "btn-group-pill btn-group-sm" : "d-none"} size='sm' style={{ width: '100%' }}>
															<Button
																outline
																color='primary-2x'
																onClick={saveCreate}
															>
																<label className="labelSaveCreate">{"Cotizar"}</label>
															</Button>
															<Button
																className='btn-air-danger'
																outline
																color='danger'
																onClick={() => handleCancellQuoter()}
															>
																<label className='labelSaveCreate'>Cancelar</label>
															</Button>
														</ButtonGroup>
													}
												</Col>
											</Col>
										</Row>
									</Col>
								</CardBody>
							</Card>
						</Col>
						<Col sm="12" className={listQuoterTest.length > 0 ? 'animate__animated animate__bounceInRight p-l-0 p-r-0' : "d-none"} >
							<Row>
								<Col xs="12" sm="12" md="12" lg="6" xl="6" className='p-r-0'>
									<Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" sm="12">
										<Row className="position-sticky" style={{ top: 140 }}>
											<Col xl="12">
												<Card>
													<CardHeader className=' bg-primary'>
														<h5 className="mb-0">
															<Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterDate(!filterDate)} aria-expanded={filterDate} aria-controls="collapseicon"
																data-target="#collapseicon"  ><i className="icofont icofont-prescription"></i> &nbsp; &nbsp; &nbsp; &nbsp;Indicaciones</Button>
														</h5>
													</CardHeader>
													<Collapse isOpen={filterDate}>
														<CardBody className="p-3">
															<Card >
																<Col sm="12" className='scrollWork'>
																	{
																		listQuoterTest.length > 0 && listQuoterTest.map((obj, index) => {
																			if (obj.id_exam !== null) {
																				return (
																					<div key={index}>
																						<i className="icofont icofont-laboratory f-16 icon-Exam"></i><b className='labelIndication'>{obj.name}</b>
																						<Col>
																							{
																								(obj.listIndications === undefined || obj.listIndications.length === 0)
																									?
																									<label className='text-secondary PIndication'>-SIN INDICACIONES PREVIAS </label>
																									:
																									obj.listIndications.map((o, i) => {
																										return (
																											<ul key={i} sm="12">
																												<label className='text-secondary PIndication'>-{o.indication}</label>
																											</ul>
																										)
																									})
																							}
																						</Col>
																					</div>
																				)
																			}
																			else if (obj.id_profile !== null) {
																				return (
																					<div key={index}>
																						<i className="icofont icofont-laboratory f-16 icon-Exam"></i><label className='labelIndication'>{obj.name.toUpperCase()}</label>
																						<Col>
																							{
																								(obj.listExamIndications == undefined || obj.listExamIndications.length == 0)
																									?
																									<label className='PIndication'>-Sin indicaciones previas </label>
																									:
																									<ul>
																										{
																											obj.listExamIndications.map((ind, i) => {
																												return (
																													<li key={i}>
																														<label className='labelIndication' >{ind.name_exam}</label>
																														<ul>
																															{
																																(ind.listIndications === undefined || ind.listIndications.length === 0)
																																	?
																																	<label className='PIndication'>-SIN INDICACIONES PREVIAS</label>
																																	:
																																	ind.listIndications.map((a, c) => {
																																		return (
																																			<li key={c}>
																																				<label className='PIndication' >-{a.indication}</label>
																																			</li>
																																		)
																																	})
																															}
																														</ul>
																													</li>
																												)
																											})
																										}
																									</ul>
																							}
																						</Col>
																					</div>
																				)
																			}
																		})
																	}
																</Col>
															</Card>
														</CardBody>
													</Collapse>
												</Card>
											</Col>
										</Row>
									</Col>
								</Col>
								<Col xs="12" sm="12" md="12" lg="6" xl="6" className='p-l-0'>
									<Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" sm="12">
										<Row className="position-sticky" style={{ top: 140 }}>
											<Col xl="12">
												<Card>
													<CardHeader className=' bg-primary '>
														<h5 className="mb-0">
															<Button color="link pl-0" data-toggle="collapse"
																data-target="#collapseicon"  ><i className="fa fa-user-md"></i> &nbsp; &nbsp; &nbsp; &nbsp;Observaciones</Button>
														</h5>
													</CardHeader>
													<Collapse isOpen={observationm}>
														<CardBody className="p-3">
															<Card style={{ height: "100px" }}>
																<textarea
																	name="observations"
																	className="form-control input-air-primary form-control-sm gridQuoter" placeholder="Observaciones......"
																	value={observations}
																	onChange={handleInputChange}
																/>
															</Card>
														</CardBody>
													</Collapse>
												</Card>
											</Col>
										</Row>
									</Col>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Container >
			<ModalViewExamsQuoter
				{
				...{ modalViewExamsP, toggleModalViewExams, viewExamsProfile, handleViewExamsProfile }
				}
			/>

			<ModalRepeatProfile
				{
				...{
					modalRepeatProfile, validationsRepeat, modalTitle, toggleRepeatProfile,
				}
				}
			/>

		</Fragment >
	)
}